import { Component, Input, Output, Renderer2, ViewChild, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';
import { PagesComponent } from '../pages/pages.component';
import { trigger, state, style, transition, animate} from '@angular/animations';
import { PreviewService } from '../preview.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectPhotosComponent } from '../select-photos/select-photos.component';

@Component({
  selector: 'app-collage',
  templateUrl: './collage.component.html',
  styleUrls: ['./collage.component.scss'],
  // animations: [
  //   trigger('slideInOut', [
  //     transition(':enter', [
  //       style({ transform: 'translateX(-100%)' }),
  //       animate('500ms ease-in-out', style({ transform: 'translateX(0%)' })),
  //     ]),
  //     transition(':leave', [
  //       animate('500ms ease-in-out', style({ transform: 'translateX(-100%)' })),
  //     ]),
  //   ])
  // ]
})
export class CollageComponent {

  @ViewChild("colgInput")
  colgInput:any;

  @Input()
  parent!: PagesComponent;

  @Input()
  imgDataCp: any;

  imgData: any;
  
  seqIndex = 0;
  originalIndex = 0;
  removeDubId:number[] = [];

  renderImage = ""

  newImageIdMap = new Map();

  inEdit = false;
  inCrop = false;
  loading = false;
  imgSeqMetaCp: any;
  imgSeqMeta: any;

  private subscribe!:Subscription;
  private modalSubscription!: Subscription;
  private modalRef;

  // originalUrls = new Map();

  @Output()
  getCollage = new EventEmitter();

  // layouts = ['1_1', '1-1', 'three-landscape', '1-1_1', '1-1_1-1' ];
  
  layouts = [ 'two-landscape', 'two-portrait', 'three-landscape', 'three-portrait', 'four-photo' ];

  layoutMap = {
    'two-landscape' : {
      style : "fit43",
    }, 
    'two-portrait': {
      style : "fit34",
    }, 
    'three-landscape': {
      "0": {
        style : "fit43",
      }, 
      "1" : {
        style : "full",
      } 
    }, 
    'three-portrait': {
      "0": {
        style : "fit34",
      }, 
      "1" : {
        style : "full",
      }
    }, 
    'four-photo' : {
      style : "full",
    }
  }

  constructor(private renderer: Renderer2, private customModal:NgbModal) {

  }

  async ngOnInit() {
    this.imgData = cloneDeep(this.imgDataCp);
    this.imgData.metadata.style = "collage";
    // this.originalUrls = cloneDeep(this.parent.parent.dataService.getOriginalImage());
    if(this.imgData.metadata['collage']) {
      this.getCollageFromMetadata();
    } else {
      this.imgData.metadata['collage'] = await this.createLayout(2, cloneDeep(this.imgData));
    }
    // this.imgDataCp = cloneDeep(this.imgDataCp);
    this.originalIndex = this.setOriginalIndex(); 
    // this.parent.parent.dataService.resetFiles();
  }

  async createLayout(id:number, imgData) {
    const data = cloneDeep(imgData);
    delete data.metadata.caption;
    delete data.metadata.rotate;
    this.parent.parent.dataService.setLoader(true);
    return new Promise(async (resolve)=> {
      const layout = this.layouts[id];
      const frames = layout.startsWith("two") ? 2 : layout.startsWith("three") ? 3 : 4;
      const collage = {
        layout : layout,
        frames : frames,
        seq : Array.from(Array(frames).keys()),
        metadata : {
          images : [data]
        } 
      }
      this.parent.parent.dataService.setLoader(false);
      resolve(this.setCollageMetadata(1, collage));
    });
  }

  async getCollageFromMetadata(collageData = cloneDeep(this.imgData.metadata['collage'])) {
    return new Promise(async (resolve)=> {
      let self = this;
      (async function () {
        for (let [index, s] of collageData.seq.entries()) {
          const meta = collageData.metadata.images[s];
          if(meta) {
            meta["src"] = await self.parent.parent.preview.genImagePreview(self.parent.parent.originalUrls, meta);
            collageData.metadata.images[s] = meta;
          }
        }
        self.imgData.metadata['collage'] = collageData;
        resolve(collageData);
      })();
    })
  }

  async addImageToCollage(imageId:number, isDup:boolean) {
    const data = this.parent.parent.originalUrls.get(imageId);
    const collageData = this.imgData.metadata.collage;
    const style = collageData.frames == 3 ? this.layoutMap[collageData.layout]["1"].style : this.layoutMap[collageData.layout].style;
    await this.parent.parent.utils.getUploadedImageMetadata(data, '', this.seqIndex, imageId, style, false, {}, false, data.boost).then((imgMetaResp:any)=> {
      this.imgSeqMetaCp = cloneDeep(imgMetaResp);
      this.imgSeqMeta = cloneDeep(this.imgSeqMetaCp);
      this.imgData.metadata.collage.metadata.images[this.seqIndex] = cloneDeep(this.imgSeqMeta);
      if(!isDup)
        this.removeDubId.push(imageId);
      else if(this.removeDubId.indexOf(imageId) > -1)
        this.removeDubId.splice(this.removeDubId.indexOf(imageId),1)
      return true;
    });
  }

  async setCollageMetadata(id:number, collageData = cloneDeep(this.imgData.metadata['collage'])) {
    return new Promise(async (resolve)=> {
      const layout = this.layouts[id];
      collageData["layout"] = layout;
      collageData["frames"] = layout.startsWith("two") ? 2 : layout.startsWith("three") ? 3 : 4;
      collageData["seq"] = Array.from(Array(collageData["frames"]).keys());
      collageData.metadata.images = collageData.metadata.images.slice(0,collageData["frames"]);
      let self = this;
      this.loading = true;
      (async function () {
        for (let [index, meta] of collageData.metadata.images.entries()) {
          meta.metadata.style = collageData.frames == 3 ? self.layoutMap[collageData.layout][!index ? "0" : "1"].style : self.layoutMap[collageData.layout].style;
          collageData.metadata.images[index] = await self.getNewImages(self.parent.parent.originalUrls.get(meta.id), cloneDeep(meta))
        }
        self.imgData.metadata['collage'] = collageData;
        self.loading = false;
        resolve(collageData);
      })();
    })
  }

  getNewImages(orginalUrlData, imgMeta) {
    return new Promise((resolve)=> {
      this.parent.parent.preview.newImagePreview(orginalUrlData.rawUrl, imgMeta).then(res=> {
        resolve(cloneDeep(res));
      })
    })
  }


  selectCollageImg(seqIndex:number) {
    this.seqIndex = seqIndex;
    if(this.imgData.metadata.collage.metadata.images[seqIndex]) {
      this.setInEdit();
    } else {
      this.choosePhotoModal();
      // this.addNewCollagePhoto();
    }
  }

  chooseUploaded() {
    this.parent.parent.originalUrls
  }

  addNewCollagePhoto() {
    if(this.colgInput?.nativeElement){
      this.renderer.selectRootElement(this.colgInput.nativeElement).click();
      this.colgInput.nativeElement.value = '';
    }
  }

  setInEdit() {
    this.inEdit = !this.inEdit;
    this.imgSeqMetaCp = cloneDeep(this.imgData.metadata.collage.metadata.images[this.seqIndex]);
    this.imgSeqMeta = cloneDeep(this.imgSeqMetaCp);
  }

  resetImage() {
    this.imgSeqMetaCp = cloneDeep(this.imgData.metadata.collage.metadata.images[this.seqIndex]);
    this.imgSeqMeta = cloneDeep(this.imgSeqMetaCp);
    // this.originalUrls = cloneDeep(this.parent.originalUrls);
  }

  crop() {
    this.inCrop = !this.inCrop;
  }

  async cropImage() {
    // this.imgSeqMeta = await this.getNewImages(cloneDeep(this.imgSeqMeta));
    this.imgSeqMeta['src'] = await this.parent.parent.preview.genImagePreview(this.parent.parent.originalUrls, this.imgSeqMeta);
    this.inCrop = !this.inCrop;
  }

  getCropData(crop) {
    // const crop = {sx:+data[0], sy:+data[1], sw: data[2] - data[0], sh: data[3] - data[1]};
    this.imgSeqMeta.metadata["crop"] = crop;
  }

  rotateRight() {

  }

  deletePage() {
    delete this.imgData.metadata.collage.metadata.images[this.seqIndex];
    this.inEdit = !this.inEdit;

  }

  async saveSeqImg() {
    this.imgData.metadata.collage.metadata.images[this.seqIndex] = cloneDeep(this.imgSeqMeta);
    await this.getCollageFromMetadata();
    this.inEdit = !this.inEdit;
  }

  cancelEdit() {
    this.imgData = cloneDeep(this.imgDataCp);
    // this.parent.originalUrls.set(currentImg, URL.createObjectURL(coFile));
    // this.parent.parent.dataService.setOriginalImage(coFile, currentImg);
    this.getCollageFromMetadata();
    this.inEdit = !this.inEdit;
  }

  async shuffle() {
    // if(this.imgData.metadata.collage.frames == this.imgData.metadata.collage.metadata.images.length) {
      this.imgData.metadata.collage.seq.push(this.imgData.metadata.collage.seq.shift());
      let self = this;
      await (async function () {
        const collageData = cloneDeep(self.imgData.metadata['collage']);
        for (let [index, s] of collageData.seq.entries()) {
          const meta = collageData.metadata.images[s];
          // meta["src"] = self.originalUrls.get(meta.id);
          if(meta) {
            meta.metadata.style = collageData.frames == 3 ? self.layoutMap[collageData.layout][!index ? "0" : "1"].style : self.layoutMap[collageData.layout].style;
            collageData.metadata.images[s] = await self.getNewImages(self.parent.parent.originalUrls.get(meta.id), cloneDeep(meta))
          }
        }
        // self.imgData.metadata['collage'] = collageData;
      //   self.getCollageFromMetadata();
      //   return true;
        await self.getCollageFromMetadata(collageData);
        return true;
      })();
      // this.setCollageMetadata(this.layouts.findIndex(this.imgData.metadata.collage.layout));

    // }
  }

  setOriginalIndex() {
    return Math.max(...Array.from(this.parent.parent.originalUrls.keys()).map((x)=> +x)) + 1;
  }

  getOriginalIndex() {
    return this.originalIndex++;
  }

  collageBack() {
    this.imgData = cloneDeep(this.imgDataCp);
    this.parent.inCollage = !this.parent.inCollage;
  }

  async saveCollage() {
    await this.removeDub();
    this.parent.parent.bookData.metadata.images[this.parent.editIndex] = this.imgData;
    await this.parent.parent.parseImage(this.parent.parent.bookData.metadata.images[this.parent.editIndex], this.parent.editIndex);
    this.parent.renderImage = this.parent.parent.imgPreviews[this.parent.editIndex]
    this.parent.renderMeta = this.imgData;
    this.parent.parent.updateBooks();
    console.log(this.parent.editIndex);
    this.parent.inCollage = !this.parent.inCollage;
  }

  removeDub() {
    console.log(this.removeDubId);
    
    if(this.removeDubId.length == 0) return true;
    const indexImg = this.parent.parent.findAllIndex(this.parent.parent.bookData.metadata.images, 
      (item) => item.metadata.style != 'collage' && this.removeDubId.includes(item.id)
    );
    console.log(indexImg);
    if(indexImg.length) {
      indexImg.sort((a, b) => b - a);
      indexImg.forEach(ind=> {
        if(ind) {
          this.parent.parent.bookData.metadata.images.splice(ind, 1);
          this.parent.parent.imgPreviews.splice(ind, 1);
        }
      });
      indexImg.push(this.parent.editIndex);
      
      indexImg.sort((a, b) => a - b);
      console.log(indexImg);
      let index = indexImg.indexOf(this.parent.editIndex);
      this.parent.parent.dataService.editIndex -= index;
      this.parent.editIndex = this.parent.parent.dataService.editIndex;
      this.removeDubId = [];
    }
    return true;
  }

  choosePhotoModal(seqIndex:null|number = null) {
    this.modalRef = this.customModal.open(SelectPhotosComponent,
      {
        centered: true, backdrop: 'static', keyboard: false
      }
    );
    this.modalRef.componentInstance.images = this.getRawImages();
    const usedIndex = this.parent.parent.bookData.metadata.images.map(x=> x.metadata.style == 'collage' ? x.metadata.collage.metadata.images.map(y=> y.id) : x.id).flat()
    this.modalRef.componentInstance.usedIndex = usedIndex;
    this.modalRef.componentInstance.imgIndex = seqIndex ? this.imgData.metadata.collage.metadata.images[seqIndex].id : null;
    this.modalSubscription = this.modalRef.componentInstance.actionConfirmed.subscribe((data: any) => {
      if(data.type == 'close')
        this.closeModalFromParent();
      else if(data.type == 'selected')
        this.getImageSelection(data.value);
      else if(data.type == 'save')
        this.setImageSelection(data.value);
      else if(data.type == 'upload')
        this.uploadFiles(data.value);
    });
  }

  getRawImages() {
    const images:any = [];
    // const ids = this.imgData.metadata.collage.metadata.images.map(x=> x.id);
    Array.from(this.parent.parent.originalUrls.keys()).forEach(x=> {
      // if(x != 0 && !ids.includes(x)) {
        const d = this.parent.parent.originalUrls.get(x);
        images.push({id:x, src: d.previewUrl});
      // }
    })
    return images;
  }

  getImageSelection(value:any) {
    const index = this.parent.parent.findAllIndex(this.parent.parent.bookData.metadata.images, 
      (item) => item.metadata.style != 'collage' && item.id === value.id
    );
    if(index.length) {
      this.modalRef.componentInstance.message = value.isDub ? `Photo will be same for Page ${this.parent.editIndex} and Page ${index.join(', ')}` : 
      `Photo will be moved from Page ${index.join(', ')} to Page ${this.parent.editIndex}`
    } else
    this.modalRef.componentInstance.message = "";
  }

  async setImageSelection(value:any) {
    this.parent.parent.dataService.setLoader(true, true);
    await this.addImageToCollage(value.id, value.isDub);
    // this.renderImage = '';
    // await this.getCollageFromMetadata();
    await this.saveSeqImg();
    this.closeModalFromParent();
    this.parent.parent.dataService.setLoader(false);
  }

  async uploadFiles(event:any) {
    await this.parent.parent.uploadFiles(event, false);
    this.modalRef.componentInstance.images = this.getRawImages();
  }

  closeModalFromParent() {
    this.unsubscribeModal();
    if (this.modalRef) {
      this.modalRef.close('Closing from parent');
    }
  }

  unsubscribeModal() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }


  ngOnDestroy() {
    this.parent.parent.dataService.resetFiles();
  }
}
