import { Component } from '@angular/core';
import { DataService } from '../data-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UploaderService } from '../uploader.service';
import { APIService } from '../service/ApiService';
import { CustomUtils } from '../service/customUtils';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { GtmService } from '../gtm.service';
import { debounceTime, Subject, Subscription, switchMap } from 'rxjs';
import { ModalService } from '../modal.service';
import { cloneDeep } from 'lodash';
import { HandlerService } from '../handler.service';
import { PreviewService } from '../preview.service';

@Component({
  selector: 'app-flipbook',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss']
})
export class CoverComponent {

  imgPreviews:Array<any> = [];
  imgNameMap:Array<any> = [];
  originalUrls = new Map();
  magnetImage = '';

  testMap = new Set();

  private routeSub: Subscription = new Subscription;

  bookData:any = {};
  maxLimit:number;
  currentPage = 0;
  lastPage = 0;
  editable = true;
  inPreview = false;
  inFlipBook = true;
  inEdit = false;
  inSort = false;
  _destroying = false;
  loading = false;
  progress = 0;
  frames:any = [];
  styles:any = [];

  failed:number[] = [];
  event: any;
  type = "";

  private uploadCoverSubject = new Subject<any>();
  private uploadSubject = new Subject<any>();
  private uploadCoverSubs!: Subscription;
  private uploadSubs!: Subscription;

  constructor(public router: Router, public route: ActivatedRoute,
    public dataService : DataService, 
    public sanitizer: DomSanitizer, 
    public uploader:UploaderService, 
    public service: APIService, 
    public utils:CustomUtils, 
    public toaster: ToastrService,
    public gtm: GtmService,
    public readonly title: Title,
    public modalService: ModalService,
    public preview : PreviewService,
    public handler: HandlerService
    // public modalService: NgbModal
    ){
    // this.bookData = this.dataService.getBookData();
    this.maxLimit = this.utils.maxImages;
    this.frames = this.utils.frames;
    this.styles = this.utils.styles;

    this.uploadCoverSubs = this.uploadCoverSubject.pipe(
      debounceTime(10000),
      switchMap((data) => this.uploadCover(data.bookImage, data.coverId))
    ).subscribe();
    this.uploadSubs = this.uploadSubject.pipe(
      debounceTime(2000),
      switchMap((data) => this.updateBooks())
    ).subscribe();
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.bookData.id = params['id'];
      if(this.handler.processing || this.handler.processed)
        this.inProcessing();
      else if(this.bookData.id) {
        this.fetchBook();
      } else {
        this.router.navigate(["orders"])
      }
    });
  }

  inProcessing() {
    if(this.handler.processing) {
      this.imgPreviews = new Array(this.handler.total + 1)
      this.type = this.dataService.getSku();
      this.bookData =  this.dataService.getBookData();
      if(!this.handler.progressSubscription.closed) {
        Object.values(this.handler.preview).forEach(x=> {
          this.imgPreviews[x.pageId] = x.result;
        })
        this.handler.getPreviews().subscribe(progress => {
          if(progress.result)
            this.imgPreviews[progress.pageId] = progress.result;
        });
        this.handler.getSubscribe().subscribe(progress => {
          if(progress.result)
            this.updateSmartPreviews(progress.pageId, progress);
        });
        this.handler.getErrorSubscribe().subscribe(error => {
          this.imgPreviews[error.pageId] = '';
        });
        this.getHandlerData(true);
      } else {
        this.handler.progressSubscription.add(()=> this.getHandlerData(true))
      }
    } else if(this.handler.processed) {
      this.type = this.dataService.getSku();
      this.getHandlerData();
    }
  }

  fetchBook() {
    this.editable = false;
    this.progress = 0;
    this.fetchBookData(this.bookData.id);
  }

  isProcessing() {
    return this.handler.processing;
  }

  getProcessed() {
    return this.handler.processed;
  }

  getTotalProcessing() {
    return this.handler.total;
  }

  updateSmartPreviews(pageId, progress, isSave = true) {
    if(isSave) {
      this.imgPreviews[pageId] = progress.result.meta.src;
      if(pageId == 0) {
        this.dataService.setCover(this.imgPreviews[pageId]);
      }
      this.setImageMapName(pageId, progress.result.meta);
      this.bookData = this.dataService.getBookData();
    }
    const d = progress.result.resp;
    d.metaData = d.metaData && typeof d.metaData == 'string' ? JSON.parse(d.metaData) : d.metaData;
    d.metaData.boost = d.metaData.boost && typeof d.metaData.boost == 'string' ? JSON.parse(d.metaData.boost) : d.metaData.boost;
    this.originalUrls.set(progress.imageId, d)
  }

  setImageMapName(id, meta) {
    if(id == 0) return;
    let isCol = meta.metadata.style == 'collage';
    const indices = this.imgNameMap.reduce((r, x, i) => {
      if (x?.[0]== meta.name) {
        r.push(i);
      }
      return r;
    }, []);
    if(!isCol && indices.length > 1) {
      indices.map(x=> this.imgNameMap[x] = [meta.name, ++this.imgNameMap[x][1]])
    }
    else if((meta.metadata.dpi||meta.dpi) < this.utils.minDpi && !isCol)
      this.imgNameMap[id] = [meta.name, 2];
    else
      this.imgNameMap[id] = [meta.name, 1];
  }

  getHandlerData(isNew = false) {
    Object.keys(this.handler.progress).forEach(x=> {
      this.updateSmartPreviews(+x, this.handler.progress[+x])
    })
    this.handler.processed = 0;
    this.bookData = this.dataService.getBookData();
    
    this.editFlipbook();
    if(isNew) {
      if (this.bookData.metadata.images.length > 20) {
        this.openGifts();
      }
    }
  }

  findAllIndex(arr, predicate) {
    let indices:number[] = [];
    arr.map((item, index) => {
      if (index && predicate(item, index, arr)) {
        indices.push(index);
      }
    });
    return indices;
  }

  async addImages(index:number, imageIds:number[], isDuplicate:boolean) {
    (async()=> {
      for(let id of imageIds) {
        await this.addImage(index++, id, isDuplicate, false);
      }
    })()
  }

  async addImage(index:number, imageId:number, isDuplicate:boolean, isCover = false) {
    this.dataService.setLoader(true, true);
    const data = this.originalUrls.get(imageId);
    const indexImg = this.findAllIndex(this.bookData.metadata.images, (item) => item.id === imageId);
    this.bookData.metadata.images[index].id = imageId;
    this.bookData.metadata.images[index].metadata.boost = data.boost;
    if(data['metaData']) {
      data['metaData'] = typeof data['metaData'] == 'string' ? JSON.parse(data['metaData']) : data['metaData'];
      this.bookData.metadata.images[index].width = data['metaData'].width;
      this.bookData.metadata.images[index].height = data['metaData'].height;
      this.bookData.metadata.images[index].dpi = data['metaData'].dpi;
      this.bookData.metadata.images[index].format = data['metaData'].format;
      this.bookData.metadata.images[index].size = data['metaData'].size;
      this.bookData.metadata.images[index].name = data['metaData'].name;
      this.bookData.metadata.images[index].date = data['metaData'].date;
      this.bookData.metadata.images[index].layout = data['metaData'].layout;
      this.bookData.metadata.images[index] = await this.preview.newImagePreview(data.rawUrl, this.bookData.metadata.images[index], isCover, this.bookData.metadata.cover);
    } else {
      this.bookData.metadata.images[index] = await this.utils.getUploadedImageMetadata(data, '', index, imageId, null, isCover, this.bookData.metadata.cover, false, data.boost);
    }
    this.imgPreviews[index] = this.bookData.metadata.images[index].src;
    if(!isDuplicate && indexImg.length) {
      indexImg.sort((a, b) => b - a);
      indexImg.forEach(ind=> {
        if(ind) {
          this.bookData.metadata.images.splice(ind, 1);
          this.imgPreviews.splice(ind, 1);
        }
      });
      if(!isCover) {
        indexImg.push(this.dataService.editIndex);
        indexImg.sort((a, b) => b - a);
        let index = indexImg.indexOf(this.dataService.editIndex);
        this.dataService.editIndex += index;
      }
    }
    // to trigger cover change
    await this.updateBooks(index == 0);
    this.dataService.setLoader(false);
    return true;
  }

  getEditable() {
    this.editable = this.bookData.status == 'STAGE' || this.bookData.status == 'STAGING';
    return this.editable;
  }

  fetchBookData(id:number) {
    this.imgPreviews = [];
    this.dataService.setCover('');
    this.service.getBookData(id).then((res:any)=> {
      if(res.data) {
        this.dataService.setBookData(res.data);
        this.bookData = this.dataService.getBookData();
        this.getEditable();
        this.type = this.bookData.type;
        this.originalUrls = this.bookData.metaUrlsMap;
        let self = this;
        (async function () {
          try {
            const images = cloneDeep(self.bookData.metadata.images);
            await self.processImagesConcurrently(images, self.parseImage.bind(self));
            self.progress = 100;
            self.editFlipbook();
            if(self.bookData.metadata.magnets?.length) {
              self.preview.genImagePreview(self.originalUrls, self.bookData.metadata.magnets[0], undefined, undefined, undefined, undefined).then((url)=> {
                self.dataService.setMagnet(url);
                self.magnetImage = url;
              })
            } else if (images.length > 20) {
              self.openGifts();
            }
          } catch (error) {
            console.error('Error processing images:', error);
          }
        })();
      }
    })
  }

  openGifts() {
    const int =  setInterval(()=> {
      if(!this.dataService.getAction()) {
        clearInterval(int);
        this.modalService.openGifts(this.bookData.id, this.bookData.metadata.images.length - 1)?.then(res=> res&&this.openMagnets());
      }
    },2000);
  }

  async processImagesConcurrently(images, parseImageFunction, concurrencyLimit = 4) {
    const results:any = [];
    const executing = new Set();
    let len = 100/images.length;
    let self = this;
    for (let i = 0; i < images.length; i++) {
      const x = images[i];
      if (self._destroying) return; // Check if destroying
      const promise = parseImageFunction((x.metadata.style == 'cover' ? Object.assign({},x, self.bookData.metadata.cover) : x), i, x.metadata.style == 'cover')
      .then(res => {
        self.progress += len;
        return res;
      })
      .finally(() => {
        executing.delete(promise);
      });
      results.push(promise);
      executing.add(promise);
      if (executing.size >= concurrencyLimit) {
        await Promise.race(executing);
      }
    }
    await Promise.all(results);
    return results;
  }

  parseImage(imgMeta:any, index:any, cover = false):Promise<boolean> {
    return new Promise(async (resolve)=> {
      this.preview.genImagePreview(this.originalUrls, imgMeta, undefined, undefined, undefined, undefined).then(async (url)=> {
        if(url) {
          this.setImageMapName(index, imgMeta);
          this.imgPreviews[index] = url;
          if(index == 0) {
            await this.uploadCoverDebounced(url, this.bookData.metadata.cover.previewId);
            this.dataService.setCover(url);
          }
        }
        resolve(true);
      })
    })
  }

  uploadCoverDebounced(bookImage: any, coverId) {
    this.uploadCoverSubject.next({bookImage, coverId : coverId});
  }

  private async uploadCover(bookImage, coverId) {
    let self = this;
    return await new Promise(async (resolve)=> {
      fetch(bookImage)
      .then(function(response) {
        return response.blob()
      })
      .then(async function(blob) {
        const previewId = await self.service.uploadCover(blob, coverId);
        if(!coverId)
          self.bookData.metadata.cover.previewId = previewId;
        resolve(previewId)
      })
    });
  }

  changeColor(id:any) {
    this.gtm.event('click_colorChange', {})
    let color = this.dataService.colorData.find(x=> x.id == id);
    typeof this.bookData.metadata == "string" ? this.bookData.metadata = JSON.parse(this.bookData.metadata) : null;
    this.bookData = cloneDeep(this.dataService.getBookData());
    if(color) {
      this.bookData.color = color.bookColor;
      this.bookData.metadata["cover"]["color"] = color.bookColor;
      this.bookData.metadata["cover"]["titleColor"] = color.titleColor;
      this.bookData.metadata["cover"]["logoColor"] = color.logoColor;
    }
    // this.dataService.setLoader(true);
    this.saveChanges();
  }

  rebuildCover() {
    this.loading = true;
    return new Promise(async resolve=> {
      const data = this.originalUrls.get(this.bookData.metadata.images[0].id);
      this.bookData.metadata.images[0] = await this.preview.newImagePreview(data.rawUrl, Object.assign({}, this.bookData.metadata.images[0], this.bookData.metadata["cover"]));
      this.loading = false;
      resolve(true);
    })
  }

  async changeFrame(ind:number) {
    const frame =  this.frames[ind];
    let rebuild = true;
    if(this.bookData.metadata.cover["frame"].name == 'wb1' && frame.style == 'full')
      rebuild = false;
    else if(this.bookData.metadata.cover["frame"].style == 'full' && frame.name == 'wb1')
      rebuild = false;

    this.bookData.metadata.cover["frame"] = frame;
    if(this.bookData.metadata.cover["frame"].name == 'wb2') {
      this.bookData["title"] = this.utils.getTitle();
      this.bookData.metadata.cover.titleStyle = ind ? "Ronda" : "Carena";
    } else {
      this.bookData["title"] = this.utils.getSpineTiltle();
      this.bookData.metadata.cover.titleStyle = 'Nunito';
    }
    this.bookData.metadata.cover["title"] = this.bookData["title"];
    if(rebuild)
      await this.rebuildCover();
    this.saveChanges();
  }

  async changeLayout(ind:number) {
    if(this.bookData.metadata.cover["frame"].name == 'wb2') {
      this.bookData.metadata.cover["frame"]["style"] = this.styles[ind];
      await this.rebuildCover();
    }
    this.saveChanges();
  }
  
  changeTitle(val:string) {
    this.bookData["title"] = val;
    this.bookData.metadata.cover["title"] = val;
    this.saveChanges();
  }

  onBlur() {
    const val = this.bookData.metadata.cover["title"];
    this.bookData["title"] = val?.trim()?.slice(0, this.bookData.metadata.cover.frame.titleLen);
    this.bookData.metadata.cover["title"] = val?.trim()?.slice(0, this.bookData.metadata.cover.frame.titleLen);
    this.saveChanges();
  }

  changeTileColor(color) {
    this.bookData.metadata["cover"]["titleColor"] = color;
    this.saveChanges();
  }

  changeFont(font:string) {
    this.bookData.metadata.cover.titleStyle = font;
    this.saveChanges();
  }

  changeDob(date:string) {
    this.bookData.metadata["cover"]["dob"] = date;
    this.saveChanges();
  }

  changeIsDob() {
    this.bookData.metadata.cover.isDob = !this.bookData.metadata.cover.isDob;
    this.saveChanges(); 
  }

  saveChanges() {
    this.parseImage(Object.assign({}, this.bookData.metadata.images[0], this.bookData.metadata["cover"]), 0).then(res=> {
      this.uploadDebounced();
    });
  }

  uploadDebounced() {
    this.uploadSubject.next(true);
  }

  async updateBooks(cover = false) {
    return new Promise(resolve=> {
      this.service.updateBooks(cloneDeep(this.bookData), cover).then(x=> {
        if(x && x.data){
          this.dataService.setBookData(x.data);
          this.bookData = this.dataService.getBookData();
        }
        resolve(true);
      });
    })
  }

  togglePreview() {
    this.gtm.event('click_preview', {})
    this.inPreview = !this.inPreview;
  }

  nextPage() {
    if(this.currentPage < this.imgPreviews.length){
      this.currentPage+=2;
      this.lastPage = 0;
      this.imgPreviews = [...this.imgPreviews]
    }else if(!this.lastPage) {
      this.lastPage+=1;
    }
  }

  prevPage() {
    if(this.lastPage){
      this.lastPage = 0;
    }else if(this.currentPage > 0){
      this.lastPage = 0;
      this.currentPage-=2
    }
  }

  getcurrentPage(index:number) {
    if(this.currentPage == 0 && index == 0)
      return "viewLeft"
    else if(this.currentPage == 2) {
      return index == 1 ? "viewRight" : "";
    } else if(index < this.currentPage && index > this.currentPage - 3) {
      return index % 2 == 0 ? "viewLeft" : "viewRight";
    }
    return "";
  }

  editFlipbook() {
    this.gtm.event('click_edit', {});
    this.inEdit = true;
    this.getEditable();
      // setTimeout(()=> this.router.navigate(["edit"], {relativeTo: this.route}));
  }

  editCover() {
    this.gtm.event('click_editCover', {});
    this.inFlipBook = !this.inFlipBook;
    this.router.navigate(['cover'], {relativeTo: this.route})
  }

  scrollToPage(pageId:number) {
    document.getElementById("d"+pageId)?.scrollIntoView();
  }

  retry(i) {
    if(this.handler.failed[i]) {
      document.getElementById("file-input-"+i)?.click();
    }
  }

  async retryUpload(event, i) {
    if(this.handler.failed[i]) {
      const { pageId, imageId }  = this.handler.failed[i];
      delete this.handler.failed[i];
      const success = await this.addUpload(event, pageId, imageId, true, true)
      if(!success) {
        this.removeFailed();
      }

      if(document.getElementById('file-input-'+i)) {
        // @ts-ignore
        document.getElementById('file-input-'+i).value = '';
      }
    } else {
      this.removeFailed();
    }
  }

  async uploadFiles(event:any, isSave:boolean) {
    return new Promise(async (resolve)=> {
      this.gtm.event('click_addPage', {});
      if(this.getFailed()) {
        this.removeFailed();
      }
      const files = this.imgPreviews;
      // +1 for cover
      const newFiles:Array<File> = Array.from(event.target.files)
      if((this.utils.maxImages + 1) >= files.length + newFiles.length){
        let self = this;
        const ids = self.bookData.metadata.images.map(x=> !x.metadata.collage ? x.id : x.metadata.collage.metadata.images.map(y=> y.id) ).flat();
        const mIds = self.originalUrls.keys();
        const imageId = Math.max(...mIds, ...ids) + 1;
        
        const pageId = self.bookData.metadata.images.length;
        console.log("adding", pageId, imageId);
        // this.handler.progressSubscription.add(()=> this.getHandlerData())
        await this.addUpload(event, pageId, imageId, isSave, false).then(x=> {
          this.toaster.success("Photos added!")
          resolve(true)
        });
      } else resolve(false)
    });
  }

  async addUpload(event, pageId, imageId, isSave, isRetry) {
    return new Promise(async (resolve)=> {
      const files = await this.uploader.verifySelection(event, (this.utils.maxImages - this.imgPreviews.length - 1));
      if(files){
        // preview set for uncrop
        if(isSave)
        this.handler.getPreviews().subscribe(progress => {
          if(progress.result)
            this.imgPreviews[progress.pageId] = progress.result;
        });
          // final preview set
        this.handler.getSubscribe().subscribe(progress => {
          this.progress = Math.min(100, ((this.handler.processed/files.length)*100));
          if(progress.result)
            this.updateSmartPreviews(progress.pageId, progress, isSave);
          if(progress.processed == files.length) {
            this.handler.processed = 0;
            this.progress = 0;
            resolve(true)
          }
        });
        this.handler.getErrorSubscribe().subscribe(error => {
          this.imgPreviews[error.pageId] = '';
        });
        this.handler.queueImages(files, this.bookData, pageId, imageId, false, isSave, isRetry);
        
      } else 
      resolve(false);
    })
  }

  orderNow() {
    if(this.getFailed()) {
      this.checkFailedImages();
      return;
    }

    const len = this.bookData.metadata.images.length;
    if(len - 1 < this.utils.minImages) {
      this.gtm.event('click_orderNA', {});
      this.inPreview = false;
      // this.toaster.info("Add "+ (this.utils.minImages - this.bookData.metadata.images.length) + " more photos to ", "Order Whitebook")
      this.modalService.open("Add "+ (this.utils.minImages - (len -1)) + " more photos to "+"Order your Whitebook").then((res:any)=> {
        if(res) {
          this.gtm.event('click_orderAdd', {});
          document.getElementById("file-input")?.click();
        } else 
        this.gtm.event('click_orderNo', {});
      })
      return;
    }
    this.gtm.event('click_order', {});
    if(this.toaster.toasts[0])
      this.toaster.remove(this.toaster.toasts[0].toastId)
    if(this.bookData.status != 'IN_TRANSIT') {
      const eId = this.gtm.setEventId("InitiateCheckout");
      const bookData = this.dataService.getBookData();
      this.service.createOrder(bookData?.id, bookData.type, eId).then((res:any)=> {
        if(res.success && res.data.id) {
            this.gtm.trackEvent("InitiateCheckout", {
              order_id: res.data.id,
              content_id: res.data.bookId,
              external_id: res.data.accountId
            })
          this.dataService.orderData = res.data;
          this.dataService.setOriginalImages(this.originalUrls)
          // if(res.data.deliveryAddressId)
          //   this.router.navigate(['checkout/billing']);
          // else
            this.router.navigate(["checkout", this.dataService.bookId, "address"]);
        } else if(res.message) {
          this.toaster.error(res.message);
        };
      })
    }
    else this.router.navigate(['orders']);

  }

  checkFailedImages() {
    this.modalService.open("Some of your photos failed to upload.\nWould you like to retry?").then((res:any)=> {
      if(res) {
        this.imgPreviews.map((x,i)=> {
          if(this.dataService.getImageStatus(i) == 'failed') {
            this.failed.push(i);
          }
        })
        if(this.failed.length)
          setTimeout(()=>  {
            // @ts-ignore
            document.getElementById('d'+this.failed[0]).scrollIntoView();
          },1000)
      } else  {
        this.removeFailed();
      }
    })
  }

  getFailed() {
    const f = Object.values(this.handler.failed)?.map(x=> x.pageId)||[];
    return f.length;
  }

  removeFailed() {
    this.failed = [];
    const pageIds = Object.values(this.handler.failed)?.map(x=> x.pageId);
    this.handler.failed = {};
    this.dataService.clearImageStatus();
    this.imgPreviews = this.imgPreviews.filter((x,i)=> !pageIds.includes(i));
  }

  getOrderActive() {
    return this.getEditable();
  }

  exitPreview() {
    this.inPreview = !this.inPreview;
    setTimeout(()=> this.router.navigate(["flipbook", this.dataService.bookId]))
  }


  openMagnets() {
    this.modalService.openMagenets(this.originalUrls, this.bookData, this);
  }

  showWarning(i) {
    let msg = "";
    if(this.imgNameMap[i] && this.imgNameMap[i][1] > 1) {
      let name = this.imgNameMap[i][0];
      let p = this.imgNameMap.map((x,i)=> x[0] == name ? i : null).filter(x=> x);
      if(p && p.length > 1)
        msg += ("Dublicate photos found in pages " + p.join(", "));
      else if((this.bookData.metadata.images[i].metadata.dpi||this.bookData.metadata.images[i].dpi) < this.utils.minDpi) {
        msg += "The photo on page " + i + " looks low in quality. We recommend updating it to ensure the best print quality."
      }
      this.modalService.open(msg, true).then((res:any)=> {
      });
    } 
  }

  ngOnDestroy() {
    this._destroying = true;
    this.routeSub?.unsubscribe();
    this.uploadSubs?.unsubscribe();
    this.uploadCoverSubs?.unsubscribe();
  }
}
