import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DataService } from './data-service.service';
import { UploaderComponent } from './uploader/uploader.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientService } from './service/httpService';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { EditComponent } from './edit/edit.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthInterceptor } from './service/AuthInterceptor';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { UploaderService } from './uploader.service';
import { PaymentComponent } from './payment/payment.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AddressComponent } from './address/address.component';
import { BillingComponent } from './billing/billing.component';
import { SuccessComponent } from './success/success.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleryComponent } from './gallery/gallery.component';
import { CustomUtils } from './service/customUtils';

import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { ToasterComponent } from './toaster/toaster.component';
import { CommonModule } from '@angular/common';
import { SortablejsModule } from '@maksim_m/ngx-sortablejs';
import { OrdersComponent } from './orders/orders.component';
import { SafeUrlPipe } from './service/SafeUrlPipe';
import { PreviewComponent } from './preview/preview.component';
import { EditCoverComponent } from './edit-cover/edit-cover.component';
import { ModalModule } from 'ngx-bootstrap/modal';

import * as Sentry from '@sentry/angular-ivy';
import { GtmService } from './gtm.service';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
// import { ModalComponent, NgbdModalComponent } from './modal/modal.component';
import { NgbDatepickerModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGurad } from './service/AuthGuard';
import { PaginationComponent } from './pagination/pagination.component';
import { CoverComponent } from './cover/cover.component';
import { PagesComponent } from './pages/pages.component';
import { CollageComponent } from './collage/collage.component';
import { CroppieComponent } from './croppie/croppie.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SignupComponent } from './signup/signup.component';
import { PageGuard } from './service/PageGuard';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ModalComponent } from './modal/modal.component';
import { ImageProcessingService } from './imageprocess.service';
import { HandlerService } from './handler.service';
import { PreviewService } from './preview.service';
import { LongPressDirective } from './long-press.directive';
import { SelectPhotosComponent } from './select-photos/select-photos.component';
import { CouponsComponent } from './coupons/coupons.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { MagnetComponent } from './magnet/magnet.component';


@NgModule({
  declarations: [
    SafeUrlPipe,

    AppComponent,
    LoginComponent,
    HomeComponent,
    GalleryComponent,
    UploaderComponent,
    // FlipbookComponent,
    EditComponent,
    CheckoutComponent,
    AddressComponent,
    BillingComponent,
    PaymentComponent,
    SuccessComponent,
    OrdersComponent,
    PreviewComponent,
    EditCoverComponent,
    SignupComponent,
    SelectPhotosComponent,
    CouponsComponent,
    CustomSelectComponent,
    MagnetComponent,
    // EditPageComponent,

    // EditorComponent,
    CoverComponent,
    PagesComponent,
    CollageComponent,
    CroppieComponent,
    DatePickerComponent,
    ModalComponent,
    // NgbdModalComponent,
    // ModalComponent,

    ToasterComponent,
    PaginationComponent,
    LongPressDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    SortablejsModule.forRoot({
      animation: 200,
    }),
    ToastrModule.forRoot({ 
      toastComponent: ToasterComponent,
      timeOut: 2500,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      maxOpened: 1
    }),
    ToastContainerModule,
    ImageCropperModule,
    NgOtpInputModule,
    NgbModalModule,
    NgbDatepickerModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    FontAwesomeModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    BackButtonDisableModule.forRoot(),
    TypeaheadModule.forRoot(),
    GoogleTagManagerModule.forRoot({
      id: environment.GTM_ID // Replace with your GTM Container ID
    }),

    CarouselModule

  ],
  providers: [
    HandlerService,
    ImageProcessingService,
    PreviewService,
    // SamrtCropService,
    DataService,
    UploaderService,
    CustomUtils,
    GtmService,
    AuthGurad,
    PageGuard,

    HttpClient,
    HttpClientService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    // {provide: 'googleTagManagerId', useValue: environment.GTM_ID }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
