import { Component, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../data-service.service';
import { APIService } from '../service/ApiService';
import { Router } from '@angular/router';
import { CustomUtils } from '../service/customUtils';
import { ToastrService } from 'ngx-toastr';
import { UploaderService } from '../uploader.service';
import { cloneDeep } from 'lodash';
import { GtmService } from '../gtm.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {

  @ViewChild('canvas')
  canvas: ElementRef<HTMLCanvasElement>|any = null;

  context: CanvasRenderingContext2D|any = null;

  canvaWidth = 2000;

  resultImage:any;

  previewImages:Array<any> = [];
  // filesRead:Array<any> = [];
  compressImg:Array<any> = [];


  files:Array<any> = [];

  accepts = "";

  dataService: DataService;
  service: APIService;

  imageMeta:any = [];
  selectedImg = 0;

  isNext = true;

  ignoreFiles:Array<string> = [];

  constructor(dataService : DataService, service : APIService, 
      private router: Router, public utils: CustomUtils, private gtm: GtmService,
      private toaster: ToastrService, private uploader: UploaderService,
      private custom: CustomUtils){
    this.dataService = dataService;
    this.service = service;
    this.accepts = this.uploader.accepts;
  }

  ngOnInit() {
    this.dataService.setLoader(false);
    this.files = this.dataService.getFiles();
    if(!this.files || !this.files.length) {
      this.router.navigate(["/"]);
    } else {
      this.selectAllImages();
      this.upload();
    }
  }

  selectAllImages() {
    this.imageMeta.forEach((x:any)=> {
      x["isTicked"] = true;
    })
    this.imageMeta= this.imageMeta.sort((a:any, b:any)=> a.date-b.date)
  }

  selectImages() {
    let imgCopy = cloneDeep(this.imageMeta);
    let selected = imgCopy.filter((x:any)=> !this.ignoreFiles.includes(x.name)).sort((a:any, b:any)=> b.dpi-a.dpi).slice(0,this.utils.maxImages).sort((a:any, b:any)=> b.date-a.date).map((x:any)=> x.src);
    this.imageMeta.forEach((x:any)=> {
      x["isTicked"] = !this.ignoreFiles.includes(x.name) && selected.includes(x.src)
    })
    this.selectedImg =  this.imageMeta.filter((x:any)=> x.isTicked).length;
  }

  selected(i:number,val:any) {
    this.gtm.event('select', {val, i})
    if(this.selectedImg && this.selectedImg < this.utils.maxImages) {
      let data =  this.imageMeta[i];
      if(this.ignoreFiles.length && this.ignoreFiles.includes(data["name"])) return;
      if(val){
        data['isTicked'] = true;
        this.imageMeta[i]['isTicked'] = true;
      }else {
        data['isTicked'] = !data['isTicked'];
      }
      this.imageMeta[i] = data;
      this.selectedImg =  this.imageMeta.filter((x:any)=> x.isTicked).length;
    } else {
      this.toaster.warning("Max "+this.utils.maxImages+" photos can be selected for each book.")
    }
  }

  upload(){
    this.gtm.event('click_upload', {})
    let imageMeta = this.imageMeta.filter((x:any)=> x.isTicked);
    // let metadata = {images: imageMeta};
    // // if(imageMeta.length >= this.utils.minImages){
      //   const files:any = [];
      //   imageMeta.forEach((x:any, i:any)=> {
        //     files.push(this.files.find(f=> f.name == x.name))
        //     this.dataService.setImageMeta(i, x);
        //     delete x.name;
      //   })
      // metadata = {images: [imageMeta[0], ...imageMeta]};
      this.dataService.setFiles([this.files[0], ...this.files]);
      this.validateUser();
    // } else{
    //   this.toaster.error("Select/Add atleast "+this.utils.minImages+" photos to create your Whitebook!")
    // }
  }

  async validateUser() {
    if(await this.service.validateUser()){
      // this.dataService.setActiveStep(this.dataService.getActiveStep() + 1)
    }
    else {
      const token = this.dataService.getActiveToken();
      if(token && token != "null") {
        this.toaster.warning("Session timeout!");
      }
      this.router.navigate(["login"])
    }
  }

  ngOnDestroy() {
    // this.filesRead.map((x:any)=> URL.revokeObjectURL(x));
  }

}
