import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

    public transform(value: any): SafeUrl {
        return !value ? value : typeof value == "string" ? this.transformData(value) 
            : this.transformData(value.changingThisBreaksApplicationSecurity);
    }

    private transformData(value: any): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(value);
    }
}