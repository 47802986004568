import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})

export class DatePickerComponent implements OnInit {
  selectedDay!: number;
  selectedMonth!: number;
  selectedYear!: number;

  displayMonths = 1;
	navigation = 'select';
	showWeekNumbers = false;
	outsideDays = 'visible';

  @Input()
  date!:string;

  @Input()
  disabled!:boolean;

  selectedDate;

  @Output()
  selected = new EventEmitter();

  static crrYear = new Date().getFullYear();

  days: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  months: number[] = Array.from({ length: 12 }, (_, i) => i + 1);
  years: number[] = Array.from({ length: 50 }, (_, i) => DatePickerComponent.crrYear - i);

  isLeapYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  // Define the maximum number of days for each month
  


  constructor() {}

  ngOnInit(): void {
    if(!this.date) {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      this.date = firstDay.toLocaleDateString().replaceAll("/","-");
    }
    [this.selectedDay, this.selectedMonth, this.selectedYear] = this.date.split("-").map(x=> +x);
  }

  ngOnChanges() {
    this.selectedDate = {day : this.selectedDay, month: this.selectedMonth, year: this.selectedYear}
  }

  validateDate(event) {
    this.selectedDay = event.day;
    this.selectedMonth = event.month;
    this.selectedYear = event.year;
    const daysInMonth = [
      31, this.isLeapYear(this.selectedYear) ? 29 : 28,
      31, 30, 31, 30, 31, 31, 30, 31, 30, 31
    ]
    const isValidDate =
    this.selectedDay >= 1 && this.selectedDay <= daysInMonth[this.selectedMonth - 1];
    if (!isValidDate) {
      console.log('Invalid date selected');
    } else {
      this.selected.emit(this.getDate())
    }
  }

  getDate():string {
    let date:any[] = [];
    date.push(this.selectedDay > 9 ? this.selectedDay : "0"+this.selectedDay);
    date.push(this.selectedMonth > 9 ? this.selectedMonth : "0"+this.selectedMonth);
    date.push(this.selectedYear);
    return date.join("-");
  }
}
