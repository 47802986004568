import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data-service.service';
import { APIService } from '../service/ApiService';
import { ToastrService } from 'ngx-toastr';
import { GtmService } from '../gtm.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  userData  :any ={email:"", phone:"", password:""};
  isValidEmail = false;

  @ViewChild("emailInput") emailInput;

  constructor(private data: DataService, private api: APIService, 
    private toaster: ToastrService, private router: Router, private gtm: GtmService) {}

  ngOnInit() {
    this.gtm.event('click_signup', {});
    this.gtm.sendConversion('conversion', {'send_to': 'AW-11124049813/6yEJCIGyxpQZEJWPrrgp'});
  }

  ngAfterViewInit() {
    this.emailInput.nativeElement.focus();
  }

  public emailOnlyValidator(event:any) {
    this.isValidEmail = event.target?.validity?.valid;
  }

  skip() {
    this.gtm.event('click_skipEmail', {});
    // this.router.navigate(['upload'])
    this.postEmail("");
  }

  isValidEmailIn(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  
  addEmail() {
    const email = this.userData.email?.trim();
    if(!this.isValidEmailIn(email)) {
      this.toaster.warning("Please enter valid email address!");
      return;
    }
    this.gtm.event('click_email', {});
    this.gtm.sendMoe("add_email", email);
    this.postEmail(email);
  }

  postEmail(email) {
    const eId = this.gtm.setEventId("Lead")
    this.gtm.trackEvent("Lead", { external_id: this.data.accId });
    if(!email) {
      email = this.data.getEmail();
    }
    this.data.setEmail(email);
    this.api.addEmail(email, eId).then(res=> {
      if(res && res.data && res.data.active){
        this.router.navigate(['upload'])
      } 
      else {
        this.toaster.error("Something went wrong!")
      }
    })
  }

}
