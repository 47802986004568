<div *ngIf="this.dataService.loader" class="overlay-loader">
  <div class="spinner-border loader"></div>
</div>
<div *ngIf="getPath() != 'home' && getAction()" class="popAction">
  <div class="d-flex" (click)="clickActionBar()" style="justify-content: space-around; height: 100%; align-items: center;">
    <ng-container [ngSwitch]="getPath()">
      <ng-container *ngSwitchCase="'login'">
        <div class="px-2 col-7">
          <p style="font-size: smaller; margin: 0;padding-left: 0.7rem;">Sign in to preview your beautifully crafted Whitebook
          </p>
        </div>
        <div class="col-5 m-auto text-center">
          <button class="btn btn-sm btn-primary">
            Continue
            <i class="fa-solid fa-arrow-right fa-fade"></i>
          </button>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="px-2 col-8">
          <p style="font-size: smaller; margin: 0;padding-left: 0.7rem;">Your beautifully crafted Whitebook is waiting for you
            <i class="fa-solid fa-arrow-right"></i>
          </p>
        </div>
        <div class="col-4 text-center fa-beat">
          <img [src]="getCoverPrev() | safeUrl" width="60" height="60" style="box-shadow: 0px 0px 5px #00000085;">
        </div>
      </ng-container>
    </ng-container>
    
    <!-- <div class="col-5">
      <button class="btn btn-sm btn-primary" >
        Continue
        <i class="fa-solid fa-image fa-fade"></i>
      </button>
    </div> -->
  </div>
</div>
<div class="row header w-100 m-0" [ngStyle]="{'visibility' : !getAction() || getPath() == 'home' ? '' : 'hidden'}">
  <div class="col-3 p-0">
    <i *ngIf="getIsBack()" id="back_wb" class="fa fa-solid fa-arrow-left" style="padding: 12px;color: #00000099;scale: 1.2;" (click)="goBack()"></i>
    <i *ngIf="!inFrame && !getIsBack()" id="back_lo" class="fa fa-solid fa-arrow-right-from-bracket" style="padding: 12px;color: #00000099;scale: 1.2;" (click)="logout()"></i>
  </div>
  <div class="col-6">
  </div>
  <div class="col-3 p-0" style="text-align: end;">
<!-- <i *ngIf="dataService.draftCount" class="fa-solid fa-cart-shopping" style="padding: 12px;color: #00000099;" (click)="goOrders()" ></i> -->
    <i class="fa-solid fa-down-left-and-up-right-to-center" (click)="minFrame()" style="padding: 12px;color: #00000099;"></i>
  </div>
  <div style="position: absolute; visibility: hidden">
    <p style="font-family: 'Carena';">hi</p>
    <p style="font-family: 'Ronda';">hi</p>
    <p style="font-family: 'Nunito';">hi</p>
  </div>
</div>
<div class="content">
  <router-outlet></router-outlet>
  <!-- <ngbd-modal-component></ngbd-modal-component> -->
</div>

<!-- <div class="footer"></div> -->