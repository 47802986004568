import { Component, ElementRef, ViewChild } from '@angular/core';
import { DataService } from '../data-service.service';
import { ToastrService } from 'ngx-toastr';
import { UploaderService } from '../uploader.service';
import { GtmService } from '../gtm.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { APIService } from '../service/ApiService';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('hideElement', [
      state('visible', style({
        opacity: 1,
        height: '*',
      })),
      state('hidden', style({
        opacity: 0,
        height: '0',
        display: 'none'
      })),
      transition('visible => hidden', [
        animate('600ms ease-out'),
      ]),
      transition('hidden => visible', [
        animate('0ms ease-in'),
      ]),
    ]),
  ],
  providers: [NgbCarouselConfig]
})
export class HomeComponent {

  @ViewChild(NgbCarousel) carousel!: NgbCarousel;

  @ViewChild("uploader")
  uploaderInput!: ElementRef;


  dataService: DataService;
  files = [];
  compressImg:any = [];
  accepts;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    items: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    autoplayTimeout: 7000,
    autoplayHoverPause: true,
    // nav: true,
    // navText: [ '<i class="fa fa-solid fa-chevron-left"></i>', '<i class="fa fa-solid fa-chevron-right"></i>' ],
  }

  constructor(dataService: DataService, private gtm: GtmService,
    private service : APIService, private uploader: UploaderService, config: NgbCarouselConfig,
    private router: Router) {
    this.dataService = dataService;
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit() {
    this.accepts = this.uploader.accepts;
    this.dataService.reset()
  }

  selectFiles(event:Event) {
    event.stopPropagation();
    setTimeout(()=> this.uploadFiles(event),2);
  }

  uploadFiles(event: any) {
    this.gtm.event('click_gallery', {})
    this.dataService.reset()
    this.dataService.postMessageParent('max');
    this.dataService.setLoader(true);
    this.uploader.verifySelection(event).then(async res=> {
      //clear to detect file change
      if(this.uploaderInput) {
        this.uploaderInput.nativeElement.value = '';
      }
      if(res) {
        this.dataService.setFiles(res, true);
        this.uploader.uploadPending = true;
        if(await this.service.validateUser()) {
          this.router.navigate(["upload"])
        }
      }
      this.dataService.setLoader(false);
    })
  }

  sendGae(event:string) {
    this.gtm.event(event, {})
  }

  // closeInfo(event:any) {
    //   // event.stopPropagation();
    //   // event.preventDeafult();
    //   this.dataService.isInfo = !this.dataService.isInfo;
    //   this.gtm.event('click_info', {});
    //   this.postMessageParent(this.dataService.isInfo ? 'info' : 'min');
  // }

  postMessageParent(data:any, val = null) {
    this.dataService.postMessageParent(data, val);
  }

  getImages(type) {
    const imgData = {
      "birthday" : {
        text : "BIRTHDAY MEMORIES",
        images : [ "assets/images/bday/1.jpg", "assets/images/bday/2.jpg", "assets/images/bday/3.jpg", "assets/images/bday/4.png" ]
      },
      "first-year" : {
        text : "FIRST-YEAR JOURNEY!",
        images : [ "assets/images/first/1.jpg", "assets/images/first/2.jpg", "assets/images/first/3.jpg", "assets/images/first/4.png" ]
      },
      "memories" : {
        text : "SPECIAL MEMORIES",
        images : [ "assets/images/memories/1.jpg", "assets/images/memories/2.png", "assets/images/memories/3.png", "assets/images/memories/4.png" ]
      },
      "pregnancy" : {
        text : "PREGNANCY MOMENTS",
        images : [ "assets/images/pregnancy/1.jpg", "assets/images/pregnancy/2.jpg", "assets/images/pregnancy/3.jpg", "assets/images/pregnancy/4.png" ]
      },
      "travel" : {
        text : "FAMILY VACATIONS",
        images : [ "assets/images/travel/1.jpg", "assets/images/travel/2.jpg", "assets/images/travel/3.jpg", "assets/images/travel/4.png" ]
      },
      "childhood" : {
        text : "CHILDHOOD MEMORIES",
        images : [ "assets/images/default/1.jpg", "assets/images/default/2.jpg", "assets/images/default/3.png" ]
      },
      "default" : {
        text : "Whitebook for All",
        images : [ "assets/images/first/1.jpg", "assets/images/bday/1.jpg", "assets/images/pregnancy/1.jpg", "assets/images/travel/1.jpg" ]
      },
      "fathers" : {
        text : "FATHER'S DAY 2024",
        images : [ "assets/images/fathers/7.png", "assets/images/fathers/8.png", "assets/images/fathers/9.png", "assets/images/fathers/10.png" ]
      },
      
    }
    return imgData[type||'default']
  }

  getSlides() {
    return this.getImages(this.dataService.getSku());
  }

}
