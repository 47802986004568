<div class="row">
    <div class="col">
        <div class="p-3">
          <!-- <div class="card-header py-3">
            <h5 class="mb-0">Biling details</h5>
          </div> -->
          <div class="card-body">
            <form (submit)="next()">

              <div class="form-outline mb-3">
                <input type="text" id="fullname" name="fullname" class="form-control" [(ngModel)]="address.fullName" placeholder="Full Name*"/>
              </div>

              <div class="form-outline mb-3">
                <input type="email" id="email" name="email" class="form-control" required [(ngModel)]="address.email" placeholder="Email Address*"/>
              </div>

              <div class="form-outline mb-3">
                <input type="tel" id="phone" name="phone" maxlength="10" class="form-control" [(ngModel)]="address.phone" placeholder="Enter 10 Digit Phone Number*"/>
              </div>

              <div class="form-outline mb-3">
                <textarea type="textarea" id="address" rows="3" name="address" class="form-control" [(ngModel)]="address.address" placeholder="Address*" >
                </textarea>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <div class="form-outline">
                    <input type="text" maxlength="6" id="pincode" name="pincode" class="form-control" [ngModel]="address.pincode" (ngModelChange)="verifyPincodeInput($event)" placeholder="Pincode*"/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-outline">
                    <input type="text" id="city" name="city" class="form-control" [(ngModel)]="address.city" placeholder="City*"/>
                  </div>
                </div>
              </div>
              
              <div class="form-outline mb-3">
                <input type="text" id="state" name="state" class="form-control" [(ngModel)]="address.state" placeholder="State*" />
              </div>

              <div class="form-outline mb-3">
                <input type="text" id="landmark" name="landmark" class="form-control" [(ngModel)]="address.landmark" placeholder="Landmark (Optional)" />
              </div>
    
              <div class="form-check d-flex justify-content-center mb-3">
                <input class="form-check-input me-2" name="terms" style="min-width: 16px;"
                  [(ngModel)]="terms" type="checkbox" value="" id="terms_id" />
                  <label class="form-check-label toc" for="terms_id">
                    <span> 
                      I agree to the Terms of Service and 
                      <a (click)="togglePrivacy()" >Privacy Policy</a>
                      , and I am over 16 years old.
                    </span> 
                  </label>
              </div>
              <button class="btn btn-sm btn-primary next main" type="submit" [disabled]="!terms">Next</button>
            </form>
          </div>
        </div>
      </div>
    
</div>