import { Helper } from './helper'
import { ActivatedRoute, CanActivate, NavigationEnd, UrlTree } from '@angular/router';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { DataService } from '../data-service.service';

@Injectable()
export class AuthGurad implements CanActivate {

    allowedUrls = ['/', '/login', '/home', '/gallery']

    constructor(public router: Router, private data:DataService) { 
       
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const url = state.url.startsWith("/") ? state.url.slice(1) : state.url;
        this.data.setGuardUrl(url);
        const isToken = this.data.getActiveToken() != "";
        if(!isToken){
            this.router.navigate(["login"]);
        }
        return isToken;
    }
}