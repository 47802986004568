<!-- <hr style="margin: 0;"> -->
<nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button class="nav-link" [ngClass]="type == 'stage' ? 'active' : ''" id="nav-draft" (shown.bs.tab)="changeBook('stage')" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Draft</button>
      <button class="nav-link" [ngClass]="type == 'transit' ? 'active' : ''" id="nav-transit" (shown.bs.tab)="changeBook('transit')" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Transit</button>
      <button class="nav-link" [ngClass]="type == 'completed' ? 'active' : ''" id="nav-complete" (shown.bs.tab)="changeBook('completed')" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Completed</button>
    </div>
</nav>
<div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade mt-3" [ngClass]="type == 'stage' ? 'show active' : ''" id="nav-home" role="tabpanel" aria-labelledby="nav-draft">
        <ng-container *ngFor="let book of booksList?.[type]">
            <div *ngIf="book" class="row card-body bookCard" style="padding-left: 10px;">
                <div class="col-4 ">
                    <div *ngIf="book.metadata?.images?.[0]?.src; else noImg;" style="position: relative;">
                        <div *ngIf="book.metadata?.images[0].src" class="cover-rim" [ngClass]="'e'+book.metadata?.cover?.color?.slice(1)"></div>
                        <img class="content" width="300" (click)="selectFlipBook(book.id)" [src]="book.metadata?.images[0].src | safeUrl" alt="">
                    </div>
                </div>
                <div class="col-8">
                    <div class="row">
                        <div class="col-9">
                            {{book.title}}<br>
                            <span style="font-size: small;color: rgba(0, 0, 0, 0.54);">
                                #{{book.id}}
                                <!-- on {{book.month}} {{book.year}} -->
                            </span>
                        </div>
                        <div class="col-3">
                            <i class="fas active fa-duotone fa-trash" (click)="deleteFlipBook(book.id)"></i>
                        </div>
                        <div class="col text-center mt-2">
                            <button class="btn btn-sm btn-secondary" (click)="selectFlipBook(book.id)">Preview & Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr *ngIf="book">
        </ng-container>
        <pagination *ngIf="booksList?.[type]?.length && total > pageSize" [pageNumber]="pageNo" [pageSize]="pageSize" [numberOfElements]="booksList?.[type].length"
            [totalNumbers]="total" (changeEmitter)="onPageChange($event)"></pagination>
        <ng-container *ngIf="!booksList?.[type]?.length">
            <div class="col text-center">
                <p>No BOOKS FOUND</p>
            </div>
        </ng-container>
    </div>
    <ng-template #noImg>
        <img class="imgPrev" style="object-fit: none;opacity: 0.4" src="assets/images/favicon.png" alt="">
    </ng-template>
    <div class="tab-pane fade mt-3" [ngClass]="type == 'transit' ? 'show active' : ''" id="nav-profile" role="tabpanel" aria-labelledby="nav-transit">
        <ng-container *ngFor="let book of booksList?.[type]">
            <div *ngIf="book" class="row card-body bookCard">
                <div class="col-4 px-3">
                    <div *ngIf="book.metadata?.images?.[0]?.src; else noImg;" style="position: relative;">
                        <div *ngIf="book.metadata?.images[0].src" class="cover-rim" [ngClass]="'e'+book.metadata?.cover?.color?.slice(1)"></div>
                        <img class="content" width="300" (click)="selectFlipBook(book.id)" [src]="book.metadata?.images[0].src | safeUrl" alt="">
                    </div>
                </div>
                <div class="col-8">
                    <div class="row h-100">
                        <div class="col-12">
                            {{book.title}}<br>
                            <span style="font-size: small;color: rgba(0, 0, 0, 0.54);">
                                #{{book.id}}
                                <!-- <br>on {{book.month}} {{book.year}} -->
                            </span>
                        </div>
                        
                        <ng-container [ngSwitch]="book.orderStatus">
                            <div *ngSwitchCase="'PENDING'" class="col-12 text-center mt-3">
                                <small class="txt-blue">
                                    <i class="fa-solid fa-circle-info"></i>
                                    Payment is being processed.
                                </small>
                                <!-- <small *ngIf="book.orderStatus != 'PENDING'" class="text-info">
                                    {{book.orderStatus}}
                                </small> -->
                            </div>
                            <div *ngSwitchCase="'FAILED'" class="row w-100">
                                <div class="col-6 text-center mt-2 p-0">
                                    <small class="text-danger">
                                        Payment failed Please try again!
                                    </small>
                                </div>
                                <div class="col-6 text-end mt-2">
                                    <button class="btn btn-sm btn-secondary" (click)="retryPayment(book.id)">Try Again
                                        <span><small>!</small></span>
                                    </button>
                                </div>
                            </div>
                            <div *ngSwitchDefault class="col-12 text-center mt-2 p-0">
                                <div class="row w-100">
                                    <div class="col p-0">
                                        <ul id="progressbar" >
                                            <i id="step1" class="step0 fas fa-solid fa-check" [ngClass]="book.orderStatus == 'CONFIRMED' ? 'active':''"></i>
                                            <i id="step2" class="step0 fas fa-regular fa-truck-fast" [ngClass]="book.orderStatus == 'SHIPPED' ? 'active':''"></i>
                                            <i id="step3" class="step0 fas fa-solid fa-gift" [ngClass]="book.orderStatus == 'DELIVERED' ? 'active':''"></i>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-12">
                            <i *ngIf="book.orderStatus == 'FAILED'" class="fas fa-solid fa-pen" (click)="editFlipbook(book.id)"></i>
                            <small *ngIf="book.paymentStatus == 'COD'" class="text-muted">
                                To pay ₹{{book.total}} on delivery
                            </small>
                            <small *ngIf="book.paymentStatus == 'PART'" class="text-muted">
                                To pay remaining ₹{{book.total - book.partialAmount}} on delivery
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <hr *ngIf="book">
        </ng-container>
        <pagination *ngIf="booksList?.[type]?.length  && total > pageSize" [pageNumber]="pageNo" [pageSize]="pageSize" [numberOfElements]="booksList?.[type].length"
            [totalNumbers]="total" (changeEmitter)="onPageChange($event)"></pagination>
        <ng-container *ngIf="!booksList?.[type]?.length">
            <div class="col text-center">
                <p>No BOOKS FOUND</p>
            </div>
        </ng-container>
    </div>
    <div class="tab-pane fade" [ngClass]="type == 'completed' ? 'show active' : ''" id="nav-contact" role="tabpanel" aria-labelledby="nav-complete">
        <ng-container *ngIf="!booksList?.[type]?.length">
            <div class="col text-center">
                <p>No BOOKS FOUND</p>
            </div>
        </ng-container>
        <ng-container *ngFor="let book of booksList?.[type]">
            <div *ngIf="book" class="row card-body bookCard">
                <div class="col-4 px-3">
                    <div *ngIf="book.metadata?.images?.[0]?.src; else noImg;" style="position: relative;">
                        <div *ngIf="book.metadata?.images[0].src" class="cover-rim" [ngClass]="'e'+book.metadata?.cover?.color?.slice(1)"></div>
                        <img class="content" width="300" (click)="selectFlipBook(book.id)" [src]="book.metadata?.images[0].src | safeUrl" alt="">
                    </div>
                </div>
                <div class="col-8">
                    <div class="row h-100">
                        <div class="col-5">
                            {{book.title}}<br>
                            <span style="font-size: small;color: rgba(0, 0, 0, 0.54);">
                                #{{book.id}}
                                <!-- <br>on {{book.month}} {{book.year}} -->
                            </span>
                        </div>
                        <div class="col-5 text-center m-auto">
                            <button class="btn btn-secondary" (click)="selectFlipBook(book.id)">Preview
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr *ngIf="book">
        </ng-container>

    </div>
    <br>
    <br>
</div>
<div class="row text-center mx-auto" style="justify-content: center;position: sticky;bottom: 20px; width: 33%; min-width: 157px; z-index:999;">
    <button class="btn btn-sm btn-primary main" (click)="createNow()">Create New</button>
    <!-- <div class="col-6" style="position: fixed;bottom: 5vh;">
        <button class="btn btn-sm btn-primary" (click)="createNow()">Create Now</button>
    </div> -->
</div>