import { Injectable } from '@angular/core';
import { HttpClientService } from './httpService';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { DataService } from '../data-service.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class APIService {

    public loader = false;

    private baseUrl = environment.API_URL + "/";
    // private baseUrl = "https://stage.whitebook.world/api/"

    constructor(private httpService: HttpClientService, private router: Router,
        private data: DataService) { }

    getBaseUrl() {
        return this.baseUrl;
    }

    getPaymentUrl() {
        return this.baseUrl + "payment/complete" + (window.location.search||this.data.getParams());
    }

    async validateUser(noRedirect = false) : Promise<boolean> {
        return await this.validate(noRedirect);
    }

    private validate(noRedirect:boolean): Promise<boolean> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "user/validate";
            self.httpService.get(url).toPromise().then((res: any) => {
                if(!res.success && !noRedirect) setTimeout(()=> self.router.navigate(["login"]));
                else if(res) {
                    this.data.isActiveUser = true;
                    this.data.setAccData({email:res.data.email, phone:res.data.phone, id : res.data.id});
                    this.data.setToken(res.data.token);
                }
                resolve(res.success);
            }), (err: any) => {
                resolve(false);
            }
        });
    }

    recentDraft(): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "book/recentDraft";
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    draftCount(): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "book/draftCount";
            self.httpService.get(url).toPromise().then((res: any) => {
                if(res) this.data.draftCount = res;
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    login(phone:number): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "login?&phone=" + phone;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    verifyOtp(phone: any, otp: string): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "login/verify?phone="+phone + "&otp=" + otp;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    logout(): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "user/logout";
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    addEmail(value: any, eId): Promise<any> {
        let self = this;
        let headers = new HttpHeaders();
        if(eId)
            headers = this.getHeaders(eId, null);
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "user/email?value="+value;
            self.httpService.get(url, {headers}).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    // uploadFiles(chunks: any, bookId:number, obj: any, index: number): Promise<any> {
    //     let self = this;
    //     return new Promise<any>((resolve, reject) => {
    //         let url = this.baseUrl + "upload/chunks?bookId="+bookId+ "&obj=" + obj + "&counter=" + index;
    //         self.httpService.post(url, chunks).toPromise().then((res: any) => {
    //             resolve(res);
    //         }), (err: any) => {
    //             reject(err);
    //         }
    //     });
    // }

    getPresignedUrls(data:any) {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/putUrl";
            self.httpService.post(url, data).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    getPresignedUrlsMultiPart(data:any) {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/putUrls";
            self.httpService.post(url, data).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    initiateMultipartUpload(data:any) {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/initMultipart";
            self.httpService.post(url, data).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    completeMultipartUpload(data:any) {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/completeMultipart";
            self.httpService.post(url, data).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    saveMetaUrl(data:any) {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/saveMetaUrl";
            self.httpService.post(url, data).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });

    }

    // S3 API Call
    uploadFileToS3(url:string, blob:Blob):Observable<any> {
        let self = this;
        const headers = new HttpHeaders({
            'Content-Type': 'application/octet-stream'
        });
        return self.httpService.put(url, blob, {headers, observe: 'response'});
    }

    uploadFiles(file: Blob, bookId:number, obj: any, chunk: number, chunks:number, type:string = 'image/png'): Promise<any> {
        let self = this;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('chunk', chunk.toString());
        formData.append('chunks', chunks.toString());
        formData.append('bookId', bookId.toString());
        formData.append('obj', obj.toString());
        formData.append('type', type);

        let headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type','multipart/form-data')
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/chunk";
            self.httpService.post(url, formData, {headers}).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    verifyPincode(pincode:number) {
        const url = "https://api.postalpincode.in/pincode/"+pincode;
        return new Promise<any>((resolve, reject) => {
            try {
                fetch(url).then(function(response) {
                    return response.json()
                }).then(resolve).catch(reject);
            } catch(e){
                reject
            }
        });
    }

    uploadCover(chunks: any, coverId = null): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/cover"+ (coverId ? "/update" : "") + (coverId ? "?coverId="+coverId : "");
            self.httpService.post(url, chunks).toPromise().then((res: any) => {
                if(res?.success)
                    resolve(res.data);
                else resolve(null)
            }), (err: any) => {
                resolve(null)
            }
        });
    }

    uploadPreview(chunks: any, bookId:number, obj: any, index: number): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/preview?bookId="+bookId+ "&obj=" + obj + "&counter=" + index;
            self.httpService.post(url, chunks).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    async createBooks(data: any, eId): Promise<any> {
        const param = new URLSearchParams(this.data.getParams());
        const sku = this.data.getSku();
        let type:any = [];
        sku ? type.push(sku) : null;
        if(param && param.has("utm_source")) {
            type.push(param.get("utm_source"));
            type.push(param.get("utm_medium"));
            type.push(param.get("utm_campaign"));
        }
        let self = this;
        data["metadata"] = typeof data["metadata"] == "string" ? JSON.parse(data["metadata"]) : data["metadata"];
        const srcMap = new Map();
        delete data["metadata"]?.cover.src;
        data["metadata"]?.images.forEach((x:any, i)=> { 
            if(x.metadata.style == "collage" && x.metadata.collage) {
                x.metadata.collage.metadata.images.forEach((cx:any, j:number)=> {
                    srcMap.set(i+"_"+j,cx.src)
                    delete cx.src
                })
            } 
            else srcMap.set(i,x.src);
            delete x.src
        });
        data["metadata"] = JSON.stringify(data["metadata"]);

        let headers = new HttpHeaders();
        if(eId)
            headers = this.getHeaders(eId, null)
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "book/create" + (type.length ? "?type=" + encodeURIComponent(type.join("|")) : "");
            self.httpService.post(url, data, {headers}).toPromise().then((res: any) => {
                if(res && res.success && res.data){
                    res.data.metadata = JSON.parse(res.data.metadata);
                    res.data.metadata?.images.forEach((x:any, i)=>  {
                        if(x.metadata.style == "collage" && x.metadata.collage) {
                            x.metadata.collage.metadata.images.forEach((cx:any, j:number)=> {
                                cx.src = srcMap.get(i+"_"+j)
                            })
                        } 
                        else x.src = srcMap.get(i)
                    });
                }
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    updateBooks(data: any, cover = false): Promise<any> {
        let self = this;
        data["metadata"] = typeof data["metadata"] == "string" ? JSON.parse(data["metadata"]) : data["metadata"];
        const srcMap = new Map();
        delete data["metadata"]?.cover.src;
        data["metadata"]?.images.forEach((x:any, i)=> { 
            if(x.metadata.style == "collage" && x.metadata.collage) {
                x.metadata.collage.metadata.images.forEach((cx:any, j:number)=> {
                    srcMap.set(i+"_"+j,cx.src)
                    delete cx.src
                })
            } 
            else srcMap.set(i,x.src);
            delete x.src
        });
        data["metadata"] = JSON.stringify(data["metadata"]);
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "book/update" + (cover ? "?cover="+cover : '') ;
            self.httpService.post(url, data).toPromise().then((res: any) => {
                if(res && res.success && res.data){
                    res.data.metadata = JSON.parse(res.data.metadata);
                    res.data.metadata?.images.forEach((x:any, i)=>  {
                        if(x.metadata.style == "collage" && x.metadata.collage) {
                            x.metadata.collage.metadata.images.forEach((cx:any, j:number)=> {
                                cx.src = srcMap.get(i+"_"+j)
                            })
                        } 
                        else x.src = srcMap.get(i)
                    });
                    res.data.metadata.cover.src = srcMap.get(0);                    
                    res.data.metadata = JSON.stringify(res.data.metadata);
                }
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    uploadComplete( bookId: number): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/complete?bookId="+bookId;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    uploadPartial( bookId: number, files: Array<number>): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "upload/partial?bookId="+bookId+"&files="+files;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    getBookData(bookId: any): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "book/get?bookId=" + bookId;
            self.httpService.get(url).toPromise().then((res: any) => {
                
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    getBookPrice(bookId:number): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "book/price/?bookId="+bookId;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    deleteBookData(bookId: any): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "book/delete?bookId=" + bookId;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    createOrder(bookId: any, sku, eId): Promise<any> {
        let self = this;
        let headers = new HttpHeaders();
        if(eId)
            headers = this.getHeaders(eId, null);
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/create/" + bookId;
            self.httpService.get(url, {headers}).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    updateOrder(bookId: any, partial): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/update/" + bookId+"?partial="+partial;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    updateOrderBookSize(bookId: any, size:string): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/size/" + bookId + "?size="+ size;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    getOrder(bookId: any): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/" + bookId;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    createPayment(bookId: any, data:any = null, eId = null): Promise<any> {
        let self = this;
        let headers = new HttpHeaders();
        if(eId)
            headers = this.getHeaders(eId, data);
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/payment/" + bookId;
            self.httpService.get(url, {headers}).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    completeCodPayment(bookId: any, data, eId): Promise<any> {
        let self = this;
        let headers = new HttpHeaders();
        if(eId)
            headers = this.getHeaders(eId, data);
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/complete/"+bookId;
            self.httpService.post(url,{}, {headers}).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    completePayment(oId:string, pId:string, sign:string): Promise<any> {
        let self = this;
        const form = new FormData();
        form.append("razorpay_order_id", oId);
        form.append("razorpay_payment_id", pId);
        form.append("razorpay_signature", sign);
        return new Promise<any>((resolve, reject) => {
            self.httpService.post(this.getPaymentUrl(), form).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    retryPayment(bookId: any): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/retry/" + bookId;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    editPayment(bookId: any): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/retry/edit/" + bookId;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    setOrderAddress(bookId: any, data:any, eId): Promise<any> {
        let self = this;
        let headers = new HttpHeaders();
        if(eId)
            headers = this.getHeaders(eId, data);
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/address/" + bookId;
            self.httpService.post(url, data, {headers}).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    getDefaultAdd(addId:number): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "order/address/"+ (addId ? "?id="+ addId : "");
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    getAllBooks(status: string, page: number, pageSize: number, signal:any): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "book/getAll/?status=" + status + "&page="+page+"&size="+pageSize;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    getAllCoupons(): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "coupon";
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    applyCoupons(coupon:string, orderId:number): Promise<any> {
        let self = this;
        return new Promise<any>((resolve, reject) => {
            let url = this.baseUrl + "coupon/apply" + ("?coupon="+coupon+"&orderId="+orderId) ;
            self.httpService.get(url).toPromise().then((res: any) => {
                resolve(res);
            }), (err: any) => {
                reject(err);
            }
        });
    }

    private getHeaders(eventId, data) {
        let headers = new HttpHeaders();
        const sku = this.data.getSku()||'whitebook';
        const email = this.data.getUserData()?.email||this.data.getEmail();
        try {
            let names = data?.fullName?.split(" ");
            headers = headers.set("type", sku);
            headers = headers.set("pixel", this.data.pixel ? 'enable':'disable');
            headers = headers.set("eventId", eventId);
            headers = headers.set("fbp", this.getCookie('_fbp')||"");
            headers = headers.set("fbc", this.getCookie('_fbc')||"");

            if(email)
                headers = headers.set("Email", email);
            if(data) {
                headers = headers.set("City", data?.city);
                headers = headers.set("Region", data?.state);
                headers = headers.set("Fn", names?.[0]||"");
                headers = headers.set("Ln", names?.[1]||"");
            }
        } catch(e){

        }
        return headers;
    }

    private getCookie(name: string): string | null {
        const cookieName = encodeURIComponent(name) + '=';
        const cookieArray = document.cookie.split(';');
        for (let cookie of cookieArray) {
          cookie = cookie.trim();
          if (cookie.indexOf(cookieName) === 0) {
            return decodeURIComponent(cookie.substring(cookieName.length, cookie.length));
          }
        }
        return null;
    }
    

}