import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { DataService } from '../data-service.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private appUrl = environment.API_URL;

  constructor(private router: Router, private data:DataService) {}

  private getToken() {
    return this.data.getActiveToken();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers:any = {}
    if (!request.url.includes("login") && !request.url.includes(".amazonaws.com")) {
      const token = this.getToken();
      if(token)
        headers['Authorization']= `Bearer ${token}`;
      headers['Access-Control-Allow-Origin']= '*';
      headers['X-Requested-With']= 'XMLHttpRequest';
    }
    request = request.clone({
        setHeaders: headers
    });
    

    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
        console.log("error");
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401 || err.status == 403) {
          this.router.navigate(["login"]);
        }
        return;
      }
    }));
  }
}