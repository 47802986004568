import { Injectable } from '@angular/core';
import { ImageProcessingService } from './imageprocess.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandlerService {

    progress: { [id: number]: { processed: number, total: number, result: Object, pageId:number, imageId: number } } = {};
    preview: { [id: number]: {pageId:number, result:any} } = {};
    failed: { [id: number]: { pageId:number, imageId: number, error:any } } = {};
    errorMessages: { [id: number]: string } = {};
    progressSubscription!: Subscription;
    previewSubscription!: Subscription;
    completionSubscription!: Subscription;
    errorSubscription!: Subscription;
    processed = 0;
    total = 0;
    processing = false;

    constructor(private process : ImageProcessingService) {

    }

    getSubscribe() {
        return this.process.progress$;
    }

    getPreviews() {
        return this.process.preview$;
    }

    getErrorSubscribe() {
        return this.process.error$;
    }


    queueImages(files:File[], bookData, pageId = 1, imageId = 0, isNew = true, isSave = true, isRetry = false):boolean {
        if(!this.process.isAlreadyQueued()) {
            this.progress = {};
            this.preview = {};
            this.errorMessages = {};
            this.processed = 0;
            this.total = files.length;
            this.processing = true;
            const date = new Date().getTime();
            this.progressSubscription = this.process.progress$.subscribe(progress => {
                this.processed = progress.processed;
                this.progress[progress.pageId] = { pageId: progress.pageId, imageId: progress.imageId, processed: progress.processed, total: progress.total, result: progress.result };
                // console.log(`Processed ${progress.processed} of ${progress.total} images (ID: ${progress.pageId} (result:`,progress.result);
            });
            this.previewSubscription = this.process.preview$.subscribe(progress => {
                this.preview[progress.pageId] = { pageId: progress.pageId, result: progress.result };
            });
        
            this.errorSubscription = this.process.error$.subscribe(({ pageId, imageId, error }) => {
                this.failed[pageId] = { pageId, imageId, error };
                this.errorMessages[pageId] = `Error processing image ID ${pageId}: ${error.message}`;
            });

            this.completionSubscription = this.process.completion$.subscribe(() => {
                console.log('All images processed in', new Date().getTime() - date);
                this.processComplete();
            });
            this.process.processFiles(files, bookData, pageId, imageId, isNew, isSave, isRetry);
            return true;
        } else {
            console.warn("Already queued");
        }
        return false;
    }

    processComplete() {
        this.processing = false;
        this.preview = {};
        this.progressSubscription.unsubscribe();
        this.previewSubscription.unsubscribe();
        this.completionSubscription.unsubscribe();
        this.errorSubscription.unsubscribe();
    }

    ngOnDestroy() {
        this.processComplete();
    }


}