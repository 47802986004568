import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent {

  
  selectedOption: string | null = null;

  @Input()
  options: any[] = [];

  @Input()
  isDropdownOpen: boolean = false;

  @Output()
  output = new EventEmitter();

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectOption(option: any) {
    this.selectedOption = option.id;
    this.output.emit(option.id);
    this.isDropdownOpen = false;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.custom-select')) {
      this.isDropdownOpen = false;
    }
  }

  ngOnDestroy() {
    // Clean up event listener
    document.removeEventListener('click', this.clickOutside.bind(this));
  }

}
