import { Component, ViewChild } from '@angular/core';
import { APIService } from '../service/ApiService';
import { DataService } from '../data-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GtmService } from '../gtm.service';
import { NgOtpInputComponent } from 'ng-otp-input';
import { environment } from 'src/environments/environment';
import { CustomUtils } from '../service/customUtils';
import { debounce } from 'lodash';
import { UploaderService } from '../uploader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  // @ViewChild(NgOtpInputComponent, { static: false}) 
  // ngOtpInput:NgOtpInputComponent;
  @ViewChild("phoneInput") phoneInput;

  userData  :any ={email:"", phone:"", password:""};

  countryCode = 91;
  isValidEmail = false;
  isValidPhone = false;
  verifyPage = false;
  phoneVerified = false;
  seconds = 0;
  minutes = 0;
  isResend = false;
  interval:any;
  renderImage;
  bookData:any;
  isBook = false;
  imgPreviews:any = [];
  otp = "0";
  focusFn:any;
  timer = 0;

  submitOtps = debounce(this.submitOtp, 500, {
    'leading': false,
    'trailing': true
  })
  // api: APIService

  @ViewChild(NgOtpInputComponent) otpInput: NgOtpInputComponent | undefined;

  constructor(private data: DataService, private api: APIService, 
    private toaster: ToastrService, private router: Router, private gtm: GtmService,
    private utils: CustomUtils, private uploader: UploaderService){ 
      // this.ngOtpInput = ngOtpInput;
  }

  ngOnInit() {
    if(this.data.getFiles()?.length) {
      this.isBook = true
      this.getCover();
    }
  }

  ngAfterViewInit() {
    if(!this.verifyPage) {
      this.focusFn = setTimeout(()=> {
        this.phoneInput?.nativeElement?.focus();
        this.phoneInput?.nativeElement?.scroll()
      },3000)
    }
  }
  
  login() {
    clearInterval(this.interval);
    if(this.isValidPhone && this.userData.phone && this.userData.phone.length == 10) {
      this.gtm.event('click_login', {});
      this.data.setLoader(true);
      this.api.login(this.countryCode+this.userData.phone).then(res=> {
        this.data.setLoader(false);
        this.otpTimer(this.timer+=30);
        if(res && res.data){
          this.verifyPage = true;
          // this.setAutoSubmit()
        } else {
          this.toaster.error("Something went wrong!")
        }
      })
    } else {
      this.toaster.warning("Please enter valid phone number!")
    }
    
  }

  extractDigits(phoneNumber) {
    const digitMatches = phoneNumber.match(/\d/g);
    if (digitMatches) {
      return digitMatches.join('');
    } else {
      return '';
    }
  }

  setPhone(val) {
    val = this.extractDigits(val);
    this.userData.phone = val.slice(-10);
    if(val && val.length > 9)
      setTimeout(()=> this.login());
  }

  public numbersOnlyValidator(event: any) {
    event.target.value = this.extractDigits(event.target.value);
    if(event.target.value.length > 10) {
      event.target.value = event.target.value.slice(-10)
      this.userData.phone = event.target.value;
    }
    this.isValidPhone = event.target.validity.valid && event.target.value.length == 10;
  }

  public emailOnlyValidator(event:any) {
    this.isValidEmail = event.target?.validity?.valid;
  }

  onOtpChange(otpValue:any){
    if (Object.values(otpValue).every(x=> x || x === 0)) {
      this.otp = Object.values(otpValue).join("");
      if(this.otp && this.otp.toString().length == 4) {
        this.submitOtps();
      }
    }
  }

  changedata() {
    clearInterval(this.interval);
    this.gtm.event('click_change', {});
    this.verifyPage = false;
  }

  submitOtp() {
    if(!this.otp || this.otp.toString().length != 4) {
      this.toaster.info("Enter OTP first!");
      return;
    }
    this.gtm.event('click_submit', {});
    this.data.setLoader(true);
    const phone = this.countryCode+this.userData.phone
    this.api.verifyOtp(phone, this.otp).then(async res=> {
      if(res && res.data && res.data.active){
        await this.gtm.sendMoe("add_unique_user_id", res.data.id);
        this.gtm.sendMoe("add_mobile", "+"+res.data.phone);
        this.data.setLoader(false);
        this.data.setToken(res.data.token);
        this.data.setAccData({email:res.data.email, phone:res.data.phone, id : res.data.id})
        if(res.data.token && res.data.email) {
          this.gtm.sendMoe("add_email", res.data.email);
          this.redirect();
        }
        else if(res && res.data && res.data.token && !res.data.emailVerified) {
          this.router.navigate(['signup']);
        }
        this.sendLogin(true);
      } 
      else {
        this.toaster.error(res.message ? res.message : "Something went wrong!")
        this.data.setLoader(false);
        if(this.otpInput) {
          try {
            this.otpInput.setValue('')
            this.otpInput.focusTo('otp_0_'+this.otpInput.componentKey);
          } catch(e){}
        }
        this.sendLogin(false);
      }
    })
  }

  sendLogin(value) {
    if(this.data.inFrame)
      window.parent.postMessage({ type : value ? 'loggedIn' : 'loggedOut' }, environment.HOME_URL);
  }

  redirect() {
    // const prevUrl = this.previousRouteService.getPreviousUrl();
    // const guardUrl = this.data.guardUrl;
    if(this.uploader.uploadPending) {
      this.router.navigate(["upload"]);
    }
    else this.router.navigate(["orders"]);
  }

  otpTimer(seconds) {
    if(seconds > 60 && seconds % 60 > 0) {
      this.minutes = seconds/60;
      this.seconds = seconds%60;
    } else this.seconds = seconds;
    this.isResend = false;
    this.interval = setInterval(() => {
        if (this.seconds > 0) {
          --this.seconds;
        }
        if (this.seconds === 0) {
          if (this.minutes === 0) {
            this.isResend = true;
            clearInterval(this.interval);
          } else {
            this.seconds = 59;
            --this.minutes;
          }
        }
      }, 1000);
  }

  async getCover() {
    this.bookData = this.utils.defaultBookData();
    const files = this.data.getFiles()?.slice(0,4);
    if(files.length < 3) {
      files.push(...files)
    }
    let self = this;
    (async function () {
      for (let [i, file] of files.entries()) {
        await self.utils.getUploadedImageMetadata(file, false, i, 0, null, i==0,i == 0 ? self.bookData.metadata.cover : {}, true).then(async (imgMetaResp:any)=> {
          self.imgPreviews.push(imgMetaResp.src);
        });
      }
    })();
    
  }

  ngOnDestroy() {
    clearTimeout(this.focusFn);
    clearInterval(this.interval);
  }

}
