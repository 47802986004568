import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from '../service/ApiService';
import { DataService } from '../data-service.service';
import { ToastrService } from 'ngx-toastr';
import { GtmService } from '../gtm.service';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent {

  service: APIService;
  dataService: DataService;

  address:any = {}

  userData:any = {};

  terms = true;
  isValidPin = false;

  constructor(service:APIService, dataService: DataService, private route: ActivatedRoute, 
    private router: Router, private toaster: ToastrService, private gtm: GtmService, public modalService: ModalService) {
    this.service = service;
    this.dataService = dataService;
  }

  ngOnInit() {
    if(!this.dataService.bookId)
      this.router.navigate(["orders"]);
    else if(!this.dataService.getBookData())
      this.fetchBookData(this.dataService.bookId)
    else
      this.setAddressData();
  }

  ngAfterViewInit() {
    setTimeout(()=> this.dataService.checkoutStep = 1);
  }

  fetchBookData(id:number) {
    this.toaster.info("Fetching Your Whitebook.");
    this.service.getBookData(id).then((res:any)=> {
      if(res.data) {
        this.dataService.setBookData(res.data);
        this.setAddressData();
      }
    });
  }

  setAddressData() {
    this.userData = this.dataService.getAccData();
    this.address = Object.assign(this.dataService.address, this.userData);
    this.address.phone = this.address.phone && this.address.phone.length > 10 ? 
      this.address.phone.slice(2) : this.address.phone;
    this.getDefaultAdd();
  }

  getDefaultAdd() {
    this.service.getDefaultAdd(this.dataService.orderData.deliveryAddressId).then((res)=> {
      if(res && res.success) {
        this.address = Object.assign(this.address, res.data);
        this.verifyPincodeInput(this.address.pincode)
      }
    })
  }

  async next() {
    const {msg, valid} = await this.validateAddress();
    if(valid) {
      this.gtm.event('click_next', {});
      const bookData = this.dataService.getBookData();
      const eId = this.gtm.setEventId("AddPaymentInfo")
      this.gtm.trackEvent("AddPaymentInfo", {
        id: bookData.id, 
        external_id: bookData.accountId
      });
      this.service.setOrderAddress(bookData.id, this.address, eId).then((res:any)=> {
        if(res.data) {
          this.dataService.orderData = res.data;
          this.dataService.address = this.address;
          this.router.navigate(["checkout", this.dataService.bookId, "billing"]);
        }
      })
    } else {
      this.toaster.warning(msg ? msg : "Invalid/Empty Address field !")
    }
    
  }

  async verifyPincodeInput(value) {
    this.address.pincode = value;
    this.isValidPin = await this.verifyPincode(value);
    // if(!this.isValidPin)
    //   this.toaster.error("Invalid Pincode!")
  }

  verifyPincode(pincode:number, change = true):Promise<boolean> {
    return new Promise(resolve=> {
      if(!pincode || isNaN(pincode) || pincode.toString().length != 6) {
        resolve(false);
        return;
      }
      this.service.verifyPincode(pincode).then(res=> {
        if(res?.length && res[0]["Status"] == "Success") {
          if(change && res[0]?.["PostOffice"]?.[0]) {
            this.address.city = res[0]["PostOffice"][0]["District"]
            this.address.state = res[0]["PostOffice"][0]["State"]
          }
          resolve(true)
        } else if(!res || !res.length)
          resolve(true)
        else resolve(false);
      },err=> resolve(true))
    })
  }

  extractDigits(phoneNumber) {
    const digitMatches = phoneNumber.match(/\d/g);
    if (digitMatches) {
      return digitMatches.join('');
    } else {
      return '';
    }
  }

  isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  numbersOnlyValidator() {
    let phone = this.extractDigits(this.address.phone);
    if(phone.length > 10) {
      phone = phone.slice(-10)
      this.userData.phone = phone;
    }
    return phone.length == 10;
  }

  async validateAddress():Promise<{valid:boolean, msg:string}> {
    let valid = true;
    let msg = '';
    if(!this.address.email || !this.address.phone || !this.address.fullName || !this.address.pincode || !this.address.city 
      || !this.address.address || !this.address.state ) {
        valid = false;
    }else if (isNaN(+this.address.pincode) || !this.isValidPin || !(await this.verifyPincode(this.address.pincode, false))){
      msg = "Please enter valid pincode!"
      valid = false;
    } else if(!this.numbersOnlyValidator()) {
      msg = "Please enter valid phone number!"
      valid = false;
    } else if(!this.isValidEmail(this.address.email)) {
      msg = "Please enter valid email address!"
      valid = false;
    }
    this.dataService.setAddress(this.address);
    this.dataService.setUserData({ name : this.address.fullName, email: this.address.email, contact: this.address.phone });
    return {valid, msg};
  }

  togglePrivacy() {
    this.modalService.open("privacy");
  }

}
