<ng-container *ngIf="inFlipBook">
    <ng-container *ngIf="!inPreview">
        <div class="progress" *ngIf="progress > 0 && progress < 100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{'width': progress+'%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="col px-4 text-center" *ngIf="dataService.getProgress() > 0 && dataService.getProgress() < 100">
            <div class="progress" style="height: 10px;">
                <div class="progress-bar color-primary" role="progressbar" [style]="'width: '+dataService.getProgress()+'%;'" [attr.aria-valuenow]="dataService.getProgress()" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>

        <div class="preGrid mt-2" *ngIf="!inSort">
            <div class="col-4 preview cover-page" [ngStyle]="{'background-color': bookData.color}">
                <ng-container *ngIf="bookData.metadata?.cover?.frame && bookData.metadata?.cover?.frame?.name == 'wb2'; else defaultCover">
                    <div class="cover">
                        <div class="imgBox">
                            <img *ngIf="imgPreviews?.[0] else noImg" (click)="inPreview = !inPreview" class="imgPrev" [src]="imgPreviews[0] | safeUrl" alt="">
                            <div *ngIf="loading" class="overlay">loading...</div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #defaultCover>
                    <div *ngIf="imgPreviews[0]" class="cover-rim" [ngClass]="'e'+bookData.metadata?.cover?.color?.slice(1)"></div>
                    <div class="cover">
                        <div class="imgBox2">
                            <img *ngIf="imgPreviews?.[0] else noImg" (click)="inPreview = !inPreview" class="imgPrev" [src]="imgPreviews[0] | safeUrl" alt="">
                            <div *ngIf="loading" class="overlay">loading...</div>
                        </div>
                    </div>
                </ng-template>
                <ng-template #noImg>
                    <img class="imgPrev" style="object-fit: none;opacity: 0.4" src="assets/images/favicon.png" alt="">
                </ng-template>
            </div>
            <div *ngIf="uploader.uploading && (uploader.uploadedFiles <= uploader.totoalFiles)"
                style="position: sticky; top: 0;z-index: 9;" class=" mt-3" >
                <div class="col px-4">
                    <div class="upload drop-area uploading">
                        <div class="upload-info">
                            <span class="upload-filename">Photos Uploading
                                ({{uploader.uploadedFiles}}/{{uploader.totoalFiles}})
                            </span>
                        </div>
                        <div class="upload-progress"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="getEditable()" class="row p-3">
                <div class="col-6 text-start">
                    <button class="btn btn-sm btn-secondary " [disabled]="!editable" (click)="editCover()">Edit Cover</button>
                </div>
                <div class="col-6 text-end">
                    <button class="btn btn-sm btn-primary " (click)="togglePreview()">Preview</button>
                </div>
            </div>

            <div *ngIf="bookData.status == 'STAGE'" class="coverInput">
                <div class="label">
                    Book Title :
                    <span>
                        <small class="text-muted">
                            ({{bookData?.title?.length||0}}/{{bookData.metadata?.cover?.frame?.titleLen || 40}})
                        </small>
                    </span>
                </div>
                <div class="value">
                    <div class="input-group input-title">
                        <input type="text" class="form-control py-1" name="title" [maxlength]="bookData.metadata?.cover?.frame?.titleLen"
                        [ngModel]="bookData.title" (ngModelChange)="changeTitle($event)" (blur)="onBlur()" 
                         style="font-size: 16px;">
                        <div class="input-color d-flex">
                            <ng-container *ngFor="let c of utils.titleColors">
                                <span *ngIf="bookData.color != c.color" (click)="changeTileColor(c.color)">
                                   <i [ngStyle]="{'background-color': c.color}" 
                                    [ngClass]="{'active':bookData.metadata?.cover?.titleColor == c.color}" ></i>
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="label">
                    Frame :
                </div>
                <div class="value d-flex mt-3 mb-2">
                    <a (click)="changeFrame(0)" type="radio" class="form-control py-1" name="layoutStyle"
                    [ngClass]="{'active':bookData.metadata?.cover?.frame?.name == 'wb1'}" placeholder="Style1">
                        Compact
                    </a>
                    <a (click)="changeFrame(1)" type="radio" class="form-control py-1" name="layoutStyle"
                    [ngClass]="{'active':bookData.metadata?.cover?.frame?.name == 'wb2'}" placeholder="Style2">
                        Classic
                    </a>
                </div>
               
                <ng-container *ngIf="bookData.metadata?.cover?.frame?.name == 'wb2'">

                    <div class="label">
                        Layout :
                    </div>
                    <div class="value d-flex mt-3 mb-2">
                        <a (click)="changeLayout(1)" type="radio" class="form-control py-1" name="layoutStyle"
                        [ngClass]="{'active':bookData.metadata?.cover?.frame?.style == 'fit'}" placeholder="fit">
                            Fit
                        </a>
                        <a (click)="changeLayout(0)" type="radio" class="form-control py-1" name="layoutStyle"
                        [ngClass]="{'active':bookData.metadata?.cover?.frame?.style == 'full'}" placeholder="full">
                            Smart
                        </a>
                    </div>

                    <div class="label">
                        Font :
                    </div>
                    <div class="value d-flex mt-3 mb-2">
                        <a (click)="changeFont('Ronda')" type="radio" class="form-control py-1" name="titleStyle"
                        [ngClass]="{'active':bookData.metadata?.cover?.titleStyle == 'Ronda'}" placeholder="Style1">
                            Handwritten
                        </a>
                        <a (click)="changeFont('Carena')" type="radio" class="form-control py-1" name="titleStyle"
                        [ngClass]="{'active':bookData.metadata?.cover?.titleStyle == 'Carena'}" placeholder="Style2">
                            Vintage
                        </a>
                    </div>
                    <div class="label">
                        <label class="form-check-label" for="date">
                            {{type == 'birthday' ? 'Birth Date' : 'Date' }}:
                          </label>
                    </div>
                    <div class="value" style="display: flex;justify-content: space-around;">
                        <div class="checkbox" style="font-size: 16px;display: flex;align-items: center;">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" name="date" id="date" [ngModel]="bookData?.metadata?.cover.isDob" (ngModelChange)="changeIsDob()" >
                            </div>
                        </div>
                        <app-date-picker *ngIf="bookData.metadata?.cover" [disabled]="!bookData.metadata.cover.isDob" (selected)="changeDob($event)" [date]="bookData.metadata.cover.dob" ></app-date-picker>
                    </div>
                </ng-container>
                <div class="label">
                    Color :
                </div>
                <div class="value">
                    <div  class="row text-center" style="transform: translateX(-5px); width: 95%;">
                        <ng-container *ngFor="let data of dataService.colorData">
                            <div class="col mx-1 p-0" (click)="changeColor(data.id)">
                                <div class="circle" [ngClass]="bookData?.color == data.bookColor ? 'colorA' : 'colorNa'"
                                [ngStyle]="{'background-color' : data.bookColor}"></div>
                                <small [ngClass]="bookData?.color == data.bookColor ? 'txt-blue' : 'text-muted'" style="font-size: 7px;">{{data.name}}</small>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div *ngIf="!getEditable()" class="row mx-auto mt-5" style="justify-content: center;position: relative;z-index: 999;">
               
                <div class="col-12">
                    <button class="btn btn-sm btn-primary" (click)="togglePreview()">
                        Preview
                    </button>
                </div>
            </div>
        </div>
        <app-edit *ngIf="getEditable()" [parent]="this" [bookData]="bookData" [imgPreviews]="imgPreviews" [inSort]="inSort" ></app-edit>
        <div class="row orderNow mx-auto">
            <button *ngIf="!inSort && getOrderActive()" class="btn btn-sm btn-primary main" style="height: 36px;" (click)="!handler.processing && editable && orderNow()">
                <ng-container *ngIf="!handler.processing">Order Now</ng-container>
                <ng-container *ngIf="handler.processing">
                  <div class="progress position-absolute w-100" style="height:100%;top:0;left:0;">
                    <div
                      class="progress-bar progress-bar-striped progress-bar-animated"
                      [style.width.%]="dataService.getProgress()"
                      role="progressbar"
                      [attr.aria-valuenow]="dataService.getProgress()"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ dataService.getProgress() }}%
                    </div>
                  </div>
                </ng-container>
            </button>
            <!-- <button *ngIf="!inSort && getOrderActive() && !handler.processing && editable" class="btn btn-sm btn-primary main" (click)="openMagnets()">Magnet</button> -->
            <button *ngIf="inSort" class="btn btn-sm btn-primary main" (click)="inSort = false">Done</button>
        </div>
    </ng-container>
    <div class="w-100 p-3" *ngIf="inPreview">
    <app-preview  [autoFlip]="true" [parent]="this" [_imgPreviews]="imgPreviews" [bookLogoColor]="bookData.metadata?.cover?.logoColor"
    [bookColor]="bookData.metadata?.cover?.color" (exitEvent)="exitPreview()"></app-preview>
</div>
</ng-container>

<router-outlet (activate)="inFlipBook = false" (deactivate)="inFlipBook = true"  ></router-outlet>