<div class="d-flex pt-3 px-3 justify-content-between">
    Apply Coupons <i class="fa fas fa-times text-black" (click)="close()"></i>
</div> 
<div class="p-4 pt-2">
    <div class="p-2">
        <div class="input-group input-title">
            <input type="text" class="form-control py-1" name="title" [maxlength]="16"
            [ngModel]="customCode" (ngModelChange)="customCode = $event.trim().toUpperCase()"
             style="font-size: 16px;">
            <div class="input-color d-flex">
                <button class="apply-btn" [ngClass]="customCode ? 'color-btn' : ''" (click)="apply(customCode.trim())" >Apply</button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="coupons && coupons.length">
        <div class="applicable pt-2">Applicable Coupons</div>
        <div class="">
            <ng-container *ngFor="let coupon of coupons">
                <div class="card coupon-card p-2">
                    <div class="d-flex align-v-center justify-content-between" style="opacity: 1;">
                        <div class="d-flex align-v-center">
                            <div class="Coupon_sub__heading__text__6qq6O text__uppercase">
                                <img src="/assets/images/offer.svg" style="width: 20px; margin-right: 16px;" alt="remove-icon1">
                                <span class="ml-5 text-uppercase coupon-name">{{coupon[0]}}</span>
                            </div>
                        </div>
                        <button class="apply-btn color-btn btn btn-sm btn-primary" (click)="apply(coupon[0])">Apply</button>
                    </div>
                    <!-- <div class="Coupon_save__text__87cF3">Apply coupon and save ₹786.60</div> -->
                    <div class="d-flex justify-content-between">
                        <div class="coupon-desc">{{coupon[1]}}</div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- <div class="input-group">
            <select class="form-select w-100" id="yearselect" style="font-size: 14px;justify-content: space-around;" (change)="changeCoupon($event)">
                <option [value]="null" >--
                </option>
                <option *ngFor="let cp of coupons" [value]="cp[0]"
                    >
                    {{cp[0]}}
                </option>
            </select>
        </div> -->
    </ng-container>

    <ng-container *ngIf="!coupons  || !coupons.length">
    </ng-container>

</div>