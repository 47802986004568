import { Injectable } from '@angular/core';
// import { Http, Response, Headers, RequestOptionsArgs, ResponseContentType } from '@angular/http';
import { Observer, Observable, lastValueFrom, map, catchError, of } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
// import { CustomHTTP } from './cutomHTTP';

@Injectable()
export class HttpClientService {
  public httpClient: any;
  
  constructor(private http: HttpClient) {
    this.httpClient = http;
  }

  get(url: string, options?: any): Observable<any> {
    return this.http.get(url, options).pipe(catchError((error: any, caught: Observable<any>): Observable<any> => {
      console.error('There was an error!', error);
      return of({});
    }));
  }
  
  getHeaders() {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type','application/json')
    headers.append('Access-Control-Allow-Origin', '*')
    return { "headers": headers };
  }
  
  post(url: string, data: any, options?: any): Observable<any> {
    return this.http.post(url, data, options).pipe(catchError((error: any, caught: Observable<any>): Observable<any> => {
      console.error('There was an error!', error);
      return of({});
    }));
  }

  put(url: string, data: any, options?: any): Observable<any> {
    return this.http.put(url, data, options).pipe(catchError((error: any, caught: Observable<any>): Observable<any> => {
      console.error('There was an error!', error);
      return of({});
    }));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(url, options).pipe(catchError((error: any, caught: Observable<any>): Observable<any> => {
      console.error('There was an error!', error);
      return of({});
    }));
  }

  getBlob(url: string, options?: any): Observable<any> {
    options = {};
    options.responseType = "blob";
    options.observe = 'response';
    return this.http.get<HttpResponse<Blob>>(url, options);
  }

  filePost(url: string, data: any, options?: any): Observable<any> {
    options = {};
    options = { headers: new HttpHeaders() };
    options.headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(url, data, options);
  }

  postBlob(url: string,data:any, options?: any): Observable<any> {
    options = {};
    options.responseType = "blob";
    options.observe = 'response';
    return this.http.post<HttpResponse<Blob>>(url,data, options);
  }

 
}
