<!-- <div style="display: flex; justify-content: space-around; margin: 5px 5px 10px 5px">
    <div data-value="1" class="step" (click)="setRouter('address')">
        <span class="step-num" [ngClass]="dataService.checkoutStep >= 1 ? 'active' : ''">1</span>
        <span class="text-muted">Address</span>
    </div>
    <div data-value="2" class="step" (click)="setRouter('billing')">
        <span class="step-num" [ngClass]="dataService.checkoutStep >= 2 ? 'active' : ''">2</span>
        <span class="text-muted">Billing</span>
    </div>
    <div data-value="3" class="step">
        <span class="step-num" [ngClass]="dataService.checkoutStep >= 3 ? 'active' : ''">3</span>
        <span class="text-muted">Payment</span>
    </div>
</div>
 -->
<div class="progress-checkout">
    <div [ngClass]="{'done' : dataService.checkoutStep > 1 , 'active' : dataService.checkoutStep == 1 , 'circle' : true}"> <span class="label">1</span>
      <span class="title">Address</span>
    </div> 
    <span [ngClass]="{'done' : dataService.checkoutStep > 1 , 'active' : dataService.checkoutStep == 1 , 'bar' : true}"></span>
    <div [ngClass]="{'done' : dataService.checkoutStep > 2 , 'active' : dataService.checkoutStep == 2 , 'circle' : true}"> <span class="label">2</span>
      <span class="title">Billing</span>
    </div> 
    <span [ngClass]="{'done' : dataService.checkoutStep > 2 , 'active' : dataService.checkoutStep == 2 , 'bar' : true}"></span>
    <div [ngClass]="{'done' : dataService.checkoutStep > 3 , 'active' : dataService.checkoutStep == 3 , 'circle' : true}"> <span class="label">3</span>
      <span class="title">Payment</span>
  
    </div>
</div>
<hr class="m-0">
<router-outlet></router-outlet>