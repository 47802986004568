import { Component, NgZone, TemplateRef } from '@angular/core';
import { APIService } from '../service/ApiService';
import { DataService } from '../data-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GtmService } from '../gtm.service';
import { Helper } from '../service/helper';
import { CustomUtils } from '../service/customUtils';
import { cloneDeep } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalService } from '../modal.service';
import { PreviewService } from '../preview.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CouponsComponent } from '../coupons/coupons.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent {

  service:APIService;
  dataService:DataService;

  orderData:any = {};
  bookImage:any;
  magnetImage:any;
  bookData:any;
  discounts = 0;
  priceData:any;
  address:any;
  coupons:Array<any> = [];
  couponsMap = new Map();
  coupon:Array<string> = [];
  isCod = false;
  isPartial = false;
  applying = false;
  isAnime = false;
  inCoupon = false;
  loading = true;
  applyingMsg = "Applying coupon";

  bookColor;

  modalRef;
  private modalSubscription!: Subscription;

  constructor(service:APIService, dataService: DataService, private router: Router, 
    private preview: PreviewService, private zone: NgZone,
    private toaster: ToastrService, private gtm: GtmService, public utils: CustomUtils,
    private customModal:NgbModal, private modalService: ModalService) {
    this.service = service;
    this.dataService = dataService;
  }

  ngOnInit() {
    // this.orderData = this.dataService.orderData;
    const bookId = this.dataService.getBookData()?.id;
    if(!bookId){
      this.router.navigate(['orders']);
    } else {
      this.bookData = this.dataService.getBookData();
      this.bookImage = this.dataService.getCover();
      this.magnetImage = this.dataService.getMagnet();
      if(!this.bookImage)
        this.router.navigate(["flipbook", this.dataService.bookId]);
      else {
        // const eId = this.gtm.setEventId("AddPaymentInfo")
        // this.gtm.trackEvent("AddPaymentInfo", {
        //   id: this.bookData.id, 
        //   account_id: this.bookData.accountId
        // });
        this.service.getBookPrice(bookId).then(res=> {
          if(res?.data) {
            this.priceData = res.data;
            this.fetchOrderDetails(bookId);
          }
        })
        this.setAddress();
      }
    }
  }
  
  ngAfterViewInit() {
    setTimeout(()=> this.dataService.checkoutStep = 2);
  }

  uploadCover(coverId) {
    let self = this;
    fetch(this.bookImage)
    .then(function(response) {
      return response.blob()
    })
    .then(async function(blob) {
      const previewId = await self.service.uploadCover(blob, coverId);
      if(!coverId) {
        const bookData = self.dataService.getBookData();
        bookData.metadata = typeof bookData.metadata == "string" ? JSON.parse(bookData.metadata) : bookData.metadata
        bookData.metadata.cover.previewId = previewId;
        self.dataService.setBookData(bookData);
      }
    });
  }

  fetchBookData(id:number) {
    this.toaster.info("Fetching Your Whitebook");
    this.service.getBookData(id).then((res:any)=> {
      if(res.data) {
        this.dataService.setBookData(res.data);
        this.setAddress();
      }
    });
  }

  setAddress() {
    const address = this.dataService.getAddress();
    if(address) {
      const joiner:Array<string> = [];
      joiner.push(address.fullName);
      joiner.push(address.address);
      joiner.push(address.state);
      joiner.push(address.city);
      joiner.push(address.pincode);
      // joiner.push(address.phone);

      this.address = joiner.join(", ")+"\n"+address.phone
    }
  }

  fetchOrderDetails(bookId:number) {
    this.service.getOrder(bookId).then((res)=> {
      if(res && res.success && res.data.status == "PENDING") {
        this.orderData = res.data;
        this.dataService.orderData = res.data;
        this.isPartial = this.orderData.partialAmount > 0;
        this.loading = false;
        this.fetchCoupons(true)
        this.fetchColor();
      } else if(res && res.success && res.data.status == "CONFIRMED"){
        this.toaster.warning("We have already recieved your order!")
        this.router.navigate(['orders']);
      } else if(!res.success){
        this.service.createOrder(bookId, "", null).then((res:any)=> {
          if(res.success && res.data.id) {
            this.dataService.orderData = res.data;
            this.orderData = res.data;
            this.loading = false;
            this.fetchCoupons(true)
            this.fetchColor();
            // this.router.navigate(['address'], {relativeTo:this.route});
          }
        })
      }
    })
  }

  fetchCoupons(apply = false) {
    this.service.getAllCoupons().then(res=> {
      if(res && res.success && res.data?.length) {
        this.coupons = res.data;
        res.data.map(x=> this.couponsMap.set(x[0], x[1]));
        // this.setCoupon(apply);
        // this.applyCoupons(res.data[0])
      }
    })
  }

  setCoupon(apply = false) {
    if(this.orderData && this.orderData.appliedCoupon) {
      this.coupon = this.coupons.find(x=> x[0] == this.orderData.appliedCoupon);
    }else if(this.coupons?.length && apply) {
      this.applyCoupons(this.coupons[0][0])
      this.coupon = [];
    } else {
      this.coupon = [];
    }
  }

  fetchColor() {
    if(!this.bookColor) {
      const bookData = this.dataService.getBookData();
      if(bookData && bookData.color) {
        this.bookColor = this.utils.colorData.find(x=> x.bookColor.toLocaleLowerCase() == bookData.color.toLocaleLowerCase())?.name;
      }
    }
    return this.bookColor;
  }
  
  changeCoupon(event:any) {
    this.gtm.event('click_cpn'+event.target.value, {});
    this.applyCoupons(event.target.value, event);
  }

  async selectPayment(event:any) {
    // this.isCod = event.target.value === 'true';
    // this.orderData.total = 0
    // this.isPartial = event.target.value === 'part';
    this.loading = true;
    this.service.updateOrder(this.bookData.id, event.target.value === 'part').then(res=> {
      if(res && res.success && res.data.status == "PENDING") {
        this.orderData = res.data;
        this.dataService.orderData = res.data;
        this.isPartial = this.orderData.partialAmount > 0;
        this.loading = false;
      }
    });
  }

  applyCoupons(coupon, event:any = null) {
    this.applying = true;
    this.applyingMsg = coupon ? "Applying coupon" : "Removing coupon"
    this.service.applyCoupons(coupon, this.orderData.id).then((res)=> {
      if(res && res.success && res.data.status == "PENDING"){
        setTimeout(()=> {
          if(coupon && res.data.appliedCoupon == coupon)
            this.isAnime = true;
          this.dataService.orderData = res.data;
          this.orderData = res.data;
          this.applying = false;
        },1000)
        setTimeout(()=> {
          if(coupon && res.data.appliedCoupon == coupon) {
            this.isAnime = false;
            this.toaster.success("Offer applied!")
          }
          this.setCoupon();
        },2300)
      } else if(res && !res.success && res.data) {
        this.applying = false;
        this.toaster.warning(res.errorMessage);
        this.dataService.orderData = res.data;
        if(event) event.target.value = null;
      }
    }, err=> {
      this.applying = false;
      this.toaster.warning("Invalid Coupon");
    })
  }

  changeColor(event:any) {
    const id = +event.target.value;
    const index = 0;
    this.gtm.event('click_colorChange', {})
    let color = this.dataService.colorData.find(x=> x.id == id);
    typeof this.bookData.metadata == "string" ? this.bookData.metadata = JSON.parse(this.bookData.metadata) : null;
    const bookData = cloneDeep(this.dataService.getBookData());
    if(color) {
      bookData.color = color.bookColor;
      bookData.metadata["cover"]["color"] = color.bookColor;
      bookData.metadata["cover"]["titleColor"] = color.titleColor;
      bookData.metadata["cover"]["logoColor"] = color.logoColor;
    }
    const imgMeta = Object.assign({}, bookData.metadata.images[index], bookData.metadata["cover"]);
    this.preview.genImagePreview(this.dataService.getOriginalImage(), imgMeta).then(url=> {
      this.bookImage = url;
      this.dataService.setCover(url);
      this.updateBooks(bookData);
    });
  }

  selectFlipBook(id:number) {
    this.gtm.event('click_selectFlip', {});
    this.dataService.setBookData({id, metatdata:{}});
    this.router.navigate(["flipbook", id]);
  }

  editAddress() {
    this.router.navigate(["checkout", this.dataService.bookId, "address"]);
  }

  changeSize(size:string) {
    this.service.updateOrderBookSize(this.orderData.bookId, size).then(res=> {
      if(res.success && res.data.id) {
        this.dataService.orderData = res.data;
        this.orderData = res.data;
        this.service.getBookPrice(this.orderData.bookId).then(res=> {
          if(res?.data) {
            this.priceData = res.data;
          }
        })
      }
    })
  }

  getAdditionalPages() {
    if(this.orderData && this.priceData)
      return this.orderData.additionalPrice/this.priceData.pricePerPage;
    return 0;
  }

  makePayment() {
    this.gtm.event('click_payment', {});
    this.gtm.sendConversion('conversion', {'send_to': 'AW-11124049813/l-rSCP6xxpQZEJWPrrgp'});
    const bookData = this.dataService.getBookData();
    bookData.metadata = typeof bookData.metadata == "string" ? JSON.parse(bookData.metadata) : bookData.metadata
    this.uploadCover(bookData.metadata.cover.previewId);
    const eId = this.gtm.setEventId("Purchase");
    const address = this.dataService.getAddress();
    this.service.createPayment(bookData.id, address, eId).then((res:any)=> {
      if(res.data && res.success && res.data.paymentId) {
        this.orderData = res.data;
        this.dataService.orderData = res.data;
        this.isPartial = this.orderData.partialAmount > 0;
        this.router.navigate(["checkout", bookData.id, "payment"]);
      } else if(!res.success) {
        this.toaster.info(res.message || "Unknown Error");
        if(res.message && (res.message.includes("confirmed") || res.message.includes("completed")))
          this.dataService.orderType = res.message.includes("confirmed") ? "transit" : "completed"
        this.router.navigate(["orders"]);
      }
    })
    // }
  }

  openMagnets() {
    this.modalService.openMagenets(this.dataService.getOriginalImage(), this.bookData, this);
  }

  updateBooks(bookData = this.bookData) {
    this.service.updateBooks(cloneDeep(bookData)).then(x=> {
      if(x && x.data){
        this.dataService.setBookData(x.data);
        this.bookData = this.dataService.getBookData();
        this.uploadCover(this.bookData.metadata.cover.previewId);
      } else this.dataService.setLoader(true);
    }, err=> this.dataService.setLoader(true));
  }


  openCouponModal() {
    const modalOptions = {
      class: 'modal-lg modal-fullscreen modal-dialog-centered',
      ignoreBackdropClick: true, // Prevent closing on outside click
    };
    this.modalRef = this.customModal.open(CouponsComponent,
      {
        centered: true, // Center the modal
      }
    );
    this.modalRef.componentInstance.coupons = this.coupons;
    this.modalSubscription = this.modalRef.componentInstance.actionConfirmed.subscribe((data: any) => {
      if(data.type == 'apply')
        this.applyCoupons(data.value);
      this.closeModal();
    });
  }

  closeModal() {
    this.unsubscribeModal();
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  unsubscribeModal() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  toggleRefund() {
    this.modalService.open("refund");
  }

  ngOnDestroy() {
    this.unsubscribeModal()
  }

}
