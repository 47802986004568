import {Injectable} from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ModalComponent } from './modal/modal.component';
import { MagnetComponent } from './magnet/magnet.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  options:NgbModalOptions = {
    centered: true,
    backdrop: 'static',
    keyboard: false
  };

  modalRef:any;

  private offerBookMap:Map<string, number> = new Map();


  constructor(private modalService: NgbModal){
  }
  // open() {
  //   this.display.next('open');
  // }

  open(msg:string, ok = false) {
    return new Promise<any>(res=> {
      this.modalRef = this.modalService.open(ModalComponent, this.options);
      this.modalRef.componentInstance.message = msg;
      this.modalRef.componentInstance.ok = ok;
      this.modalRef.result.then(res,err=> res(false));
    })
	}

  openMagenets(originalUrls:any, bookData:any, parent:any) {
    return new Promise<any>(res=> {
      this.modalRef = this.modalService.open(MagnetComponent, this.options);
      this.modalRef.componentInstance.originalUrls = originalUrls;
      this.modalRef.componentInstance.bookDataCp = bookData;
      this.modalRef.componentInstance.parent = parent;
      this.modalRef.result.then(res,err=> res(false));
    })
	}


  public setOfferOpen(key) {
    if(this.offerBookMap.has(key))
      this.offerBookMap.set(key, (this.offerBookMap.get(key)||0) + 1)
    else this.offerBookMap.set(key, 1);
    return this.getOfferOpen(key);
  }

  public getOfferOpen(key) {
    return this.offerBookMap.get(key)||0;
  }

  openGifts(bookId:number, pages: number, validate = true) {
    const type = this.getType(pages);
    if(validate && this.setOfferOpen(bookId+"-"+type) > 1)
      return;
    return new Promise<any>(res=> {
      this.modalRef = this.modalService.open(ModalComponent, {centered: true, size: 'sm', windowClass: 'confetti-popup', keyboard:false, backdrop:'static'});
      this.modalRef.componentInstance.data = this.getMessages(type, pages);
      this.modalRef.componentInstance.type = type;
      this.modalRef.componentInstance.magnet = true;
      this.modalRef.result.then(res,err=> res);
    })
	}

  getType(pages:number) {
    // return pages < 24 ? "unlock_magnet" : pages < 32 ? "unlock_discount" : "unlocked"; 
    return pages < 24 ? "unlock_magnet" : "unlocked"; 
  }

  getMessages(type:string, pages: number) {
    const data = {};
    switch(type) {
      case 'unlock_magnet' : {
        data['m1'] = "Add "+(24-pages)+" more pages and";
        data['m2'] = "Unlock free polaroid magnet";
        // data['m3'] = "( And add "+(32 - pages)+" more pages to get Additional 10% off )";
      } break;
      case 'unlock_discount' : {
        data['m1'] = "Add "+(32 - pages)+" more pages and";
        data['m2'] = "Free Polaroid Magnet Unlocked";
        data['m3'] = "Get Additional 10% off";
      } break;
      case 'unlocked' : {
        data['m1'] = "GIFTS UnlockED";
        data['m2'] = "Polaroid Fridge Magnet ";
        // data['m3'] = "+ Additional 10% OFF ";
      } break;
    }
    return data;
  }

  closeAll() {
    if(this.modalService.hasOpenModals())
      this.modalService.dismissAll();
    return false;
  }
}