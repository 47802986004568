import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private activeStep = 1;

  private files: Array<File> = [];

  private originalImage = new Map();

  private cover: any;
  
  private magnet: any;

  private previewsMap = new Map();

  private originalImageMap = new Map();

  private imageStatus = new Map();

  private bookData: any;

  private userData: any;

  private addressData: any;

  accId : number = 0;
  private progress : number = 0;

  private accData : any;

  private renderFileData:any = {};

  private metadata:Array<any> = [];

  private params = '';
  private sku = '';
  private email = '';
  
  public rzpId = '';
  public pixel = false;
  public loader = false;
  public isNewBook = false;
  public isInfo = true;
  public isCongrat = false;
  public isModal:null|boolean = null;
  public inFrame = false;
  public inAction = false;
  public inActionMode = false;
  public isActiveUser = false;
  private activeToken: any;
  public checkoutStep = 0;
  public bookId:number|null = null;
  public orderType = 'stage';
  public draftCount = 0;
  public accepts = 'image/*,.heic';


  public address:any = {
    email: "", phone:"", fullName:"", pincode:"", city:"", address:"", landmark:"", state:""
  }

  orderData:any = {};
  guardUrl = '';

  editIndex = 1;
    colorData = [
    {"id":1,"bookColor":"#FFF","titleColor":"#000","logoColor":"#8DA4EE","name": "Classic White"},
    {"id":2,"bookColor":"#8FBBD4","titleColor":"#000","logoColor":"#FFF","name": "Serene Blue"},
    {"id":3,"bookColor":"#FFA3B7","titleColor":"#000","logoColor":"#FFF","name": "Blushing Rose"},
    {"id":4,"bookColor":"#B9A2DF","titleColor":"#000","logoColor":"#FFF","name": "Dreamy Lilac"}
  ];

  constructor() { 
    this.setAccDataInit();
    this.setBookDataInit();
  }


  setImageStatus(id, status) {
    this.imageStatus.set(id, status);
  }

  getImageStatus(id) {
    return this.imageStatus.get(id);
  }

  clearImageStatus() {
    this.imageStatus.clear()
  }

  setProgress(progress) {
    this.progress = progress;
  }

  incProgress(increment) {
    this.progress = this.progress+=increment;
  }

  getProgress() {
    return Math.ceil(this.progress);
  }


  setBookDataInit() {
    try {
      this.bookData = JSON.parse(localStorage.getItem("wb-data")||"{}")["bookData"];
      if(this.bookData)
        this.bookId = this.bookData.id;
    } catch(e){}
  }

  setAccDataInit() {
    try {
      this.accData = JSON.parse(JSON.parse(localStorage.getItem("wb-data")||"{}")["accData"]);
      if(this.accData)
        this.accId = this.accData.id
    } catch(e){}
  }

  public setGuardUrl(url) {
    this.guardUrl = (url);
  }

  public setEvLocal(key:string, value:string) {
    try {
      const data = JSON.parse(localStorage.getItem("wb-ev")||"{}");
      data[key] = value;
      localStorage.setItem("wb-ev", JSON.stringify(data));
    }catch(e){
      console.warn(e);
    }
  }

  public getEvLocal(key:string) {
    try {
      const data = JSON.parse(localStorage.getItem("wb-ev")||"{}");
      if(data[key])
        return data[key]
      return data[key]||null;
    }catch(e){
      console.warn(e);
    }
  }

  getFiles() {
    return this.files.length ? this.files : [];
  }

  removeFileReference(file: File) {
    const index = this.files.indexOf(file);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
  }

  reset() {
    this.files = [];
    this.originalImage.clear();
    this.activeStep = 1;
    this.bookData = null;
    this.isNewBook = false;
  }

  setFiles(files: Array<File>, isNewBook = false) {
    this.files = [...files]
    this.originalImage = new Map();
    this.isNewBook = isNewBook;
    // this.setItemLocal("files", JSON.stringify(files));
  }

  setFile(file: File, index:number) {
    this.files[index] = file;
  }

  addFile(files: Array<File>) {
    this.files = this.files.concat(files);
  }

  resetFiles() {
    this.files = [];
  }

  getCover() {
    return this.cover;
  }

  setCover(cover) {
    this.cover = cover;
  }

  getMagnet() {
    return this.magnet;
  }

  setMagnet(magnet) {
    this.magnet = magnet;
  }

  setOriginalImage(data:any, index:any, updateMap = true) {
    const d = this.originalImage.get(index);
    this.originalImage.set(index, data);
    updateMap ? this.updateOriginalMap(this.bookId, index, this.originalImage.get(index)) : null;
    d?.includes("blob:") ? URL.revokeObjectURL(d) : null;
  }

  setOriginalImages(data:Map<number, string>) {
    this.originalImage = cloneDeep(data);
    // this.originalImageMap.set(this.bookId, cloneDeep(data));
  }

  getOriginalImage() {
    return this.originalImage;
  }

  setOriginalMap(bookId:number) {
    this.originalImageMap.set(bookId, this.originalImage);
  }

  getOriginalMap(bookId:number) {
    return this.originalImageMap.get(bookId)||[];
  }

  updateOriginalMap(bookId:number|null, index:number, originalImage:any) {
    if(this.originalImageMap.has(bookId)){
      const data = this.originalImageMap.get(bookId);
      data[index] = originalImage;
      this.originalImageMap.set(bookId, data);
    }
  }

  setLoader(val:boolean, flag = false){
    setTimeout(()=> this.setLoaderExp(val));
    if(val && !flag) {
      setTimeout(()=> this.setLoaderExp(!val), 5000);
    }
  }

  private setLoaderExp(val:boolean){
    if(!(val === this.loader)){
      this.loader = val;
    }
  }

  setParams(data:any) {
    this.params = data;
  }

  getParams() {
    return this.params || "";
  }

  setSku(data:any) {
    this.sku = typeof data == 'string' ? data : '';
  }

  getSku() {
    return this.sku || "";
  }

  setInFrame(data:any) {
    this.inFrame = data;
  }

  getInFrame() {
    return this.inFrame;
  }

  setAction(data:any) {
    this.inAction = data;
  }

  getAction() {
    return this.inAction;
  }

  setInActionMode(data:any) {
    this.inActionMode = data;
  }

  getInActionMode() {
    return this.inActionMode;
  }

  setEmail(email:string) {
    if(email) {
      this.setItemLocal("email", email);
      this.email = email;
    }
  }

  getEmail() {
    return this.email || this.getItemLocal("email") || "";
  }

  setIsModal(isModal:boolean) {
    this.isModal = isModal;
  }

  getIsModal() {
    return this.isModal || this.isModal === null;
  }

  setIsCongrat() {
    this.isCongrat = true;
    setTimeout(()=> this.isCongrat = false, 7000)
  }

  getIsCongrat() {
    return this.isCongrat;
  }

  postMessageParent(data:any, val = null) {
    if(this.inFrame)
      window.parent.postMessage({ type : data, val }, environment.HOME_URL);
  }

  setAccData(data:any) {
    this.accData = data;
    this.accId = data.id;
    this.setItemLocal("accData", JSON.stringify(data));
    if(this.inFrame) {
      window.parent.postMessage({ type : 'accData', accData: JSON.stringify(data) }, environment.HOME_URL);
    }
  }

  getAccData() {
    return this.accData ? this.accData : JSON.parse(this.getItemLocal("accData"));
  }

  setUserData(data:any) {
    this.userData = data;
    this.setItemLocal("userData", JSON.stringify(data));
  }

  getUserData() {
    return this.userData ? this.userData : JSON.parse(this.getItemLocal("userData"));
  }

  setAddress(data:any) {
    this.addressData = data;
  }

  getAddress() {
    return this.addressData ? this.addressData : "";
  }

  getBookData() {
    const data = this.bookData ? this.bookData : null;
    if(!data) return null;
    typeof data["metadata"] == "string" ? data["metadata"] = JSON.parse(data["metadata"]) : null;
    typeof data["metaUrlsData"] == "string" ? data["metaUrlsData"] = JSON.parse(data["metaUrlsData"]) : null;
    const imgUrlMap = new Map();
    if(data["metaUrlsData"]) {
      data["metaUrlsData"].map(d=> {
        d['metaData'] = d['metaData'] ? JSON.parse(d['metaData']) : null
        d['boost'] = d['metaData'] && d['metaData']['boost'] ? 
        (typeof d['metaData']['boost'] == "string" ? JSON.parse(d['metaData']['boost']) : d['metaData']['boost'] ) : null;
        imgUrlMap.set(d.imageId, d);
      })
      delete data['metaUrlsData'];
      data["metaUrlsMap"] = imgUrlMap;
    }
    this.bookData = data;
    return cloneDeep(data);
  }

  getBookDataString() {
    const data = this.bookData ? this.bookData : null;
    data && typeof data["metadata"] != "string" ? data["metadata"] = JSON.stringify(data["metadata"]) : null;
    return data;
  }

  setBookData(value: any) {
    const data = cloneDeep(value);
    this.bookData = data;
    this.bookId = +data.id;
    data && typeof data["metadata"] == "object" ? data["metadata"] = JSON.stringify(data["metadata"]) : null;
    // this.setItemLocal("bookData", data);
  }

  private logout() {
    try {
      localStorage.setItem('logout', Date.now().toString());
    }catch(e){
      console.warn(e);
    }
  }

  setToken(token:string|null) {
    !token ? this.logout() : null;
    this.activeToken = token+"";
    this.setLocalToken(token+"");
  }

  getActiveToken() {
    return this.activeToken ? this.activeToken : this.getLocalToken();
  }

  private getLocalToken() {
    try {
        this.activeToken = localStorage.getItem("wb-token")||null;
        return this.activeToken||"";
    }catch(e){
      console.warn(e);
    }
    return "";
  }

  private setLocalToken(token:string|null) {
    try {
      localStorage.setItem("wb-token", token||"");
    }catch(e){
      console.warn(e);
    }
  }

  private setItemLocal(key:string, value:string) {
    try {
      const data = JSON.parse(localStorage.getItem("wb-data")||"{}");
      data[key] = value;
      localStorage.setItem("wb-data", JSON.stringify(data));
    }catch(e){
      console.warn(e);
    }
  }

  private getItemLocal(key:string) {
    try {
      const data = JSON.parse(localStorage.getItem("wb-data")||"{}");
      if(data[key])
        return data[key]
      return data[key]||null;
    }catch(e){
      console.warn(e);
    }
  }

  public removeItemLocal(key:string) {
    try {
      const data = JSON.parse(localStorage.getItem("wb-data")||"{}");
      if(data[key])
        delete data[key]
      localStorage.setItem("wb-data", JSON.stringify(data));
    }catch(e){
      console.warn(e);
    }
  }

  private setMetaLocal(value:string) {
    try {
      value = typeof value == "string" ? value : JSON.stringify(value);
      localStorage.setItem("wb-meta",value);
    }catch(e){
      console.warn(e);
    }
  }

  private getMetaLocal() {
    try {
      return JSON.parse(localStorage.getItem("wb-meta")||"{}");
    }catch(e){
      console.warn(e);
    }
    return null;
  }

}
