import { Component, ViewChild, ElementRef } from '@angular/core';
import { DataService } from './data-service.service';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { Helper } from './service/helper';
import { APIService } from './service/ApiService';
import { ToastrService } from 'ngx-toastr';
import { GtmService } from './gtm.service';
import { UploaderService } from './uploader.service';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('myCanvas')
  canvas: ElementRef<HTMLCanvasElement>|any = null;

  context: CanvasRenderingContext2D|any = null;

  canvaWidth = 800;
  canvaHeight = 800;

  title = 'whitebook-app';

  resultImage:any;

  inFrame = false;
  isBegin = true;
  isRedirect = false;
  bookId;
  isSmartTipShown = false;

  constructor(public dataService : DataService, private service: APIService,
    public _route: ActivatedRoute,
    private router: Router, public readonly titleService: Title,
    private location: PlatformLocation, private toaster: ToastrService,
    public modalService: ModalService,
    private gtm: GtmService, private uploader : UploaderService)
  {
    location.onPopState(() => Helper.isNextStep = false)
  }

  ngOnInit() {
    if(window) {
      // window.parent.postMessage({ type : 'ready' }, environment.HOME_URL);
      if (window.addEventListener) {
        window.addEventListener("message", this.receiveMessage.bind(this), false);
       } else {
         (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
      }
      if(window.location.ancestorOrigins[0] === environment.HOME_URL) {
        this.gtm.event('click_appInit', {})
        window.parent?.postMessage({ type : 'ready' }, environment.HOME_URL);
      }
      // const param = new URLSearchParams(window.location.search);
      // if(param && param.get("action")) {
      //   const url = param.get("action");
      //   if(url == "success") {
      //     this.dataService.rzpId = param.get("razorpay_order_id")||""
      //     this.router.navigate([url]);
      //   }
      //   if(url && url.includes("/")) {
      //     const urls = url.split("/");
      //     this.router.navigate([urls[0], urls[1]]);
      //   } else
      //     this.router.navigateByUrl(param.get("action")||"home")
      // }
    }
  }

  logout() {
    this.service.logout().then((res:any)=> {
      if(res && res.success) {
        this.toaster.success("Successfully logged out");
        this.router.navigate(["login"]);
      } else {
        this.toaster.warning("Already logged out");
      }
      this.dataService.setToken(null);
      this.postMessageParent('loggedOut');
    })
  }

  receiveMessage(event:any) {
    if(event.origin == environment.HOME_URL) {
      switch(event.data.type) {
        case 'initFrame' : {
          this.inFrame = true;
          this.dataService.inFrame = true;
          this.dataService.setAction(event.data.value);
          this.dataService.setInActionMode(event.data.value);
          this.sendUserDetails(event.data.value);
          break;
        }
        case 'logout' : {
          this.logout();
          break;
        }
        case 'create' : {
          this.isBegin || this.router.url == '/login' || this.router.url == '/orders' ? this.router.navigate(["home"]) : null;
          break;
        }
        case 'orders' : {
          this.iframeActive(true)
          this.router.navigate(["orders"]);
          break;
        }
        case 'redirect' : {
          this.isRedirect = true;
          this.router.navigate([event.data.value]);
          break;
        }
        case 'params' : {
          this.dataService.setParams(event.data.value)
          break;
        }
        case 'sku' : {
          this.dataService.setSku(event.data.value)
          break;
        }
        case 'noscroll' : {
          this.iframeActive(false)
          this.dataService.isInfo = event.data.value == "info"
          // setTimeout(()=> this.dataService.isInfo = event.data.value == "info", 1500);
          break;
        }
        case 'scroll' : {
          this.iframeActive(true)
          break;
        }
        case 'email' : {
          this.dataService.setEmail(event.data.value);
          break;
        }
        case 'isModal' : {
          this.dataService.setIsModal(event.data.value);
          break;
        }
        case 'isCongrat' : {
          this.dataService.setIsCongrat();
          break;
        }
        case 'files' : {
          // this.uploadFiles({target: {files : event.data.value } })
          break;
        }
        default : console.log(event.data);
      }
    }
  }

  private iframeActive(val:boolean) {
    document.body.style.overflow = val ? '' : 'hidden';
    this.dataService.setAction(!val);
  }

  getIsBack() {
    return this.router.url?.startsWith('/checkout');
  }

  goBack() {
    // this._location.back();
    const page = this.router.url;
    if(page.endsWith('/address')) {
      this.router.navigate(["flipbook", this.dataService.bookId]);
    } else if(page.endsWith('/billing')) {
      if(false && !this.isSmartTipShown)
        this.modalService.open("Smart Tip: Unlock Savings! Get creative with Collaging photos to reduce pages and pricing. Or contact team for assistance.").then(res=> {
          if(res) {
            this.router.navigate(["flipbook",this.dataService.bookId]);
          } else {
            this.router.navigate(["checkout",this.dataService.bookId, "address"]);
          }
        })
      else this.router.navigate(["checkout",this.dataService.bookId, "address"]);
      this.isSmartTipShown = true;
    } else {
      console.log(page);
    }
  }

  goOrders() {
    this.router.navigate(["orders"]);
  }

  minFrame() {
    this.isBegin = false;
    this.gtm.event('click_minFrame', {})
    if(this.inFrame) {
      const path = location.pathname?.slice(1);
      if(!this.dataService.isActiveUser) {
        this.postMessageParent(path && path.startsWith('home') ? 'close' : 'min');
      }
      else if(this.bookId && path && !path.startsWith('flipbook')) {
        path.startsWith('home') ? this.router.navigate(["flipbook", this.bookId]) : null;
        this.postMessageParent('min');
      }
      else
        this.postMessageParent('min');
    }
    else this.goOrders();
  }

  async sendUserDetails(isAction = false) {
    this.gtm.event('click_appReady', {})
    this.dataService.isActiveUser = await this.service.validateUser(true);
    if(this.isRedirect) {
      this.dataService.setAction(false);
      this.isRedirect = false;
      return;
    }
    if(this.dataService.isActiveUser){
      this.postMessageParent('loggedIn');
      this.sendDraftDetails(isAction);
    } else {
      this.postMessageParent('loggedOut');
    }
  }

  async sendDraftDetails(isAction) {
    this.bookId = await this.service.recentDraft();
    if(this.bookId) {
      if(isAction) {
        this.postMessageParent('min');
        this.router.navigate(["flipbook", this.bookId])
      }
      this.dataService.setAction(isAction);
      this.postMessageParent('recentDraft', this.bookId)
      this.service.draftCount();
    }
  }

  clickActionBar() {
    this.gtm.event('click_maxFrame'+(this.getPath()=='login' ? 'login' : ''), {})
    this.postMessageParent('max')
  }

  postMessageParent(data:any, val = null) {
    this.dataService.postMessageParent(data, val);
  }

  getAction() {
    return this.dataService.getAction(); 
  }

  getPath() {
    return location.pathname?.slice(1)||""
  }

  getCoverPrev() {
    return this.dataService.getCover()||"assets/images/favicon.png";
  }

  ngOnDestroy() {
    
  }
}
