<ng-container *ngIf="inEdit">
    <input type="file" #uploadeInput id="file-input" [accept]="parent.dataService.accepts" multiple (change)="uploadFiles($event)" style="display: none;">

    <ng-template #popTemplate><div [innerHtml]="tips"></div></ng-template>

    <div [ngClass]="inSort ? 'list-group sorting' : 'list-group'" [tooltip]="popTemplate"
    triggers="" #popImg="bs-tooltip" [delay]="500">
        <div [sortablejs]="imgPreviews" [sortablejsOptions]="sortOptions">
            <ng-container *ngFor="let file of imgPreviews; index as i">
                <div class="list-item col-6 pages grids" [ngClass]="{ 'static': i === 0, 'gap': i % 4 === 2 }" [attr.id]="'d'+i">
                    <ng-container *ngIf="i != 0">
                        <i class="fas fa-arrows-alt handle" *ngIf="inSort"></i>
                        <div [id]="i" class="pics" [ngClass]="parent.imgNameMap[i]?.[1] > 1 ? 'dub':''">
                            <i *ngIf="parent.imgNameMap[i]?.[1] > 1" (click)="parent.showWarning(i)" class="fas fa-exclamation-triangle warning-icon"></i>
                            <div class="num">{{dragId||i}}</div>
                            <img appLongPress (longPress)="onLongPress()" (click)="!inSort && selectImageEdit(i)"
                            [ngClass]="!file || file.startsWith('blob') && !parent.dataService.getImageStatus(i) ? 'greyed' : ''" [src]="file||'assets/images/favicon.png'"
                            oncontextmenu="return false;"/>
                            <div *ngIf="parent.dataService.getImageStatus(i) && parent.dataService.getImageStatus(i) != 'success'" class="overlay" (click)="parent.retry(i)">
                                <input type="file" #uploadeInput [id]="'file-input-'+i" [accept]="parent.dataService.accepts" (change)="parent.retryUpload($event, i)" style="display: none;">
                                <div *ngIf="parent.dataService.getImageStatus(i) != 'failed'; else retry" class="spinner"></div>
                                <small [ngClass]="parent.dataService.getImageStatus(i) ==  'failed' ? 'text-danger' : ''">{{parent.dataService.getImageStatus(i)}}</small>
                                <ng-template #retry>
                                    <small (click)="uploadeInput.click()">Retry</small>
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!inSort && i == 0">
                        <div class="pics" style="text-align: center; font-size: x-small; justify-content: center; display: grid; align-content: center;background: #D9D9D9;">
                            <small>
                                1. Tap photo to edit<br/>
                                2. Long press photo to rearrange
                            </small>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="!inSort && (imgPreviews.length - 1) < parent.utils.maxImages">
                <input type="file" #uploadeInput id="file-input" [accept]="parent.dataService.accepts" multiple (change)="uploadFiles($event)" style="display: none;">
                <div *ngIf="imgPreviews.length % 2 == 0" class="list-item col-6">
                    <!-- <ng-container [ngTemplateOutlet]="addPages"></ng-container> -->
                </div>
                <div class="list-item col-6">
                    <ng-container [ngTemplateOutlet]="addPagesOld"></ng-container>
                </div>
                
                <ng-template #addPagesOld>
                    <div class="pics">
                        <img (click)="!parent.isProcessing() && uploadeInput.click()" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" width="0" height="0">
                        <div *ngIf="parent.isProcessing() && (parent.getProcessed() <= parent.getTotalProcessing()) else addButton"
                            class=" " style="top: 40%;position: absolute;left: 25%;">
                            <div class="col ">
                                <div class="upload drop-area uploading">
                                    <div class="upload-info">
                                        <span class="upload-filename">
                                            &nbsp;Uploading ({{parent.getProcessed()}}/{{parent.getTotalProcessing()}})&nbsp;&nbsp;
                                        </span>
                                    </div>
                                    <div class="upload-progress"></div>
                                </div>
                            </div>
                        </div>
                        <ng-template #addButton>
                            <div class="addButton">
                                <button class="btn btn-sm my-auto addButton" (click)="!parent.isProcessing() && uploadeInput.click()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <g clip-path="url(#clip0_1639_769)">
                                            <path d="M7.875 15C7.875 15.2984 7.99353 15.5845 8.2045 15.7955C8.41548 16.0065 8.70163 16.125 9 16.125C9.29837 16.125 9.58452 16.0065 9.7955 15.7955C10.0065 15.5845 10.125 15.2984 10.125 15V10.125H15C15.2984 10.125 15.5845 10.0065 15.7955 9.7955C16.0065 9.58452 16.125 9.29837 16.125 9C16.125 8.70163 16.0065 8.41548 15.7955 8.2045C15.5845 7.99353 15.2984 7.875 15 7.875H10.125V3C10.125 2.70163 10.0065 2.41548 9.7955 2.2045C9.58452 1.99353 9.29837 1.875 9 1.875C8.70163 1.875 8.41548 1.99353 8.2045 2.2045C7.99353 2.41548 7.875 2.70163 7.875 3V7.875H3C2.70163 7.875 2.41548 7.99353 2.2045 8.2045C1.99353 8.41548 1.875 8.70163 1.875 9C1.875 9.29837 1.99353 9.58452 2.2045 9.7955C2.41548 10.0065 2.70163 10.125 3 10.125H7.875V15Z" fill="#85A4FF"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1639_769">
                                            <rect width="18" height="18" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div class="text mt-3">Tap to add photos</div>
                                </button>
                            </div>
                        </ng-template>
                    </div>
                </ng-template>
                
                <ng-template #addPages>
                    <div class="pics dropdownnn" style="position: relative;">
                        <img (click)="!parent.isProcessing() && isDropdownOpen = !isDropdownOpen" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" width="0" height="0">
                        <div class="addButton">
                            <button class="btn btn-sm my-auto" (click)="!parent.isProcessing() && isDropdownOpen = !isDropdownOpen">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g clip-path="url(#clip0_1639_769)">
                                      <path d="M7.875 15C7.875 15.2984 7.99353 15.5845 8.2045 15.7955C8.41548 16.0065 8.70163 16.125 9 16.125C9.29837 16.125 9.58452 16.0065 9.7955 15.7955C10.0065 15.5845 10.125 15.2984 10.125 15V10.125H15C15.2984 10.125 15.5845 10.0065 15.7955 9.7955C16.0065 9.58452 16.125 9.29837 16.125 9C16.125 8.70163 16.0065 8.41548 15.7955 8.2045C15.5845 7.99353 15.2984 7.875 15 7.875H10.125V3C10.125 2.70163 10.0065 2.41548 9.7955 2.2045C9.58452 1.99353 9.29837 1.875 9 1.875C8.70163 1.875 8.41548 1.99353 8.2045 2.2045C7.99353 2.41548 7.875 2.70163 7.875 3V7.875H3C2.70163 7.875 2.41548 7.99353 2.2045 8.2045C1.99353 8.41548 1.875 8.70163 1.875 9C1.875 9.29837 1.99353 9.58452 2.2045 9.7955C2.41548 10.0065 2.70163 10.125 3 10.125H7.875V15Z" fill="#85A4FF"/>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1639_769">
                                        <rect width="18" height="18" fill="white"/>
                                      </clipPath>
                                    </defs>
                                </svg>
                                <div class="text mt-3">Tap to Add Pages</div>
                            </button>
                        </div>

                        <app-custom-select *ngIf="isDropdownOpen"
                            [options]="[{id:'choose', name: 'Choose Photos'},{id:'file', name: 'Add Photos'}]"
                            [isDropdownOpen]="isDropdownOpen"
                            (output)="$event == 'file' ? uploadeInput.click() : showUploadedPhotos() "
                        ></app-custom-select>
                        
                    </div>
                </ng-template>
            </ng-container>
            <div *ngIf="inSort && imgPreviews.length % 2 != 0" class="list-item col-6 static" style="margin-bottom: 50px;">
                <div class="pics" style="aspect-ratio: 1 / 1;text-align: center;border: none;border-radius: 3px;">
                    <div class="" style="display: flex; align-items: flex-end; justify-content: center; width: 100%; height: 100%;">
                       
                    </div>
                </div>
            </div>
            <div *ngIf="!inSort" class="list-item col-6 static" style="margin-bottom: 50px;">
                <div class="pics" style="aspect-ratio: 1 / 1;text-align: center;border: none;border-radius: 3px;" [ngStyle]="{'background-color' : bookData.metadata?.cover?.color}">
                    <div class="" style="display: flex; align-items: flex-end; justify-content: center; width: 100%; height: 100%;">
                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 70"
                            width="400" height="70"
                            style="margin: -20px auto; width: 20%;" [ngStyle]="{'fill': bookData.metadata?.cover?.logoColor}">
                            <path class="a"
                                d="m59.9 46.1l-8.6 20.8c-0.6 1.5-2.2 2.5-3.8 2.5h-8.5c-1.7 0-3.2-1-3.8-2.6l-4.1-10.2c-0.3-0.8-1.5-0.8-1.8 0l-4.1 10.2c-0.6 1.6-2.1 2.6-3.8 2.6h-8.5c-1.6 0-3.2-1-3.8-2.5l-8.6-20.8c-1.1-2.7 0.9-5.7 3.8-5.7h3.9c1.8 0 3.3 1.1 3.9 2.7l4.8 12.9c0.3 0.9 1.6 0.9 1.9 0l4.4-12.8c0.6-1.7 2.2-2.8 3.9-2.8h6.2c1.8 0 3.3 1.1 3.9 2.8l4.5 12.8c0.3 0.9 1.5 0.9 1.9 0l4.7-12.9c0.6-1.6 2.2-2.7 3.9-2.7h3.9c3 0 4.9 3 3.8 5.7z">
                            </path>
                            <path class="a"
                                d="m109.1 52.4v13.9c0 1.7-1.4 3.1-3.2 3.1h-5.3c-1.7 0-3.1-1.4-3.1-3.1v-9.1c0-5-1.8-7.1-7-7.1-3.2 0-6.6 1.3-8.9 3.3-0.2 0.2-0.4 0.5-0.4 0.9v12c0 1.8-1.4 3.2-3.1 3.2h-5.3c-1.7 0-3.1-1.5-3.1-3.2v-32.5c0-1.7 1.4-3.1 3.1-3.1h5.3c1.7 0 3.1 1.4 3.1 3.1v8.5c0 0.9 0.9 1.3 1.6 0.9 3.2-1.9 7.2-3.3 12.4-3.3 6.5 0 13.9 2 13.9 12.5z">
                            </path>
                            <rect class="a" x="117.4" y="40.3" width="11.6" height="29.1" rx="3">
                            </rect>
                            <path class="a"
                                d="m155.3 50.1c-0.6 0-1.1 0.5-1.1 1.1v4.9c0 2.7 1.1 4.1 4.4 4.1 1.6 0 2.7 0 4.1-0.1 1.8-0.1 3.3 1.3 3.3 3.1v3.2c0 1.6-1.2 3-2.8 3.2-2.1 0.2-4.6 0.3-7.2 0.3-8.9 0-13.3-2.8-13.3-9v-9.7c0-0.7-0.5-1.1-1.1-1.1h-1.7c-1.7 0-3.1-1.4-3.1-3.2v-3.4c0-1.7 1.4-3.2 3.1-3.2h1.7c0.6 0 1.1-0.4 1.1-1v-1.3c0-1.5 0.9-2.7 2.3-3.1l5.4-1.3c1.9-0.5 3.8 1 3.8 3v2.7c0 0.6 0.5 1 1.1 1h7.6c1.7 0 3.1 1.5 3.1 3.2v3.4c0 1.8-1.4 3.2-3.1 3.2z">
                            </path>
                            <path fill-rule="evenodd" class="a"
                                d="m201.9 58.5h5.7c2.5 0 4 2.9 2.6 5-2.9 4.3-8.6 6.4-17.6 6.4-11.3 0-19.2-4.2-19.2-15 0-10.8 7.9-15 19.7-15 10.7 0 18 3.4 19.3 13.4 0.2 1.9-1.2 3.5-3.1 3.5h-22.4c-0.9 0-1.4 1-0.8 1.7 1.4 1.7 4 2.2 6.5 2.2 3.7 0 5.5-0.2 7.5-1.6 0.5-0.4 1.1-0.6 1.8-0.6zm-14-7.5h10.2c1 0 1.3-1.3 0.5-1.8-1.5-1-3.5-1.1-5.6-1.1-2.1 0-4.1 0.1-5.6 1.1-0.9 0.5-0.5 1.8 0.5 1.8z">
                            </path>
                            <path fill-rule="evenodd" class="a"
                                d="m259.8 54.9c0 11.7-8.4 15-15.8 15-4.2 0-8.3-1.1-11.3-3.8-0.3-0.2-0.7 0-0.7 0.3 0 1.7-1.3 3-3 3h-5.5c-1.7 0-3-1.3-3-3v-32.7c0-1.7 1.3-3 3-3h5.5c1.7 0 3 1.3 3 3v8.3c0 0.9 1 1.4 1.7 0.9 2.9-2.1 6.5-3 10.3-3 7.4 0 15.8 3.3 15.8 15zm-11.6 0c0-4.3-2.9-5.3-8.1-5.3-5.1 0-8.1 1-8.1 5.3 0 4.3 3 5.3 8.1 5.3 5.2 0 8.1-1 8.1-5.3z">
                            </path>
                            <path fill-rule="evenodd" class="a"
                                d="m267.1 54.9c0-10.8 7.4-15 19.7-15 12.3 0 19.7 4.2 19.7 15 0 10.8-7.4 15-19.7 15-12.3 0-19.7-4.3-19.7-15zm27.8 0c0-4.3-2.9-5.3-8.1-5.3-5.1 0-8.1 1-8.1 5.3 0 4.3 2.9 5.3 8.1 5.3 5.1 0 8.1-1 8.1-5.3z">
                            </path>
                            <path fill-rule="evenodd" class="a"
                                d="m313.8 54.9c0-10.8 7.4-15 19.7-15 12.3 0 19.6 4.2 19.6 15 0 10.8-7.3 15-19.6 15-12.3 0-19.7-4.3-19.7-15zm27.8 0c0-4.3-2.9-5.3-8.1-5.3-5.2 0-8.1 1-8.1 5.3 0 4.3 2.9 5.3 8.1 5.3 5.2 0 8.1-1 8.1-5.3z">
                            </path>
                            <path class="a"
                                d="m389 53.3l9.9 10.6c1.9 2.1 0.5 5.5-2.4 5.5h-6c-0.9 0-1.8-0.4-2.4-1.1l-8.1-9.1c-0.4-0.4-1-0.5-1.4-0.2l-5.6 4.1c-0.3 0.2-0.4 0.5-0.4 0.9v2.2c0 1.7-1.5 3.2-3.2 3.2h-5.1c-1.8 0-3.3-1.5-3.3-3.2v-32.3c0-1.8 1.5-3.2 3.3-3.2h5.1c1.7 0 3.2 1.4 3.2 3.2v14.7c0 0.9 1 1.4 1.7 0.8l11-8.4c0.5-0.4 1.2-0.7 1.9-0.7h7.2c3.1 0 4.4 4 1.9 5.9l-7.2 5.5c-0.5 0.4-0.6 1.1-0.1 1.6z">
                            </path>
                            <rect class="a" x="110.9" y="8.8" width="24.6" height="24.7" rx="6">
                            </rect>
                            <rect class="a" x="138.1" y=".1" width="24.6" height="24.7" rx="6">
                            </rect>
                        </svg>
                    </div>
                    <div class="cover-rim cover-rim-last" [ngClass]="'e'+ bookData.metadata?.cover?.color?.slice(1)"></div>
                </div>
            </div>
        </div>
    </div>

</ng-container>