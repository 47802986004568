import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { DataService } from './data-service.service';
import { NavigationEnd, Router } from '@angular/router';
import moengage from "@moengage/web-sdk";
declare let gtag: Function;
declare let fbq: Function;
// const moengage = require("@moengage/web-sdk");
@Injectable({
  providedIn: 'root'
})
export class GtmService {

  accId = 0;
  bookId = 0;

  accs = [794,1371,102,7269,2,52,5954];

  eventIdMap = new Map();

  constructor(private ds: DataService, private gtmService: GoogleTagManagerService, private router: Router) {
    if(environment.production) {
       const param = new URLSearchParams(window.location.search);
      if(!param || !param.get("utm_source") || param.get("utm_source") != 'google') {
        this.ds.pixel = true;
        fbq('init', 699649228146674);
        fbq('track', 'PageView');
      }
      moengage.initialize({app_id: environment.MOE_ID, cluster: 'DC_3'});
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: /checkout|flipbook/.test(item.url) ? item.url.replace(/\/(\d)+/, "/[id]") : item.url
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    }
  }

  private getIsTrack() : boolean {
    return this.ds.accId ? !this.accs.includes(this.ds.accId) : true;
  }

  async sendMoe(fn, value) {
    if(environment.production)
      return await moengage?.[fn]?.(value);
    return true;
  }

  trackEvent(eventName: string, params?: any) {
    if(environment.production && this.getIsTrack()) {
      try {
        if(this.ds.pixel)
          fbq('track', eventName, params, { "eventID" : this.getEventId(eventName)});
        gtag('event', eventName, params);
        moengage.track_event(eventName, this.getMoengData(params));
      } catch(e){}
    }
  }

  public setEventId(eventName) {
    const id = this.generateEventId();
    this.ds.setEvLocal(this.ds.bookId +"-"+  eventName, id)
    this.eventIdMap.set(eventName, id);
    return this.eventIdMap.get(eventName);
  }
     
  public getEventId(eventName) {
    if(this.eventIdMap.has(eventName))
      return this.eventIdMap.get(eventName);
    else {
      return this.ds.getEvLocal(this.ds.bookId +"-"+  eventName);
    }
  }
    
  private  generateEventId() {
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
    const eventId = `${timestamp}-${randomNumber}`;
    return eventId;
  }

  private getMoengData(eparam) {
    const data = this.ds.getBookData();
    const odata = this.ds.orderData;
    let param = {};
    if(data) {
      param = {
        "book_title": data.title,
        "book_id" : data.id,
        "book_cover": data.metadata.cover.previewId,
        "book_type": data.type,
        "product_name":"Whitebook - HardCover"
      }
      if(odata) {
        param["book_size"] = odata.bookSize;
        param["status"] = odata.status;
      }
    }
    return Object.assign(eparam, param);
  }

  sendConversion(eventName:string, params : any) {
    if(environment.production) {
      gtag('event', eventName, params);
    }
  }

  event(name:string, data:any) {
    // push GTM data layer with a custom event
    if(environment.production) {
      this.sendEvent(name, data);
    }
    
  }

  private sendEvent(name:string, val : any) {
    const acc = { aid:this.accId, bid: this.bookId }
    const data = Object.assign(acc, val);
    if(!this.ds.getIsModal() && this.ds.getInActionMode()) {
      name += '0';
    } else if(!this.ds.getIsModal() && !this.ds.getInActionMode()) {
      name += '1';
    }
    this.postMessageParent({
      event: name,
      data: data,
    });
  }

  postMessageParent(data:any) {
    if(this.ds.inFrame)
      window.parent.postMessage({ type : 'event', eventData: data }, environment.HOME_URL);
    // else 
    //   this.gtmService.pushTag(data);
  }
  
}
