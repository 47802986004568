import { Component, ViewChild } from '@angular/core';
// import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../modal.service';
import { cloneDeep } from 'lodash';
import { CoverComponent } from '../cover/cover.component';
import { PreviewService } from '../preview.service';
import { SelectPhotosComponent } from '../select-photos/select-photos.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-page',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent {

  // @ViewChild('cropperView', { static: false }) cropComponent!: CroppieComponent;

  suggestedCaptions : string[] = []

  renderImage!: string;
  actualImage!: string;
  renderMeta:any;
  imgDataCp:any;
  
  editIndex = 1;

  inEdit = false;
  inCrop = false;
  inEditor = false;
  inCollage = false;
  loading = false;

  captionActive = false;
  isDel = false;
  accepts = "";
  //Ngx-compress
  canvasRotation = 0;
  scale = 1;
  showCropper = false;
  // transform: ImageTransform = {};
  maxCaption = 80;

  private subscribe!:Subscription;
  private modalSubscription!: Subscription;
  private modalRef;
  
  constructor(private router: Router, public parent: CoverComponent, 
    private modalService:ModalService, private customModal:NgbModal) {
    this.accepts = this.parent.uploader.accepts;
    this.suggestedCaptions = this.parent.utils.getCaptions(this.parent.bookData.type);
  }

  ngOnInit() {
    this.editIndex = cloneDeep(this.parent.dataService.editIndex);
    this.actualImage = cloneDeep(this.parent.imgPreviews[this.editIndex]);
    this.renderMeta = cloneDeep(this.parent.bookData.metadata.images[this.editIndex]);
    this.checkIsDelete();
    this.parseActualImage();
  }

  checkIsDelete() {
    this.isDel = this.parent.bookData.metadata.images.length > this.parent.utils.minImages +1;
  }

  async prevImg() {
    if(this.editIndex > 1) {
      this.parent.gtm.event('click_prev'+this.editIndex, {});
      --this.editIndex;
      this.actualImage = cloneDeep(this.parent.imgPreviews[this.editIndex]);
      this.renderMeta = cloneDeep(this.parent.bookData.metadata.images[this.editIndex]);
      this.revokeUrl(this.renderImage);
      this.renderImage = '';
      this.parseActualImage();
    } else {
      this.backToAlbum();
    }
  }

  async nextImg() {
    if(this.editIndex + 1 < this.parent.bookData.metadata.images.length) {
      this.parent.gtm.event('click_next'+this.editIndex, {});
      ++this.editIndex;
      this.actualImage = cloneDeep(this.parent.imgPreviews[this.editIndex]);
      this.renderMeta = cloneDeep(this.parent.bookData.metadata.images[this.editIndex]);
      this.revokeUrl(this.renderImage);
      this.renderImage = '';
      this.parseActualImage();
    } else {
      this.backToAlbum();
    }
  }

  revokeUrl(url) {
    URL.revokeObjectURL(url);
  }

  updateText(event) {
    this.parent.gtm.event('click_captionU', {});
    this.renderMeta.metadata.text = event.slice(0,this.maxCaption);
    this.parseActualImage();
  }

  captionToggle() {
    this.parent.gtm.event('click_inCaption', {});
    this.captionActive = !this.renderMeta.metadata.caption;
    this.renderMeta.metadata.caption = this.captionActive;
    if(this.captionActive) {
      this.renderMeta.metadata.text = this.renderMeta.metadata.text || 
      ( this.suggestedCaptions.length ? this.suggestedCaptions[Math.floor(Math.random()*this.suggestedCaptions.length)] : "Your Captions Here");
    } else delete this.renderMeta.metadata.text;
    this.parseActualImage()
  }

  async styleImage(val) {
    if(val == 'original')
      this.renderMeta.metadata.rotate = 0;
    
    if(val == "collage" || !val && this.renderMeta.metadata.style == 'collage') {
      this.openCollage();
      return this.renderImage;
    } 
    if(val) return new Promise((resolve)=> {
      resolve(this.parseImage(val));
    })
    else return null;
  }

  openCollage() {
    this.inCrop = false;
    this.inCollage = true;
    this.imgDataCp = this.parent.bookData.metadata.images[this.editIndex]
  }

  async parseActualImage(preview = false, isSave = false) {
    if(isSave) {
      this.renderImage = await this.parent.preview.genImagePreview(this.parent.originalUrls, this.renderMeta);
      this.parent.imgPreviews[this.editIndex] = this.renderImage;
      this.parent.setImageMapName(this.editIndex, this.renderMeta);
      this.parent.bookData.metadata.images[this.editIndex] =  cloneDeep(this.renderMeta);
    } else
      this.renderImage = await this.parent.preview.genImagePreview(this.parent.originalUrls, this.renderMeta, 0.9, 'base64', 'jpeg', false, preview);
    return true;
  }

  private parseImage(val = '', isSave = false):Promise<string> {
    return new Promise(async (resolve)=> {
      this.loading = true;
      try {
        this.revokeUrl(this.renderImage);
        if(!val && this.renderMeta.metadata.style == 'collage')
          this.parseActualImage();
        else {
          val ? this.renderMeta.metadata.style = val : null;
          this.renderMeta = await this.parent.preview.newImagePreview(this.parent.originalUrls.get(this.renderMeta.id).rawUrl, this.renderMeta);
          this.renderImage = this.renderMeta.src;
        }
        this.loading = false;
        resolve(this.renderImage);
      } catch(ex:any){
        this.parent.toaster.error(ex.toString())
      } finally {
        this.loading = false;
      }
    });
  }

  setCollage(data:any) {
    // this.parent.dataService.setPreview(data.src, this.currentImg);
    // this.parent.imgPreviews[this.currentImg] = data.src;
    // this.parent.imgPreviews[this.currentImg] = data.src;
    // this.imgPreviews[this.currentImg] = data.src;
    const revokeUrl = this.renderImage;
    this.renderMeta = data;
    this.renderImage = data.src;
    this.saveAndNext(false);
    URL.revokeObjectURL(revokeUrl);

    // this.bookData.metadata.images[this.currentImg] = cloneDeep(this.renderMeta);
    // const bookData = cloneDeep(this.bookData);
    // this.parent.bookData = bookData;
    // this.parent.bookData = bookData;
  }

  edit() {
    this.parent.gtm.event('click_editP', {});
    this.inEditor = !this.inEditor;
  }

  deletePage() {
    this.modalService.open("Are you sure you want to remove this page from your Flipbook ?").then(res=> {
      this.parent.gtm.event('click_deleteP', {});
      if(!res) return;
      const ind = this.editIndex;
      const bookData = cloneDeep(this.parent.bookData);
      bookData.metadata.images.splice(ind, 1);
      this.parent.uploader.updateBooks(bookData).then((r:any)=> {
        if(r) {
          this.parent.toaster.success("Successfully removed");
          this.removePhoto()
        } else this.parent.toaster.error("Failed !");
      });
    })
  }

  removePhoto() {
    const ind = this.editIndex;
    const imgIndex = this.parent.bookData.metadata.images[this.editIndex].id;
    this.parent.imgPreviews.splice(ind, 1);
    // this.imgPreviews = cloneDeep(this.parent.imgPreviews);
    this.parent.originalUrls.delete(imgIndex)
    // this.originalUrls = cloneDeep(this.parent.originalUrls)
    this.parent.bookData.metadata.images.splice(ind, 1);
    this.parent.dataService.setBookData(this.parent.bookData);
    this.parent.bookData = this.parent.dataService.getBookData();
    // this.parent.dataService.setOriginalImages(this.originalUrls)
    // this.parent.dataService.setPreviews(this.parent.imgPreviews)
    if(this.editIndex == this.parent.originalUrls.size){
      this.prevImg();
    } else{
      this.renderMeta = cloneDeep(this.parent.bookData.metadata.images[this.editIndex]);
      this.renderImage = this.parent.imgPreviews[this.editIndex];
    }
    this.checkIsDelete();
  }

  crop() {
    this.parent.gtm.event(this.inCrop ? 'click_cropCancel' : 'click_crop', {});
    if(this.inCrop) {
      this.renderMeta.metadata["crop"] = cloneDeep(this.parent.bookData.metadata.images[this.editIndex]).metadata?.crop;
      this.parseActualImage();
    }
    this.inCrop = !this.inCrop;
  }
  
  getCropData(crop) {
    // const crop = {sx:+data[0], sy:+data[1], sw: data[2] - data[0], sh: data[3] - data[1]};
    this.renderMeta.metadata["crop"] = crop;
    this.renderMeta.metadata["dpi"] = this.parent.preview.calculateDpi(crop.sw, crop.sh);
  }

  cropImage() {
    this.parent.gtm.event('click_cropConfirm', {});
    this.inCrop = false;
    this.parseActualImage();
  }

  async saveAndNext(next = true) {
    this.parent.gtm.event('click_savePage', {});
    return new Promise(async (resolve)=> {
      await this.parseActualImage(false, true).then(async (res)=> {
        this.revokeUrl(this.renderImage);
        await this.updateBook(next);
        resolve(true);
      });
    });
  }

  updateBook(next) {
    return new Promise(async (resolve)=> {
      this.parent.service.updateBooks(this.parent.bookData).then(x=> {
        if(x && x.data){
          this.parent.dataService.setLoader(false);
          this.parent.dataService.setBookData(x.data);
          this.parent.bookData = this.parent.dataService.getBookData();
          next ? this.nextImg() : this.editIndex == this.parent.bookData.metadata.images.length - 1 ? this.backToAlbum() : null;
        }
        resolve(true)
      });
    })
  }

  imageCropped(event: any) {
    this.renderImage = event.data;
    this.renderMeta["metadata"]["crop"] = {}
    // console.log(this.renderMeta["metadata"]["crop"]);
    
    this.renderMeta["metadata"]["crop"]["x1"] = event.crop.x;
    this.renderMeta["metadata"]["crop"]["y1"] = event.crop.y;
    this.renderMeta["metadata"]["crop"]["w"] = event.crop.width;
    this.renderMeta["metadata"]["crop"]["h"] = event.crop.height;
    this.cropImage();
  }

  loadImageFailed() {
    console.error('Load failed');
  }

  rotateRight() {
    this.parent.gtm.event('click_rotate', {});
    let r = this.renderMeta["metadata"]["rotate"]||0;
    r+=90;
    r = r == 360 ? 0 : r;
    this.renderMeta["metadata"]["rotate"] = r;
    this.parseActualImage();
    // this.cropComponent.rotate(r);
  }

  resetImage() {
    this.renderMeta = cloneDeep(this.parent.bookData.metadata.images[this.editIndex]);
    this.parseImage();
  }

  backToAlbum() {
    this.parent.gtm.event('click_backPage', {});
    this.revokeUrl(this.renderImage);
    // this.parent.inFlipBook = !this.parent.inFlipBook;
    // this.renderMeta = cloneDeep(this.parent.bookData.metadata.images[this.editIndex]);
    // this.parseImage();
    this.router.navigate(["flipbook", this.parent.dataService.bookId])
    setTimeout(()=> this.parent.scrollToPage(this.editIndex));
  }

  openModal() {
    this.modalRef = this.customModal.open(SelectPhotosComponent,
      {
        centered: true, backdrop: 'static', keyboard: false
      }
    );
    this.modalRef.componentInstance.images = this.getRawImages();
    const usedIndex = this.parent.bookData.metadata.images.map(x=> x.metadata.style == 'collage' ? x.metadata.collage.metadata.images.map(y=> y.id) : x.id).flat()
    this.modalRef.componentInstance.usedIndex = usedIndex;
    this.modalRef.componentInstance.imgIndex = this.renderMeta.id;
    this.modalSubscription = this.modalRef.componentInstance.actionConfirmed.subscribe((data: any) => {
      if(data.type == 'close')
        this.closeModalFromParent();
      else if(data.type == 'selected')
        this.getImageSelection(data.value);
      else if(data.type == 'save')
        this.setImageSelection(data.value);
      else if(data.type == 'upload')
        this.uploadFiles(data.value);
    });
  }

  getRawImages() {
    const images:any = [];
    Array.from(this.parent.originalUrls.keys()).forEach(x=> {
      // if(x != 0 && this.renderMeta.id != x) {
        const d = this.parent.originalUrls.get(x);
        images.push({id:x, src: d.previewUrl});
      // }
    })
    return images;
  }

  getImageSelection(value:any) {
    const index = this.parent.findAllIndex(this.parent.bookData.metadata.images, (item) => item.id === value.id);
    if(index.length) {
      this.modalRef.componentInstance.message = value.isDub ? `Photo will be same for Page ${this.editIndex} and Page ${index.join(', ')}` : 
      `Photo will be moved from Page ${index.join(', ')} to Page ${this.editIndex}`
    } else
    this.modalRef.componentInstance.message = "";
  }

  async setImageSelection(value:any) {
    await this.parent.addImage(this.editIndex, value.id, value.isDub);
    this.renderImage = '';
    this.ngOnInit();
    this.closeModalFromParent();
  }

  uploadFiles(event:any) {
    this.parent.uploadFiles(event, false).then(res=> {
      this.modalRef.componentInstance.images = this.getRawImages();
    });
  }

  closeModalFromParent() {
    this.unsubscribeModal();
    if (this.modalRef) {
      this.modalRef.close('Closing from parent');
    }
  }

  unsubscribeModal() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.subscribe?.unsubscribe();
    this.unsubscribeModal();
  }

}
