<div class="p-2">
    <div class="text-center text-muted">Your Polaroid Fridge Magnet</div>
    <div>
        <div class="row px-4 edit" style="text-align: center;">
            <div class="action pt-2">
                <div class="col">
                    <i (click)="openModal()">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" stroke="currentColor" stroke-width="2"><path d="M8 21 L15 11 L19 15"></path><path d="M15 2 v5 h5"></path><path d="M8 2 h8 l4 4 v12 q0 4 -4 4 h-8 q-4 0 -4 -4 v-12 q0 -4 4 -4z"></path></g><circle fill="currentColor" cx="10" cy="8" r="1.5"></circle></g></svg>
                    </i>
                    <div class="text-muted">Select</div>
                </div>
               <div class="col">
                    <i (click)="crop()">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g stroke-width="2" stroke="currentColor" fill="none"><path d="M23 17H9a2 2 0 0 1-2-2v-5m0-3V1 M1 7h14a2 2 0 0 1 2 2v7m0 4v3"></path></g></g></svg>
                    </i>
                    <div class="text-muted">Crop</div>
                </div>
                 <!-- <div class="col" *ngIf="renderMeta?.metadata.style != 'collage'">
                    <i (click)="rotateRight()">
                        <svg class="" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round" style="transform: matrix(-1,0,0,1,0,0)"><g><g stroke="none" fill="currentColor"><path fill="none" d="M-1-1h582v402H-1z"></path><rect x="3" rx="1" height="12" width="12" y="9"></rect><path d="M15 5h-1a5 5 0 015 5 1 1 0 002 0 7 7 0 00-7-7h-1.374l.747-.747A1 1 0 0011.958.84L9.603 3.194a1 1 0 000 1.415l2.355 2.355a1 1 0 001.415-1.414l-.55-.55H15z"></path></g></g></svg>
                    </i>
                    <p>Rotate 
                    </p>
                </div>
                <div class="col">
                    <i (click)="deletePage()">
                        <svg width="24" height="24" viewBox="2 2 18 18" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" fill-rule="evenodd"><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M7.5 7h9z"></path><path d="M7.916 9h8.168a1 1 0 01.99 1.14l-.972 6.862a2 2 0 01-1.473 1.653c-.877.23-1.753.345-2.629.345-.876 0-1.752-.115-2.628-.345a2 2 0 01-1.473-1.653l-.973-6.862A1 1 0 017.916 9z" fill="currentColor"></path><rect fill="currentColor" x="10" y="5" width="4" height="3" rx="1"></rect></g></g></svg>
                    </i>
                    <p>Delete</p>
                </div> -->
            </div>
    
    </div>
    <ng-container *ngIf="!inCrop">
        <div class="magnet py-3" *ngFor="let magnet of magnetData">
            <img [src]="magnet.src">
            <div class="overlay" *ngIf="loading">loading...</div>
        </div>
        <ng-container *ngIf="!magnetData.length">
            <div class="magnet py-3">
                <img class="muted" [src]="image"/>
                <div class="overlay" *ngIf="loading">loading...</div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="inCrop">
       <div class="imageBox m-auto">
            <div class="imgCont">
                <app-croppie  #cropperView (cropper)="getCropData($event)" [imgData]="renderData"
                [imgOrgUrl]="originalUrls.get(renderData.id)"></app-croppie>
            </div>
       </div>

        <div class="row text-center px-3" style="display: flex;margin: auto;width: 100%;padding-top: 45px;">
            <div class="col-6">
                <button class="btn btn-sm btn-secondary" (click)="crop()">Cancel</button>
            </div>
            <div class="col-6">
                <button class="btn btn-sm btn-primary" (click)="cropImage()">Crop</button>
            </div>
        </div>
    </ng-container>

    <div class="details" *ngIf="magnetData.length">
       <!-- <div class="row w-50 m-auto">
        <div class="col-6 text-center" (click)="changeLayout('mg2')">Full</div>
        <div class="col-6 text-center" (click)="changeLayout('mg1')">Fit</div>
       </div> -->
       <div class="value">
            <div class="input-group input-title">
                <input type="text" class="form-control py-1" name="title" [maxlength]="renderData.metadata?.frame?.titleLen"
                [ngModel]="renderData.title" (ngModelChange)="changeTitle($event)"  (blur)="onBlur()" 
                style="font-size: 16px;">
            </div>
        </div>
        <div class="value" style="display: flex;justify-content: space-around;">
            <div class="checkbox" style="font-size: 16px;display: flex;align-items: center;">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" name="date" id="date" [ngModel]="renderData.isDob" (ngModelChange)="changeIsDob()" >
                </div>
            </div>
            <app-date-picker *ngIf="renderData" [disabled]="!renderData.isDob" (selected)="changeDob($event)" [date]="renderData.date" ></app-date-picker>
            <select class="form-select py-1" id="colorSelect" (change)="changeType($event)">
                <option [value]="null" [attr.selected]="!renderData.type ? true : null">--</option>
                <option *ngFor="let c of magnetTypes" [value]="c.name" 
                    [attr.selected]="renderData.type == c.name ? true : null">
                    {{c.display}}
                </option>
            </select>
        </div>
    </div>
</div>
<div class="p-3 footer">
    <button class="btn btn-sm btn-secondary" (click)="close()">Later</button>
    <button class="btn btn-sm btn-primary" (click)="save()">Save</button>
</div>