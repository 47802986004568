<div class="date-picker">
    <!-- <div class="date-input">
        <select class="custom-select" [(ngModel)]="selectedDay" (ngModelChange)="validateDate()">
            <option *ngFor="let day of days" [value]="day">{{ day }}</option>
        </select>
    </div>
    <span>-</span>
    <div class="date-input">
        <select class="custom-select" [(ngModel)]="selectedMonth" (ngModelChange)="validateDate()">
            <option *ngFor="let month of months" [value]="month">{{ month }}</option>
        </select>
    </div>
    <span>-</span>
    <div class="date-input">
        <select class="custom-select" [(ngModel)]="selectedYear" (ngModelChange)="validateDate()">
            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>
    </div> -->
    <form class="row row-cols-sm-auto">
        <div class="col-12">
            <div class="input-group">
                <input
                    class="form-control"
                    type="text"
                    name="date"
                    style="pointer-events: none;z-index: 2;height: 34px;"
                    placeholder="dd-mm-yyyy"
                    [ngModel]="disabled ? '' : date"
                />
                <input
                    class="form-contol"
                    placeholder="dd-mm-yyyy"
                    name="dp"
                    [displayMonths]="displayMonths"
                    [navigation]="navigation"
                    [outsideDays]="outsideDays"
                    [showWeekNumbers]="showWeekNumbers"
                    [disabled]="disabled"
                    [(ngModel)]="selectedDate"
                    (dateSelect)="validateDate($event)"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    style="pointer-events: none;position: absolute;visibility: hidden;bottom: 0;"
                />
                <button [disabled]="disabled" class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"
                style="padding: 0px 10px;border: 1px solid #ced4da;border-radius: 0px 5px 5px 0px;height: 34px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z"/>
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                      </svg>
                </button>
            </div>
        </div>
    </form>
</div>
