import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective {
  @Output() longPress = new EventEmitter();
  @Output() shortPress = new EventEmitter();
  private pressing!: boolean;
  private longPressing!: boolean;
  private timeout: any;

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMouseDown(event: MouseEvent | TouchEvent): void {
    this.pressing = true;
    this.longPressing = false;
    this.timeout = setTimeout(() => {
      if (this.pressing) {
        this.longPressing = true;
        this.longPress.emit(event);
      }
    }, 600); // Adjust time for long press detection (500ms)
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  @HostListener('touchend')
  @HostListener('touchcancel')
  onMouseUp(): void {
    if (this.longPressing) {
      this.longPressing = false;
    } else if (this.pressing) {
      this.shortPress.emit();
    }
    this.pressing = false;
    clearTimeout(this.timeout);
  }
}
