<div class="row">
    <div class="col-12 justify-content-center">
        <div class="header mt-5">
            <span>You have successfully <span class="mag">Logged In</span></span>
        </div>
        <!-- <div class="skip">
            <span ><small (click)="skip()"><a>skip</a></small></span>
        </div> -->
        <div class="text-center">
            <img src="assets/images/mail.gif" width="87"/>
        </div>
    </div>

    <div class="col-12 py-3 px-4" style="justify-content: center;display: flex;">
        <form (submit)="addEmail()">
            <div class="form-group">
                <input type="text" #emailInput name="email" class="form-control" placeholder="name@email.com" [(ngModel)]="userData.email"
                    (input)="emailOnlyValidator($event)" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
            </div>
            <div class="text-center">
                <input type="submit" class="btn btn-sm btn-primary main" [value]="'Submit'">
            </div>
        </form>
    </div>
    <div class="info">
        <p>
            Please provide your email for 
            latest offers & updates!
        </p>
        <div class="skip" (click)="skip()">Skip</div>
    </div>
</div>
