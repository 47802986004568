import { Injectable, SecurityContext } from '@angular/core';
import { DataService } from './data-service.service';
import { APIService } from './service/ApiService';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomUtils } from './service/customUtils';
import { ToastrService } from 'ngx-toastr';
import { SafeUrlPipe } from './service/SafeUrlPipe';
import Compressor from 'compressorjs';
import heic2any from 'heic2any';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  chunkSize = 1048576 * .7;//(MB)
  allowedExtension = ['jpeg', 'jpg', 'png', 'webp', 'heic', 'heif'];
  accepts = "image/*,heic";


  uploadedFiles = 1;
  totoalFiles = 1;

  files: Array<File> = [];

  uploadPending = false;

  uploading = false;

  finished = true;

  dragable = true;
  bookData;

  private imageSubject = new Subject<any>();


  constructor(private dataService: DataService, private service: APIService,
    private utils : CustomUtils, private toaster: ToastrService) 
  {
    this.accepts =  this.allowedExtension.map((x:string)=> "image/"+x).join(", ");
  }

  updateBooks(bookData:any) {
    let self = this;
    return new Promise((resolve)=> {
      self.service.updateBooks(cloneDeep(bookData)).then(x=> {
        if(x && x.data && x.success){
          self.dataService.setBookData(x.data);
          this.dragable = true;
        }
        resolve(x.success);
      });
    })
  }
  
  verifySelection(event: any, maxPages = this.utils.maxImages) {
    return new Promise<any>(async (resolve)=> {
      if(!event.target.files || !event.target.files.length){
        this.toaster.warning("Please select images first!")
        resolve(false);
      }
      const success = this.validateFile(event.target.files);
      if(success){
        const files = this.filterFiles(event.target.files);
        if(!(await this.vaidateCover(files))) {
          resolve(false);
        } else 
        resolve(files.slice(0, maxPages));
      }else {
        this.toaster.info("Please select image formats : ","."+this.allowedExtension.join("  ."))
        resolve(false);
      }
    });
  }

  validateFile(files:FileList):boolean {
    const names = Array.from(files).map(x=> x.name);
    return names.some((name:any)=>this.allowedExtension.includes(name.split('.').pop().toLowerCase()));
  }

  // fail safe cover image.
  async vaidateCover(files:Array<File>) {
    const isValid = await (async function () {
      for(let [index, file] of files.entries()) {
        console.log(index);
        const res =  await new Promise((resolve)=> {
          const img = new Image();
          img.onload = ()=> {
            URL.revokeObjectURL(img.src)
            resolve(true);
          }
          img.onerror = ()=> {
            URL.revokeObjectURL(img.src)
            resolve(false)
          }
          img.src = URL.createObjectURL(file);
        })
        if(res) return index;
      }
      return false;
    })();
    console.log(isValid);
    if(isValid === false) {
      this.toaster.warning("Invalid files selected!");
      return false;
    } else {
      const [file] = files.splice(isValid, 1);
      files.unshift(file);
    }
    return true;
  }

  filterFiles(files:FileList) {
    return Array.from(files).filter((file:any)=>this.allowedExtension.includes(file.name.split('.').pop().toLowerCase()))
    // .sort((a:any,b:any)=> b.lastModified - a.lastModified);
  }

}
