<div class="p-2">
    <div class="col-12 px-4 text-center uploader" *ngIf="handler.processing && dataService.getProgress() > 0 && dataService.getProgress() < 100">
        <div class="progress" style="height: 10px;">
            <div class="progress-bar color-primary" role="progressbar" [style]="'width: '+dataService.getProgress()+'%;'" [attr.aria-valuenow]="dataService.getProgress()" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div style="font-size: 12px; color: #5d5d5d;">
            Uploading ({{handler.processed}}/{{handler.total}})
        </div>
    </div>
    <div class="image-container">
        <div class="col-3 px-1 my-2 image">
            <div class="selected" style="aspect-ratio: 1; position: relative;">
                <button class="overlay add" style="border: none;"
                 [disabled]="handler.processing || images.length >= 350" (click)="uploadeInput.click()">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>
        <ng-container *ngFor="let img of images; let i = index">
            <div *ngIf="!usedIndex.includes(img.id)" class="col-3 px-1 my-2 image" (click)="selectImage(img.id)" [ngClass]="isSelected.includes(img.id) ? 'selected':''">
                <img [src]="img.src" crossOrigin="Anonymous" class="selectable-image">
                <div class="overlay" *ngIf="isSelected.includes(img.id)">
                    <i class="fas fa-check"></i>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="handler.processing">
            <div *ngFor="let img of getArray(handler.total);let i = index" class="col-3 px-1 my-2 image">
                <img [src]="imgUpload[i]||'assets/images/favicon.png'" class="selectable-image" style="opacity: 0.5;">
            </div>
           
        </ng-container>
        <!-- <ng-container *ngIf="!selectMultiple"> -->
            <span *ngIf="usedIndex.length" class="col-12 used">Photos In Use</span>
            <ng-container *ngFor="let img of images">
                <div *ngIf="usedIndex.includes(img.id) && imgIndex != img.id" class="col-3 px-1 my-2 image" (click)="selectImage(img.id)" [ngClass]="isSelected.includes(img.id) ? 'selected':''">
                    <img [src]="img.src" crossOrigin="Anonymous" class="selectable-image">
                    <div class="overlay" *ngIf="isSelected.includes(img.id)">
                        <i class="fas fa-check"></i>
                    </div>
                </div>
            </ng-container>
        <!-- </ng-container> -->
    </div>
    <div *ngIf="!selectMultiple && message" class="text-center w-100">
        <div class="form-check form-switch">
            <label for="titleSwitch">{{isDublicate ? 'Make' : 'No'}} Duplicate</label>
            <input class="form-check-input" [ngModel]="isDublicate" (ngModelChange)="dubToggle()"
                type="checkbox" role="switch" id="titleSwitch" [checked]="isDublicate">
        </div>
        <small class="text-primary ">*{{message}}</small>
    </div>
    <div class="row mt-4">
        <div class="col-6"><button class="btn btn-secondary" (click)="close()" [disabled]="handler.processing">Cancel</button></div>
        <div class="col-6 text-end"><button class="btn btn-primary" (click)="save()" [disabled]="!isSelected.length">{{type == 'collage' ? 'Select' : 'Save'}}</button></div>
    </div>
</div>
<input type="file" #uploadeInput id="file-input" [accept]="dataService.accepts" multiple (change)="uploadImage($event)" style="display: none;">
