<div class="row">
    <div class="col-12 justify-content-center text-center">
        <div *ngIf="imgPreviews.length; else noImg" class="w-100 p-2">
            <app-preview  [autoFlip]="true" [parent]="null" [_imgPreviews]="imgPreviews" [bookLogoColor]="bookData.metadata?.cover?.logoColor"
            [bookColor]="bookData.metadata?.cover?.color" [blur]="true"></app-preview>
        </div>
        <ng-template #noImg>
            <img *ngIf="isBook" class="imgPrev" style="object-fit: none;opacity: 0.4" width="200" height="200" src="assets/images/favicon.png" alt="">
        </ng-template>
    </div>

    <div class="header" [ngClass]="isBook ? '' : 'mt-5'">
        <span *ngIf="!verifyPage">Sign In to experience the <span class="mag">MAGIC!</span></span>
        <span *ngIf="verifyPage">Enter Your OTP</span>
    </div>

   <div class="card-box">
        <div class="text-center">
            <form *ngIf="!verifyPage" (submit)="login()">
                <div *ngIf="!phoneVerified" class="form-group text-center">
                    <div class="input-group flex-nowrap" style="border-radius: 5px;
                    border: 1px solid #C8C8C8;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);height: 45px;">
                        <span class="input-group-text" style="border: none; background: none;" id="addon-wrapping">+{{countryCode}}</span>
                        <svg style="margin: auto;
                        stroke-width: 0.8px;
                        stroke: #C8C7C7;" xmlns="http://www.w3.org/2000/svg" width="2" height="29" viewBox="0 0 2 29" fill="none">
                            <path d="M1 0V28.5" stroke="#C8C7C7" stroke-width="0.8"/>
                          </svg>
                        <input type="tel" style="border: none;" #phoneInput class="form-control" name="phone" placeholder="Enter Mobile number" aria-label="phone" (ngModelChange)="setPhone($event)" [ngModel]="userData.phone"
                            aria-describedby="addon-wrapping" (input)="numbersOnlyValidator($event)"/>
                        </div>
                </div>
                <div class="text-center">
                    <input type="submit" class="btn btn-sm btn-primary main" [value]="'Get OTP'">
                </div>
            </form>
            <div *ngIf="verifyPage" class="text-center" style="width: 50%;min-width: 290px;">
                <form (submit)="submitOtps()">
                    <div class="form-group">
                        <ng-otp-input id="email"  (onInputChange)="onOtpChange($event)"  [config]="{length:4,allowNumbersOnly:true,inputClass:'input-otp'}"></ng-otp-input>                        
                    </div>
                    <div className="countdown-text" style="padding-top: 20px;">
                        <small *ngIf="isResend" (click)="isResend && login()"><a>Resend OTP</a></small>
                        <small *ngIf="!isResend">Resend in: 0{{minutes}}:{{seconds < 10 ? 0 : ''}}{{seconds}}</small>
                    </div>
                    <div class="text-center">
                        <input type="submit" class="btn btn-sm btn-primary main" value="Submit">
                    </div>
                </form>
            </div>
        </div>
        
    </div>
    <div *ngIf="verifyPage" style="margin-top: 24px;text-align: center;">
        <div>+91 {{userData.phone}} &nbsp;
            <i (click)="changedata()" class="fa-solid fa-pencil" style="color: #85A4FF;"></i>
        </div>
    </div>
    <!-- <div *ngIf="!verifyPage" class="text-center sign-up">
        Don`t have an account ? <a routerLink="/sign-up">Sign Up</a> here.
    </div> -->
</div>