<div class="text-center">
  <h4 [innerHTML]="getSlides()?.text"></h4>
  <div class="carousel">
    <owl-carousel-o [options]="customOptions" style="height: 100%;">
      <ng-container *ngFor="let slide of getSlides().images; index as i">
        <ng-template carouselSlide [id]="i+'_t'">
          <img [src]="slide" style="height: 100%; padding: 20px; background-color: #fff;">
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>

  <div class="item-c d-flex mt-2" style="justify-content: space-around;">
    <div class="col-4">
      <div class="item">
        <img style="scale: 0.7;" src="assets/images/like.png">
      </div>
      <div class="text">Free Design</div>
      <div class="text">Assistance</div>
    </div>
    <div class="col-4">
      <div class="item">
        <img src="assets/images/free.svg">
      </div>
      <div class="text">Free</div>
      <div class="text">Shipping</div>
    </div>
    <div class="col-4">
      <div class="item">
        <img src="assets/images/pocket.png">
      </div>
      <div class="text">COD</div>
      <div class="text">Available</div>
    </div>
  </div>
  <div class="steps text-start px-4 py-3">
    <div class="head ml-4"> How it works </div>
    <ul style="padding-left: 1rem;">
        <li>Select 20+ photos </li>
        
        <li>Our Ai creates a perfect photobook for you</li>
        <li>
          Edit & Customise with ease
        </li>
        <li>
          Get hassle free delivery at your door step
        </li>
        <li>
          Delivery time 6-7 days
        </li>
    </ul>
  </div>
</div>
<div class="actionCta text-center">
  <input type="file" #uploader id="file-input" [accept]="accepts" multiple (input)="uploadFiles($event)"
    style="display: none;">
  <button class="btn btn-primary heart main" (click)="sendGae('click_createSelect');uploader.click()">
    let's start
  </button>
</div>
