import { Injectable } from '@angular/core';
import { DataService } from '../data-service.service';
import { ToastrService } from 'ngx-toastr';
import { PreviewService } from '../preview.service';

@Injectable({
    providedIn: 'root'
})

export class CustomUtils {

  monthNames = [ 
    { abbr: 'JAN', name: 'January'},
    { abbr: 'FEB', name: 'February'},
    { abbr: 'MAR', name: 'March'},
    { abbr: 'APR', name: 'April'},
    { abbr: 'MAY', name: 'May'},
    { abbr: 'JUN', name: 'June'},
    { abbr: 'JUL', name: 'July'},
    { abbr: 'AUG', name: 'August'},
    { abbr: 'SEP', name: 'September'},
    { abbr: 'OCT', name: 'October'},
    { abbr: 'NOV', name: 'November'},
    { abbr: 'DEC', name: 'December'}
  ];
  
  // static crrYear = new Date().getFullYear();

  years = new Array(50).fill(this.getYear()).map((x, i)=> x - i);
  
  colorData = [
    {"id":1,"bookColor":"#fff","titleColor":"#000","logoColor":"#8DA4EE","name": "Sparkling Snow"},
    {"id":2,"bookColor":"#8FBBD4","titleColor":"#000","logoColor":"#fff","name": "Serene Blue"},
    {"id":3,"bookColor":"#FFA3B7","titleColor":"#000","logoColor":"#fff","name": "Blushing Rose"},
    {"id":4,"bookColor":"#B9A2DF","titleColor":"#000","logoColor":"#fff","name": "Dreamy Lilac"}
  ];

  birthday = ['Happy 1st Birthday Lil One!','Our little munchkin...', 'Birthday Morning Charm !!', 'Happy Birthday (name)', 'Happy Birthday Little One', 'Happy 3rd Birthday Lil One!', 'Happy 2nd Birthday Lil One!'];
  pregnacny = ["Bump to Baby!","My Pregnancy Journey!","Our Baby Shower!","Baby Loading!"];
  firstyear = ["Our Little Miracle!","Baby Shoot of our Little One!","Our Little Explorer","Happy 1st Month!","Happy 2nd Month","Annaprasan","Welcome Home!","Naming Ceremony","Happy SIX months!"]
  travel = ['Maldives','Euro Trip','Summer in Shillong','Summer Vacation','Ooty Diaries','Goa','Summer Holidays']
  others = ["Our Beautiful Moments", "Cherished Memories","Precious Moments","Blissful Memories","Joyful Recollections","Heartwarming Moments","Treasured Memories","Enchanting Memories"]
  fathers = ["The world's best Dad!","Happy Father's Day Daddy","Best Papa in the whole world 🩷","My Papa Dearest!","Love you Nana!","Super Dad!"]

  types = [
    {name : "birthday", display : "Birthday", icon : ""},
    {name : "travel", display : "Our Vacation", icon : ""},
    {name : "pregnancy", display : "Mom & Dad to be", icon : ""},
  ]

  styles = ["full", "fit"]
  frames = [
    {"id":1, "name": "wb1", "titleLen": 15 },
    {"id":2, "name": "wb2", "titleLen": 30, "style": this.styles[1] }
  ]

  magnetFrames = [
    {"id":1, style:"full", "name": "mg1", "titleLen": 30 },
    // {"id":2, style:"fit", "name": "mg2", "title":"" }
  ]

  titleColors = [
    { id:1, color:"#000", name: "Black"},
    { id:2, color:"#fff", name: "White"}
  ]

  minImages = 20;
  minSelect = 20;
  maxImages = 75;
  minDpi:number;

  prevLd;
  prevPo;

  constructor(private dataService:DataService, private toaster:ToastrService, private smart: PreviewService){
    this.minDpi = this.smart.minDpi;
  }

  getMonthName() {
    return this.monthNames[new Date().getMonth()].name;
  }

  getYear() {
    return new Date().getFullYear();
  }

  getTitleArr(val):Array<any> {
    if(val?.includes("|"))
    val = val.split("|")[0];
    const d = Math.random() > 1/2 ? this.getYear() : '';
    switch(val) {
      case 'birthday' : return [this.birthday];
      case 'pregnancy' : return [this.pregnacny];
      case 'travel' : return [this.travel, d];
      case 'first-year' : return [this.firstyear];
      case 'firstyear' : return [this.firstyear];
      case 'fathers' : return [this.fathers];
      default : return [this.others, d];
    }
  }

  getCaptions(sku = null) {
    const arr = this.getTitleArr(sku||this.dataService.getSku())
    return arr[0];
  }

  getTitle() {
    const arr = this.getTitleArr(this.dataService.getSku())
    return arr[0][Math.floor(Math.random()*arr[0].length)]+(arr[1] ? (' '+arr[1]) : '');
  }

  getSpineTiltle() {
    return this.getMonthName() + " " + this.getYear();
  }

  getDate() {
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toLocaleDateString().replaceAll("/","-")

  }

  defaultBookData() {
    let color = this.colorData[0];
    const title = this.getTitle();
    return {"color": color.bookColor, "year":this.getYear(), "month":this.getMonthName(), title, "spineTitle": "",
      "metadata": { images: [], 
      cover: { "spineMonth": "", "spineDate": false, "titleDate": false, "titleColor": color.titleColor, "logoColor": color.logoColor,
        "color": color.bookColor, "year":this.getYear(), "month":this.getMonthName(), title, "spineTitle": "",
        "frame": this.frames[1], dob : this.getDate(), isDob: true, "titleStyle": "Ronda" } }
    };
  }

  private getFileTypeFromUrl(url) {
    const cleanUrl = url.split('?')[0];
    const fileType = cleanUrl.split('.').pop();
    return fileType.includes('/') ? 'webp' : fileType;
  }

  private async urlToFile(url, filename) {
    const response = await fetch(url);
    const type = this.getFileTypeFromUrl(url);
    const blob = await response.blob();
    const file = new File([blob], filename+"."+type, { type: "image/"+type });
    return file;
  }

  async newMagnetData(imgData:any, imageId:number, frame = 'mg1') {
    let self = this;
    const magnet = this.magnetFrames.find(x=> x.name == frame)
    return new Promise(async (resolve)=> {
      const data:any = {}
      const url = imgData.rawUrl;
      
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = async() => {
        let boost = null;
        if(imgData.metaData) {
          data["format"] = imgData.metaData.format;
          data["size"] = imgData.metaData.size;
          data["name"] = imgData.metaData.name;
          data["dpi"] = imgData.metaData.dpi;
          data["date"] = imgData.metaData.date;
          boost = imgData.metaData.boost ? typeof imgData.metaData.boost == 'string' ? JSON.parse(imgData.metaData.boost) : imgData.metaData.boost : null ;
        }
        const w = img.width;
        const h = img.height;
        data["id"] = imageId;
        data["width"] = img.width;
        data["height"] = img.height;
        if(!data["dpi"])
          data["dpi"] = self.calculateDpi(w, h);
        data["metadata"] = {};
        data["title"] = this.getTitle();
        data["titleStyle"] = "Ronda";
        data["date"] = this.getDate();
        data["isDob"] = true;
        data["type"] = "";
        data["metadata"]["style"] = 'magnet';
        data["metadata"]["frame"] = magnet;
        data["metadata"]['boost'] = boost;
        data["layout"] = w>h ? "landscape" : w == h ? "square" : "portrait";
        const result = await this.smart.newImagePreview(img, data);
        URL.revokeObjectURL(img.src);
        resolve(result);
      };
      img.onerror = async()=> {
        URL.revokeObjectURL(img.src);
        resolve(false);
      };
      img.src = url;
    })
 
  }

  async getUploadedImageMetadata(file:File|any, preview, index:number, imageId:number, style = null, isCover = false, coverData = {}, isSample = false, boost = null):Promise<Object|null> {
    let self = this;
    return new Promise(async (resolve)=> {
      const data:any = {}

      if(!(file instanceof File)) {
        data["format"] = file.metaData.format;
        data["size"] = file.metaData.size;
        data["name"] = file.metaData.name;
        data["dpi"] = file.metaData.dpi;
        data["date"] = file.metaData.date;
        file = file.rawUrl;
      } else {
        data["format"] = (file as File).name.split(".").pop();
        data["size"] = (file as File).size;
        data["name"] = (file as File).name;
        data["date"] = (file as File).lastModified;
      }
      
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = async() => {
        const w = img.width;
        const h = img.height;
        data["id"] = imageId;
        data["width"] = img.width;
        data["height"] = img.height;
        if(!data["dpi"])
          data["dpi"] = self.calculateDpi(w, h);
        data["metadata"] = {};
        data["metadata"]["style"] = 'full';
        data["metadata"]['boost'] = boost;
        isSample ? data["metadata"]["sample"] = true : null;
        if(isCover) {
          data["layout"] = w>h ? "landscape" : w == h ? "square" : "portrait";
          data["metadata"]["style"] = 'cover';
        } else if(w>h) {
          data["layout"] = "landscape";
          if(index % 2 == 0) {
            data["metadata"]["style"] = this.prevLd == 'fit' ? 'full' : 'fit';
            this.prevLd = data["metadata"]["style"];
          } else
          data["metadata"]["style"] = 'full';
        } else if(w<h) {
          data["layout"] = "portrait";
          if(index % 2 == 0) {
            data["metadata"]["style"] = this.prevPo == 'fit' ? 'full' : 'fit';
            this.prevPo = data["metadata"]["style"];
          } else
          data["metadata"]["style"] = 'full';
        } else {
          data["layout"] = "square";
          data["metadata"]["style"] = index % 2 == 0 ? 'smart' : 'full';
        }
        style ? data["metadata"]["style"] = style : null;
        const result = await this.smart.newImagePreview(img, data, isCover, coverData);
        URL.revokeObjectURL(img.src);
        resolve(result);
      };
      img.onerror = async()=> {
        URL.revokeObjectURL(img.src);
        resolve(false);
      };
      img.src = preview ? preview : file instanceof File ? URL.createObjectURL(file) : file;
    })
  }

  private calculateDpi(w:number, h:number):number {
    let dpi = 0;
    dpi = ((w/8)+(h/8))/2
    return Math.round(dpi);
  }
    
}