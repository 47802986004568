import { Component, Input, ViewChild, Renderer2, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortableOptions } from 'sortablejs';
import heic2any from 'heic2any';
import { CoverComponent } from '../cover/cover.component';
import { Subscription } from 'rxjs';
import { SelectPhotosComponent } from '../select-photos/select-photos.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {

  inEdit = false;
  inEditor = false;
  isDropdownOpen = false;

  isAdd = true;

    dragId = 0;
  fileIndex:number = -1;

  maxLimit = 0;
  sortOptions : SortableOptions= {
    animation: 400,
    delayOnTouchOnly: true,
	  touchStartThreshold: 4,
    draggable: '.grids',
    filter: '.static',
    delay: 100,
    onUpdate: (event: any) => {
      this.onDrop(event);
    },
    onMove: (event: any) => {
      // this.dragId = event.related.id.slice(1)
      return !(event.dragged.id == "d0" || event.related.id == "d0") //
    },
    onEnd: (/**Event*/evt) => {
      this.dragId = 0;
    },
    // onClone: function (/**Event*/evt) {
      //   var origEl = evt.item;
    //   var cloneEl = evt.clone;
    // },
  }
  
  accepts;
  tooltips = ["Drag and Drop images to rearrange pages!", "Tap to edit your photobook pages!"]
  tips = ""

  @Input()
  parent:CoverComponent;

  @Input()
  bookData:any = {};

  @Input()
  imgPreviews:Array<any> = [];

  @ViewChild("pop")
  pop : any;
  @ViewChild("popImg")
  popImg : any;

  @ViewChild('dropdownMenu') 
  dropdownMenu!: ElementRef;

  @Input()
  inSort = false;

  private subscribe!:Subscription;
  private modalSubscription!: Subscription;
  private modalRef;


  constructor(parent: CoverComponent, private customModal:NgbModal ) {
    this.parent = parent;
    // this.bookData = this.parent.dataService.getBookData();
    this.accepts = this.parent.uploader.accepts;
    this.maxLimit = this.parent.utils.maxImages;
  }

  ngOnChanges() {
    if(this.bookData.metadata)
      this.inEdit = true;
  }

  showPreview() {
    this.parent.gtm.event('click_previewE', {});
    this.parent.inPreview = true;
    this.parent.router.navigate(["flipbook", this.parent.dataService.bookId]);;
  }

  editCover() {
    this.parent.gtm.event('click_editCover', {});
    this.parent.router.navigate(['cover'], {relativeTo:this.parent.route})
  }

  selectImageEdit(index:number) {
    if(this.parent.getFailed()) {
      this.parent.checkFailedImages();
      return;
    }
    this.parent.gtm.event('click_editPage'+index, {});
    this.parent.dataService.editIndex = index;
    this.parent.inFlipBook = !this.parent.inFlipBook;
    this.parent.router.navigate(['page'], {relativeTo:this.parent.route})
    // this.inEditor = true
    // this.inEdit = this.inEditor;
  }

  setIndexMap() {
    console.log(this.bookData.metadata.images.map(x=> x.id));
  }

  async onDrop(event: any) {
    this.parent.gtm.event('click_sort', {});
    this.parent.uploader.dragable = false;
    const oldIndex = event.oldIndex;
    const newIndex = event.newIndex;

    const bookData = cloneDeep(this.bookData)
    bookData.metadata.images = this.reorderArray(bookData.metadata.images, oldIndex, newIndex);
    const success = await this.parent.uploader.updateBooks(bookData);
    if(success) {
      this.bookData = bookData;
      this.parent.dataService.setBookData(this.bookData);
      this.parent.imgPreviews = this.imgPreviews;
      this.parent.bookData = this.bookData;
      this.parent.imgNameMap  = this.reorderArray(this.parent.imgNameMap, oldIndex, newIndex);
    } else {
      this.imgPreviews = this.reorderArray(this.imgPreviews, newIndex, oldIndex);
    }
  }

  reorderArray(arr: any[], oldIndex: number, newIndex: number) {
    const movedItem = arr.splice(oldIndex, 1)[0];
    arr.splice(newIndex, 0, movedItem);
    return arr;
  }

  async uploadFiles(event:any) {
    this.isAdd = false;
    await this.parent.uploadFiles(event, true);
    //clear to detect file change
    if(document.getElementById('file-input')) {
      // @ts-ignore
      document.getElementById('file-input').value = '';
    }
    this.isAdd = true;
  }


  blobToFile (blob: Blob, file:File) {
    return new Promise<any>(async (resolve)=> {
      let tBlob = await heic2any({blob,toType:"image/jpeg",quality:0.8});
      let b: any = tBlob;
      b.name = file.name.split(".")[0]+".jpeg";
      resolve(<File>tBlob);
    })
  }

  toggleDropdown(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const dropdownElement = this.dropdownMenu.nativeElement;
    dropdownElement.classList.toggle('show');
  }

  showUploadedPhotos() {
    this.modalRef = this.customModal.open(SelectPhotosComponent,
      {
        centered: true, backdrop: 'static', keyboard: false
      }
    );
    this.modalRef.componentInstance.images = this.getRawImages();
    const usedIndex = this.parent.bookData.metadata.images.map(x=> x.metadata.style == 'collage' ? x.metadata.collage.metadata.images.map(y=> y.id) : x.id).flat()
    this.modalRef.componentInstance.usedIndex = usedIndex;
    this.modalRef.componentInstance.selectMultiple = true;
    this.modalSubscription = this.modalRef.componentInstance.actionConfirmed.subscribe((data: any) => {
      if(data.type == 'close')
        this.closeModalFromParent();
      else if(data.type == 'selected')
        this.getImageSelection(data.value);
      else if(data.type == 'save')
        this.setImageSelection(data.value);
      else if(data.type == 'upload')
        this.uploadFilesSelect(data.value);
    });
  }

  getRawImages() {
    const images:any = [];
    Array.from(this.parent.originalUrls.keys()).forEach(x=> {
      // if(x != 0 && this.renderMeta.id != x) {
        const d = this.parent.originalUrls.get(x);
        images.push({id:x, src: d.previewUrl});
      // }
    })
    return images;
  }

  getImageSelection(value:any) {
    const index = this.parent.findAllIndex(this.parent.bookData.metadata.images, (item) => value.id.includes(item.id));
    if(index.length) {
      let i = this.parent.bookData.metadata.images.length;
      let ind = index.map(x=> i++);
      this.modalRef.componentInstance.message = value.isDub ? `Photo will be same for Page ${ind} and Page ${index.join(', ')}` : 
      `Photo will be moved from Page ${index.join(', ')} to Page ${ind}`
    } else
    this.modalRef.componentInstance.message = "";
  }

  async setImageSelection(value:any) {
    await this.parent.addImages(this.parent.bookData.metadata.images.length, value.id, value.isDub);
    this.ngOnChanges();
    this.closeModalFromParent();
  }

  async uploadFilesSelect(event:any) {
    await this.parent.uploadFiles(event, false);
    this.modalRef.componentInstance.images = this.getRawImages();
  }

  closeModalFromParent() {
    this.unsubscribeModal();
    if (this.modalRef) {
      this.modalRef.close('Closing from parent');
    }
  }

  unsubscribeModal() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  onLongPress() {
    if(!this.inSort) {
      this.inSort = true;
    }
    this.parent.inSort = this.inSort;
  }
}
