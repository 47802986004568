<div class="row">
    <div class="col px-4 text-center">
        <div class="progress" style="height: 10px;">
            <div class="progress-bar color-primary" role="progressbar" [style]="'width: '+dataService.getProgress()+'%;'" [attr.aria-valuenow]="dataService.getProgress()" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <p>{{dataService.getProgress()}}% Completed</p>
    </div>
</div>

<div class="row">
    <div class="col px-5">
        <div class="card">
            <div class="card-body">
                <div class="row pb-4 pt-3">
                    <div class="col-3"><i class="fa fa-circle-check" [ngClass]="step >= 1 ? 'fa-duotone' : 'fa-regular'"></i>
                        <!-- <div class="line"></div> -->
                    </div>
                    <div class="col-9">Uploading Images</div>
                </div>

                <div class="row pb-4">
                    <div class="col-3"><i class="fa fa-circle-check" [ngClass]="step >= 2 ? 'fa-duotone' : 'fa-regular'"></i></div>
                    <div class="col-9">Creating a beautiful story</div>
                </div>

                <div class="row pb-4">
                    <div class="col-3"><i class="fa fa-circle-check" [ngClass]="step >= 3 ? 'fa-duotone' : 'fa-regular'"></i></div>
                    <div class="col-9">Auto layouting and cropping</div>
                </div>

                <div class="row pb-4">
                    <div class="col-3"><i class="fa fa-circle-check" [ngClass]="step >= 4 ? 'fa-duotone' : 'fa-regular'"></i></div>
                    <div class="col-9">Applying final touches</div>
                </div>

                <div class="row pb-3">
                    <div class="col-3"><i class="fa fa-circle-check" [ngClass]="step >= 5 ? 'fa-duotone' : 'fa-regular'"></i></div>
                    <div class="col-9">Your Whitebook is ready</div>
                </div>
            </div>
        </div>
    </div>
</div>