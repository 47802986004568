import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { UploaderComponent } from "./uploader/uploader.component";
import { EditComponent } from "./edit/edit.component";
import { LoginComponent } from "./login/login.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { AddressComponent } from "./address/address.component";
import { BillingComponent } from "./billing/billing.component";
import { PaymentComponent } from "./payment/payment.component";
import { SuccessComponent } from "./success/success.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { OrdersComponent } from "./orders/orders.component";
import { EditCoverComponent } from "./edit-cover/edit-cover.component";
import { AuthGurad } from "./service/AuthGuard";
import { CoverComponent } from "./cover/cover.component";
import { PagesComponent } from "./pages/pages.component";
import { SignupComponent } from "./signup/signup.component";
// import { AuthMatch } from "./service/AuthMatch";
import { PageGuard } from "./service/PageGuard";

const title = "Whitebook - ";

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home',
    },
    {
        path: 'home',
        component: HomeComponent,
        title: title+'Create',
    },
    {
        path: 'gallery',
        component: GalleryComponent,
        title: title+'Gallery',
    },
    {
        path: 'upload',
        canActivate: [AuthGurad],
        component: UploaderComponent,
        title: title+'Upload',
    },
    {
        path: 'login',
        component: LoginComponent,
        title: title+'Login'
    },
    {   
        path: 'signup',
        canActivate: [AuthGurad],
        // canMatch: [AuthMatch],
        component: SignupComponent,
        title: title+'Login Success'
    },
    // {
    //     path: 'flipbookold/:id', component: FlipbookComponent,
    //     title: title+'FlipBook',
    //     canActivate: [AuthGurad],
    //     children: [
    //         {   
    //             path: 'edit', component: EditComponent,
    //             title: title+'Edit Book',
    //             children: [
    //                 { path: 'cover', component: EditCoverComponent, title: title+'Edit Cover' },
    //                 { path: 'page', component: EditPageComponent, title: title+'Edit Page' }
    //             ]
    //         },
    //     ]
    // },
    {
        path: 'flipbook/:id', component: CoverComponent,
        title: title+'FlipBook',
        canActivate: [AuthGurad],
        children: [
            { path: 'page', component: PagesComponent, title: title+'Edit Page', canActivate: [PageGuard] },
            { path: 'cover', component: EditCoverComponent, title: title+'Edit Cover', canActivate: [PageGuard] }
        ]
    },
    {
        path: 'checkout/:id', component: CheckoutComponent,
        title: title+'Checkout',
        canActivate: [AuthGurad],
        children: [
            { path: 'address', component: AddressComponent, title: title+'Address' },
            { path: 'billing', component: BillingComponent, title: title+'Billing' },
            { path: 'payment', component: PaymentComponent, title: title+'Payment' }
        ]
    },
    {
        path: 'success',
        canActivate: [AuthGurad],
        // canMatch: [AuthMatch],
        component: SuccessComponent,
        // canActivate: [AuthSuccess],
        title: title+'Success'
    },
    {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [AuthGurad],
        title: title+'Orders'
    }
]
export const AppRoutingModule = RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' });
