import { Component, ElementRef, Input, Output, ViewChild, EventEmitter, OnChanges, IterableDiffer } from '@angular/core';
import { cloneDeep } from 'lodash';
import Croppie from 'src/assets/js/croppie';

@Component({
  selector: 'app-croppie',
  templateUrl: './croppie.component.html',
  styleUrls: ['./croppie.component.scss']
})
export class CroppieComponent {

  @ViewChild('croppieContainer') croppieContainer!: ElementRef;

  private strDiffer!: IterableDiffer<String>;


  @Input()
  imgData;

  @Input()
  imgOrgUrl;

  @Input()
  cropStyle;

  @Output()
  cropper = new EventEmitter();

  croppie;
  loading= true;

  croppieRotateMap = {90: 6, 180: 3, 270: 8}

  ngOnChanges() {
    this.ngAfterViewInit()
  }

  ngAfterViewInit() {
    if(!this.croppieContainer) return;
    this.loading = true;
    const container = this.croppieContainer.nativeElement;
    container.addEventListener("update", (e) => this.getCropper(e.detail));
    const viewport = { width: 90, height: 90 };
    const boundary = { width: 100, height: 100 };
    const enableResize = false && this.imgData.metadata.style == "original";

    if(this.imgData.metadata.style == 'cover' && this.imgData.frame.style == 'fit') {
      viewport.width = 70;
      viewport.height = 55;
    } else if(this.imgData.metadata.style == 'magnet' && this.imgData.metadata.frame.style == 'fit') {
      viewport.width = 55;
      viewport.height = 70;
    } else if(this.cropStyle) {
      switch(this.cropStyle) {
        case 'fit43' : {
          viewport.width = 90;
          viewport.height = 67.5;
        }
        break;
        default : {
          viewport.width = 67.5;
          viewport.height = 90;
        } 
      }
    } else if(this.imgData.metadata.style == "fit") {
      if(this.imgData.layout == "portrait" || this.imgData.layout == "square") {
        viewport.width = 67.5;
        viewport.height = 90;
      } else if(this.imgData.layout == "landscape") {
        viewport.width = 90;
        viewport.height = 67.5;
      }
    }
    if(this.imgData.metadata.style == "original") {
      if(this.imgData.width > this.imgData.height) {
        viewport.width = 90;
        viewport.height = this.getPercentDiff(this.imgData.width, this.imgData.height, 90);
      } else {
        viewport.width = this.getPercentDiff(this.imgData.height, this.imgData.width, 90);
        viewport.height = 90
      }
    }
    if(this.imgData.metadata.rotate == 90 || this.imgData.metadata.rotate == 270) {
      let c = viewport.width;
      viewport.width = viewport.height;
      viewport.height = c;
    }
    if(this.croppie) 
      this.croppie.destroy();
    this.croppie = new Croppie(container, {
      viewport,
      boundary,
      enableResize,
      enableOrientation: this.imgData.metadata.rotate>0||false,
    });
    const options = {
      url: this.imgOrgUrl.rawUrl
    }
    let crop = cloneDeep(this.imgData.metadata.crop);
    if(this.imgData.metadata.crop) {
      if(this.imgData.metadata.rotate) {
        crop = this.rotateCropCoordinates(this.imgData.width, this.imgData.height, crop.sx, crop.sy, crop.sw, crop.sy, this.imgData.metadata.rotate)
        crop = {sx : crop[0], sy: crop[1], sw: crop[2], sh: crop[3]}
      }
      options["points"] = [ crop.sx,crop.sy, crop.sx+crop.sw, crop.sy+crop.sh ];
    }
    if(this.imgData.metadata.rotate) {
      options["orientation"] = this.croppieRotateMap[this.imgData.metadata.rotate];
    }
    console.log(options);
    
    this.croppie.bind(options);
  }

  rotateCropCoordinates(imgWidth, imgHeight, sx, sy, dx, dy, rotationDegree) {
    let newSx, newSy, newDx, newDy;
    if (rotationDegree === 90) {
        newSx = imgHeight - sy - dy;
        newSy = sx;
        newDx = dy;
        newDy = dx;
    } else if (rotationDegree === 180) {
        newSx = imgWidth - sx - dx;
        newSy = imgHeight - sy - dy;
        newDx = dx;
        newDy = dy;
    } else if (rotationDegree === 270) {
        newSx = sy;
        newSy = imgWidth - sx - dx;
        newDx = dy;
        newDy = dx;
    } else {
        // If the rotation degree is not 90, 180, or 270, we assume no rotation
        newSx = sx;
        newSy = sy;
        newDx = dx;
        newDy = dy;
    }
    return [newSx, newSy, newDx, newDy ];
}

  getPercentDiff(v1,v2, per) {
    return (v1-v2)/((v1+v2)/2) * per;
  }

  getCropper(data:any) {
    this.loading = false;
    this.cropper.emit(this.convertCoordinates(data.points))
  }

  convertCoordinates(data) {
    const crop = {sx:+data[0], sy:+data[1], sw: data[2] - data[0], sh: data[3] - data[1]};
    return this.reverseRotateCropCoordinates(this.imgData.width, this.imgData.height, crop.sx, crop.sy, crop.sw, crop.sh, this.imgData.metadata.rotate)
  }

  reverseRotateCropCoordinates(imgWidth, imgHeight, sx, sy, dx, dy, rotationDegree) {
    if(rotationDegree == 90 || rotationDegree == 270) {
      let c = imgWidth;
      imgWidth = imgHeight;
      imgHeight = c;
    }
    let originalSx, originalSy, originalDx, originalDy;

    if (rotationDegree === 90) {
      originalSx = sy;
      originalSy = imgWidth - sx - dx;
      originalDx = dy;
      originalDy = dx;
    } else if (rotationDegree === 180) {
      originalSx = imgWidth - sx - dx;
      originalSy = imgHeight - sy - dy;
      originalDx = dx;
      originalDy = dy;
    } else if (rotationDegree === 270) {
      originalSx = imgHeight - sy - dy;
      originalSy = sx;
      originalDx = dy;
      originalDy = dx;
    } else {
      originalSx = sx;
      originalSy = sy;
      originalDx = dx;
      originalDy = dy;
    }
  return {sx: originalSx, sy:originalSy, sw:originalDx, sh:originalDy };
}

  rotate(degree) {
    // this.croppie.rotate(degree);
    // this.getCropper();
  }

  ngOnDestroy() {
    window.removeEventListener("update", this.getCropper);
  }

}
