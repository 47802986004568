import { Component } from '@angular/core';
import { DataService } from '../data-service.service';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Router } from '@angular/router';
import { EditComponent } from '../edit/edit.component';
import { Subject, Subscription, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { cloneDeep } from 'lodash';
import { CoverComponent } from '../cover/cover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectPhotosComponent } from '../select-photos/select-photos.component';

@Component({
  selector: 'app-edit-cover',
  templateUrl: './edit-cover.component.html',
  styleUrls: ['./edit-cover.component.scss']
})
export class EditCoverComponent {

  renderImage: any;
  renderMeta:any;
  renderCover:any;
  
  currentImg = 0;

  inCrop = false;
  cropReady = false;
  inEdit = false;
  showCropper = false;


  txtQueryChanged: Subject<string> = new Subject<string>();

  private subscribe!:Subscription;
  private modalSubscription!: Subscription;
  private modalRef;
  
  constructor(private router: Router, public parent: CoverComponent,
    private customModal:NgbModal){
    this.txtQueryChanged.pipe(debounceTime(500),
      distinctUntilChanged()
    ).subscribe(model => {
      this.parseImage();
    });
  }

  ngOnInit() {
    this.renderMeta = cloneDeep(this.parent.bookData.metadata.images[this.currentImg]);
    this.renderCover = cloneDeep(this.parent.bookData.metadata.cover);
    this.renderImage = cloneDeep(this.parent.imgPreviews[this.currentImg]);
    this.parseImage();
  }

  updateCaption(val:string) {
    this.renderMeta.metadata.text = val;
    this.parseImage();
  }

  getColorData() {
    return this.parent.dataService.colorData;
  }

  changeColor(id:any) {
    this.parent.gtm.event('click_colorCover', {});
    let color = this.parent.dataService.colorData.find(x=> x.id == id);
    if(color) {
      this.renderCover["color"] = color.bookColor;
      this.renderCover["titleColor"] = color.titleColor;
      this.renderCover["logoColor"] = color.logoColor;
    }
    this.parseImage()
  }

  async changeFrame(ind:number) {
    const frame =  this.parent.frames[ind];
    let rebuild = true;
    if(this.renderCover["frame"].name == 'wb1' && frame.style == 'full')
      rebuild = false;
    else if(this.renderCover["frame"].style == 'full' && frame.name == 'wb1')
      rebuild = false;

    this.renderCover["frame"] = frame;
    if(this.renderCover["frame"].name == 'wb2') {
      this.renderCover["title"] = this.parent.utils.getTitle();
      this.renderCover.titleStyle = ind ? "Ronda" : "Carena";
    } else {
      this.renderCover["title"] = this.parent.utils.getSpineTiltle();
      this.renderCover.titleStyle = 'Nunito';
    }
    this.renderCover["title"] = this.renderCover["title"].slice(0, this.parent.frames[ind].titleLen).trim();
    if(rebuild)
      await this.rebuildCover();
  }

  rebuildCover() {
    // this.loading = true;
    return new Promise(async resolve=> {
      const data = this.parent.originalUrls.get(this.renderMeta.id);
      this.renderMeta = await this.parent.preview.newImagePreview(data.rawUrl, Object.assign({}, this.renderMeta, this.renderCover));
      this.renderImage = this.renderMeta.src;
      // this.loading = false;
      resolve(true);
    })
  }


  changeTitle(val:string) {
    this.renderCover["title"] = val;
    this.parseImage();
  }

  changeSpineTitle(val:string) {
    this.renderCover["spineTitle"] = val;
    this.parseImage();
  }

  onBlur() {
    const val = this.renderCover["title"];
    this.renderCover["title"] = val?.trim()?.slice(0, this.renderCover.frame.titleLen);
    this.parseImage();
  }

  onSpineBlur() {
    const val = this.renderCover["spineTitle"];
    this.renderCover["spineTitle"] = val?.trim()?.slice(0, 20);
    this.parseImage();
  }

  changeTileColor(color) {
    this.renderCover["titleColor"] = color;
    this.parseImage();
  }

  changeFont(font:string) {
    this.renderCover.titleStyle = font;
    this.parseImage()
  }

  async changeLayout(ind:number) {
    if(this.renderCover["frame"].name == 'wb2') {
      this.renderCover["frame"]["style"] = this.parent.styles[ind];
      await this.rebuildCover();
    }
  }

  changeIsDob() {
    this.renderCover.isDob = !this.renderCover.isDob;
    this.parseImage()
  }

  changeDob(date:string) {
    this.renderCover["dob"] = date;
    this.parseImage()
  }

  async parseImage() {
    return new Promise(async (resolve)=> {
      const imgMeta = Object.assign({}, this.renderMeta, this.renderCover);
      const result = await await this.parent.preview.genImagePreview(this.parent.originalUrls, imgMeta, 0.9, 'base64', 'jpeg', false, false);
      this.renderImage = result;
      resolve(result);
    })
  }

  async saveAndNext() {
    this.parent.gtm.event('click_saveCover', {});
    if(!this.renderCover.title || this.renderCover.title.trim() == "") {
      this.parent.toaster.warning("Please add Book Title");
      return;
    }
    const bookData =  cloneDeep(this.parent.bookData);
    // const imgIndex = this.renderMeta.id;
    // this.parent.dataService.setPreview(this.renderImage, this.currentImg);
    this.parent.imgPreviews[this.currentImg] = this.renderImage;
    this.parent.dataService.setCover(this.renderImage);
    bookData.metadata.cover = this.renderCover;
    bookData.metadata.images[this.currentImg] = this.renderMeta;
    bookData['color'] = this.renderCover.color;
    bookData['title'] = this.renderCover.title;
    bookData['spineTitle'] = this.renderCover.spineTitle;

    this.updateBook(bookData);
  }

  updateBook(bookData) {
    this.parent.service.updateBooks(bookData).then(x=> {
      if(x && x.data){
        this.parent.dataService.setLoader(false);
        this.parent.dataService.setBookData(x.data);
        this.parent.bookData = this.parent.dataService.getBookData();
        this.parent.inFlipBook = !this.parent.inFlipBook;
        this.router.navigate(["flipbook", this.parent.dataService.bookId]);
      }
    });
  }

  edit() {
    this.parent.gtm.event('click_editC', {});
    this.inEdit = !this.inEdit;
    // this.renderFile = this.originalUrls.get(this.currentImg);
  }

  getCropData(crop) {
    // const crop = {sx:+data[0], sy:+data[1], sw: data[2] - data[0], sh: data[3] - data[1]};
    this.renderMeta.metadata["crop"] = crop;
  }

  crop() {
    this.parent.gtm.event(this.inCrop ? 'click_cropCancel' : 'click_crop', {});
    if(this.inCrop) {
      this.renderMeta.metadata["crop"] = cloneDeep(this.parent.bookData.metadata.images[this.currentImg]).metadata?.crop;
    }
    this.inCrop = !this.inCrop;
  }

  cropImage() {
    this.parent.gtm.event('click_cropConfirm', {});
    this.inCrop = false;
    this.parseImage();
  }

  imageLoaded(event:any) {
    this.renderImage = event.transformed.base64;
    this.showCropper = true;
    // console.log(event);
  }

  imageCropped(event: any) {
    this.renderMeta["metadata"]["crop"] = {}
    this.renderMeta["metadata"]["crop"]["x1"] = event.crop.x;
    this.renderMeta["metadata"]["crop"]["y1"] = event.crop.y;
    this.renderMeta["metadata"]["crop"]["w"] = event.crop.width;
    this.renderMeta["metadata"]["crop"]["h"] = event.crop.height;
    this.cropImage()
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
    this.cropReady = true;
  }
  
  loadImageFailed() {
    console.error('Load failed');
  }

  rotateRight() {
    this.parent.gtm.event('click_rotate', {});
    let r = this.renderMeta["metadata"]["rotate"]||0;
    r+=90;
    r = r == 360 ? 0 : r;
    this.renderMeta["metadata"]["rotate"] = r;
    this.parseImage();
  }

  resetImage() {
    this.parent.gtm.event('click_reset', {});
    this.ngOnInit();
    this.parseImage();
  }

  toggleSpineDate() {
    this.parent.gtm.event('click_spineDate', {});
    this.renderCover.spineDate = !this.renderCover.spineDate;
    if(this.renderCover.spineDate) {
      this.renderCover.spineMonth = this.parent.utils.getSpineTiltle();
    } else {
      this.renderCover.spineMonth = ""; 
    }
  }

  toggleTitleDate() {
    this.parent.gtm.event('click_titleDate', {});
    this.renderCover.titleDate = !this.renderCover.titleDate;
    if(this.renderCover.titleDate) {
      this.renderCover.title = this.renderCover.spineDate ? 
      this.renderCover.spineMonth : this.parent.utils.getSpineTiltle();
      this.parseImage();
    } else {
      this.renderCover.title = ""; 
    }
    // this.bookDataCp.title = this.renderCover.title;
  }

  changeSpineMonth(event:any, index:number) {
    if(this.renderCover.spineDate) {
      let res:Array<any> =  this.renderCover.spineMonth.split(" ");
      res[index] = event.target.value;
      this.renderCover.spineMonth = res.join(" ");
    }
    if(this.renderCover.titleDate) {
      let res:Array<any> =  this.renderCover.title.split(" ");
      res[index] = event.target.value;
      this.renderCover.title = res.join(" ");
      // this.bookDataCp.title = res.join(" ");
      this.parseImage();
    }
  }


  openModal() {
    this.modalRef = this.customModal.open(SelectPhotosComponent,
      {
        centered: true, backdrop: 'static', keyboard: false
      }
    );
    this.modalRef.componentInstance.images = this.getRawImages();
    const usedIndex = this.parent.bookData.metadata.images.map(x=> x.metadata.style == 'collage' ? x.metadata.collage.metadata.images.map(y=> y.id) : x.id).flat()
    this.modalRef.componentInstance.usedIndex = usedIndex;
    this.modalRef.componentInstance.imgIndex = this.renderMeta.id;
    this.modalSubscription = this.modalRef.componentInstance.actionConfirmed.subscribe((data: any) => {
      if(data.type == 'close')
        this.closeModalFromParent();
      else if(data.type == 'selected')
        this.getImageSelection(data.value);
      else if(data.type == 'save')
        this.setImageSelection(data.value);
      else if(data.type == 'upload')
        this.uploadFiles(data.value);
    });
  }

  getRawImages() {
    const images:any = [];
    Array.from(this.parent.originalUrls.keys()).forEach(x=> {
      // if(x != 0 && this.renderMeta.id != x) {
        const d = this.parent.originalUrls.get(x);
        images.push({id:x, src: d.previewUrl});
      // }
    })
    return images;
  }

  getImageSelection(value:any) {
    const index = this.parent.findAllIndex(this.parent.bookData.metadata.images, (item) => item.id === value.id);
    if(index.length) {
      this.modalRef.componentInstance.message = value.isDub ? `Cover will be same for Page ${index.join(', ')}` : 
      ``
    } else
    this.modalRef.componentInstance.message = "";
  }

  async setImageSelection(value:any) {
    // value.isDub true to not remove page
    await this.parent.addImage(this.currentImg, value.id, true, true);
    this.renderImage = '';
    this.ngOnInit();
    this.closeModalFromParent();
  }

  async uploadFiles(event:any) {
    await this.parent.uploadFiles(event, false);
    this.modalRef.componentInstance.images = this.getRawImages();
  }

  closeModalFromParent() {
    this.unsubscribeModal();
    if (this.modalRef) {
      this.modalRef.close('Closing from parent');
    }
  }

  unsubscribeModal() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
  
  backToAlbum() {
    this.parent.gtm.event('click_backCover', {});
    this.parent.inFlipBook = !this.parent.inFlipBook;
    this.router.navigate(["flipbook", this.parent.dataService.bookId])
  }

  ngOnDestroy() {
    this.subscribe?.unsubscribe();
    this.unsubscribeModal();
  }

}
