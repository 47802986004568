<div class="text-center">
    <div class="card">
        <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
          <i class="checkmark">✓</i>
        </div>
          <h1>Success</h1> 
          <p>We received your purchase request<br/> we'll be in touch shortly!</p>
    </div>
    <div class="row redirect">
        <p>Redirecting you to order page {{time}}...</p>
        <a class="" style="text-decoration: underline;" (click)="redirect()">redirect now</a>
        <div class="col">
            
        </div>
    </div>
</div>