import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data-service.service';
import { environment } from 'src/environments/environment';
import { GtmService } from '../gtm.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent {

  time = 5;

  constructor(private router: Router, private dataservice: DataService, private gtm: GtmService){}

  ngOnInit() {
    this.gtm.event('click_success', {});
    this.gtm.sendConversion('conversion', {
      'send_to': 'AW-11124049813/cuSfCPixxpQZEJWPrrgp',
      'value': 1.0,
      'currency': 'INR',
      'transaction_id': this.dataservice.bookId||''
    });
    // this.gtm.trackEvent("Purchase", {
    //   currency: 'INR',
    //   value: 2000.00,
    // })
    this.gtm.trackEvent("Purchase", {
      currency: 'INR',
      value: 2000.00,
      external_id: this.dataservice.accId,
    })
    const int = setInterval(()=> {
      --this.time;
      if(this.time == 1) {
        clearInterval(int);
        this.dataservice.orderType = 'transit'
        this.redirect();
      }
    },1000)
  }


  redirect() {
    this.router.navigate(["orders"])
  }

}
