<div class="demo-boo " (click)="stop()" [ngClass]="isLandscape ? '' : 'landscape-mode'">
    <div #flipBook id="flip-book" class="mx-auto col">
        <ng-container *ngFor="let img of imgPreviews;index as i">
            <ng-container *ngIf="i == 1">
                <div class="flip-my-page demo-item">
                    <div class="page-content">
                        <!-- <img class="imgPrev" [src]="img | safeUrl" alt=""> -->
                    </div>
                </div>
            </ng-container>
            <div [id]="i" class="flip-my-page demo-item">
                <div class="page-content" [ngStyle]="{'background-color':i == 0 ? bookColor : ''}">
                    <div *ngIf="i == 0" class="cover-rim" [ngClass]="'e'+bookColor?.slice(1)"></div>
                    <img [ngStyle]="{'border-radius': i == 0 ? '3px' : '', 'filter' : blur ? 'blur(1px)' : ''}" class="imgPrev" [src]="img | safeUrl" alt="">
                </div>
            </div>
            <ng-container *ngIf="i == imgPreviews.length-1">
                <!-- <ng-container *ngIf="imgPreviews.length % 2 == 0" class="list-item col-6 col-md-4 col-lg-4 col-xl-3 static">
                    <div class="flip-my-page demo-item">
                        <div class="">
                            <div style="margin-top: 40%;">
                                <p class="text-warning mt-5">You got a free space to add 1 more photo.</p>
                            </div>                       
                        </div>
                    </div>
                </ng-container> -->
                <div *ngIf="imgPreviews.length % 2 != 0" class="flip-my-page demo-item">
                    <div class="page-content">
                        <!-- <img class="imgPrev" [src]="img | safeUrl" alt=""> -->
                    </div>
                </div>
                <div class="flip-my-page demo-item">
                    <div class="page-content" [ngStyle]="{'background-color' : bookColor, 'border-radius': '3px'}">
                        <div class="" style="display: flex; align-items: flex-end; justify-content: center; width: 100%; height: 100%;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 70"
                                width="400" height="70"
                                style="margin: -20px auto; width: 20%;" [ngStyle]="{'fill': bookLogoColor}">
                                <path class="a"
                                    d="m59.9 46.1l-8.6 20.8c-0.6 1.5-2.2 2.5-3.8 2.5h-8.5c-1.7 0-3.2-1-3.8-2.6l-4.1-10.2c-0.3-0.8-1.5-0.8-1.8 0l-4.1 10.2c-0.6 1.6-2.1 2.6-3.8 2.6h-8.5c-1.6 0-3.2-1-3.8-2.5l-8.6-20.8c-1.1-2.7 0.9-5.7 3.8-5.7h3.9c1.8 0 3.3 1.1 3.9 2.7l4.8 12.9c0.3 0.9 1.6 0.9 1.9 0l4.4-12.8c0.6-1.7 2.2-2.8 3.9-2.8h6.2c1.8 0 3.3 1.1 3.9 2.8l4.5 12.8c0.3 0.9 1.5 0.9 1.9 0l4.7-12.9c0.6-1.6 2.2-2.7 3.9-2.7h3.9c3 0 4.9 3 3.8 5.7z">
                                </path>
                                <path class="a"
                                    d="m109.1 52.4v13.9c0 1.7-1.4 3.1-3.2 3.1h-5.3c-1.7 0-3.1-1.4-3.1-3.1v-9.1c0-5-1.8-7.1-7-7.1-3.2 0-6.6 1.3-8.9 3.3-0.2 0.2-0.4 0.5-0.4 0.9v12c0 1.8-1.4 3.2-3.1 3.2h-5.3c-1.7 0-3.1-1.5-3.1-3.2v-32.5c0-1.7 1.4-3.1 3.1-3.1h5.3c1.7 0 3.1 1.4 3.1 3.1v8.5c0 0.9 0.9 1.3 1.6 0.9 3.2-1.9 7.2-3.3 12.4-3.3 6.5 0 13.9 2 13.9 12.5z">
                                </path>
                                <rect class="a" x="117.4" y="40.3" width="11.6" height="29.1" rx="3">
                                </rect>
                                <path class="a"
                                    d="m155.3 50.1c-0.6 0-1.1 0.5-1.1 1.1v4.9c0 2.7 1.1 4.1 4.4 4.1 1.6 0 2.7 0 4.1-0.1 1.8-0.1 3.3 1.3 3.3 3.1v3.2c0 1.6-1.2 3-2.8 3.2-2.1 0.2-4.6 0.3-7.2 0.3-8.9 0-13.3-2.8-13.3-9v-9.7c0-0.7-0.5-1.1-1.1-1.1h-1.7c-1.7 0-3.1-1.4-3.1-3.2v-3.4c0-1.7 1.4-3.2 3.1-3.2h1.7c0.6 0 1.1-0.4 1.1-1v-1.3c0-1.5 0.9-2.7 2.3-3.1l5.4-1.3c1.9-0.5 3.8 1 3.8 3v2.7c0 0.6 0.5 1 1.1 1h7.6c1.7 0 3.1 1.5 3.1 3.2v3.4c0 1.8-1.4 3.2-3.1 3.2z">
                                </path>
                                <path fill-rule="evenodd" class="a"
                                    d="m201.9 58.5h5.7c2.5 0 4 2.9 2.6 5-2.9 4.3-8.6 6.4-17.6 6.4-11.3 0-19.2-4.2-19.2-15 0-10.8 7.9-15 19.7-15 10.7 0 18 3.4 19.3 13.4 0.2 1.9-1.2 3.5-3.1 3.5h-22.4c-0.9 0-1.4 1-0.8 1.7 1.4 1.7 4 2.2 6.5 2.2 3.7 0 5.5-0.2 7.5-1.6 0.5-0.4 1.1-0.6 1.8-0.6zm-14-7.5h10.2c1 0 1.3-1.3 0.5-1.8-1.5-1-3.5-1.1-5.6-1.1-2.1 0-4.1 0.1-5.6 1.1-0.9 0.5-0.5 1.8 0.5 1.8z">
                                </path>
                                <path fill-rule="evenodd" class="a"
                                    d="m259.8 54.9c0 11.7-8.4 15-15.8 15-4.2 0-8.3-1.1-11.3-3.8-0.3-0.2-0.7 0-0.7 0.3 0 1.7-1.3 3-3 3h-5.5c-1.7 0-3-1.3-3-3v-32.7c0-1.7 1.3-3 3-3h5.5c1.7 0 3 1.3 3 3v8.3c0 0.9 1 1.4 1.7 0.9 2.9-2.1 6.5-3 10.3-3 7.4 0 15.8 3.3 15.8 15zm-11.6 0c0-4.3-2.9-5.3-8.1-5.3-5.1 0-8.1 1-8.1 5.3 0 4.3 3 5.3 8.1 5.3 5.2 0 8.1-1 8.1-5.3z">
                                </path>
                                <path fill-rule="evenodd" class="a"
                                    d="m267.1 54.9c0-10.8 7.4-15 19.7-15 12.3 0 19.7 4.2 19.7 15 0 10.8-7.4 15-19.7 15-12.3 0-19.7-4.3-19.7-15zm27.8 0c0-4.3-2.9-5.3-8.1-5.3-5.1 0-8.1 1-8.1 5.3 0 4.3 2.9 5.3 8.1 5.3 5.1 0 8.1-1 8.1-5.3z">
                                </path>
                                <path fill-rule="evenodd" class="a"
                                    d="m313.8 54.9c0-10.8 7.4-15 19.7-15 12.3 0 19.6 4.2 19.6 15 0 10.8-7.3 15-19.6 15-12.3 0-19.7-4.3-19.7-15zm27.8 0c0-4.3-2.9-5.3-8.1-5.3-5.2 0-8.1 1-8.1 5.3 0 4.3 2.9 5.3 8.1 5.3 5.2 0 8.1-1 8.1-5.3z">
                                </path>
                                <path class="a"
                                    d="m389 53.3l9.9 10.6c1.9 2.1 0.5 5.5-2.4 5.5h-6c-0.9 0-1.8-0.4-2.4-1.1l-8.1-9.1c-0.4-0.4-1-0.5-1.4-0.2l-5.6 4.1c-0.3 0.2-0.4 0.5-0.4 0.9v2.2c0 1.7-1.5 3.2-3.2 3.2h-5.1c-1.8 0-3.3-1.5-3.3-3.2v-32.3c0-1.8 1.5-3.2 3.3-3.2h5.1c1.7 0 3.2 1.4 3.2 3.2v14.7c0 0.9 1 1.4 1.7 0.8l11-8.4c0.5-0.4 1.2-0.7 1.9-0.7h7.2c3.1 0 4.4 4 1.9 5.9l-7.2 5.5c-0.5 0.4-0.6 1.1-0.1 1.6z">
                                </path>
                                <rect class="a" x="110.9" y="8.8" width="24.6" height="24.7" rx="6">
                                </rect>
                                <rect class="a" x="138.1" y=".1" width="24.6" height="24.7" rx="6">
                                </rect>
                            </svg>
                        </div>
                    </div>
                    <div class="cover-rim cover-rim-last" [ngClass]="'e'+bookColor?.slice(1)"></div>
                </div>
            </ng-container>
        </ng-container>
    </div>
   <ng-container *ngIf="parent">
    <div class="py-5 text-center justify-content-center" *ngIf="getIsPortrait()">
        <div class="d-flex">
            <div class="col-6">
                <button class="btn btn-sm btn-secondary" (click)="exit()">Back</button>
            </div>
            <div class="col-6">
                <button class="btn btn-sm btn-primary main" [disabled]="!parent.editable || parent.uploader.uploading || !parent.uploader.finished"
                 (click)="orderNow()">Order Now</button>
            </div>
        </div>
        <ng-container *ngIf="isRotate">
            <div class="col-12" style="width: auto; margin-top: 3rem; opacity: 0.5;">
                <img style="background-color: #00000099; border-radius: 10px;" src="/assets/images/giphy.gif" alt="" width="70">
            </div>
            <div class="col-12 text-center">
                <small>Rotate device to landscape for better preview</small>
            </div>
        </ng-container>
    </div>
    <div class="col-2 text-center justify-content-center" style="align-self: stretch;display: inline-grid;" *ngIf="!getIsPortrait()">
        <button class="btn btn-sm btn-secondary align-self-start p-2" (click)="exit()">Back</button>
        <br/>
        <button class="btn btn-sm btn-primary align-self-end p-2" (click)="orderNow()">Order Now</button>
    </div>
   </ng-container>
</div>