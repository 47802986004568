import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from '../data-service.service';
import { HandlerService } from '../handler.service';

@Component({
  selector: 'app-select-photos',
  templateUrl: './select-photos.component.html',
  styleUrls: ['./select-photos.component.scss']
})
export class SelectPhotosComponent {

  @Input()
  images:any;

  @Input()
  selectMultiple:boolean = false;

  @Input()
  usedIndex!:number[];

  @Input()
  imgIndex!:number|null;

  @Input()
  message!:string;

  @Input()
  type:string = "page";

  @Output()
  actionConfirmed = new EventEmitter();

  isSelected:number[] = [];
  isDublicate = false;
  imgUpload:any = [];

  constructor(public dataService: DataService, public handler: HandlerService) {}

  ngOnInit() {
    console.log(this.usedIndex);
    this.images.sort((a,b)=> b-a);
  }

  selectImage(id:number) {
    if(this.isSelected.includes(id)) {
      this.isSelected.splice(this.isSelected.indexOf(id), 1);
    } else if(this.selectMultiple)
      this.isSelected.push(id);
    else this.isSelected = [id];
    this.sendSelection();
  }

  dubToggle(){
    this.isDublicate = !this.isDublicate;
    this.sendSelection();
  }

  getArray(size: number): number[] {
    return new Array(size);
  }

  sendSelection() {
    if(this.selectMultiple)
      this.actionConfirmed.emit({type:'selected', value:{id: this.isSelected, isDub:true}});
    else
      this.actionConfirmed.emit({type:'selected', value:{id: this.isSelected[0], isDub:this.isDublicate}});
  }

  save() {
    if(this.selectMultiple)
      this.actionConfirmed.emit({type:'save', value:{id: this.isSelected, isDub:true}});
    else
      this.actionConfirmed.emit({type:'save', value:{id: this.isSelected[0], isDub:this.isDublicate}});
  }

  close() {
    this.actionConfirmed.emit({type:'close'});
  }

  uploadImage(event) {
    this.handler.getPreviews().subscribe(progress => {
      if(progress.result)
        this.imgUpload.push(progress.result);
    });
    this.actionConfirmed.emit({type:'upload', value:event});
  }

}
