import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent {

  @Input()
  coupons = [];

  @Output()
  actionConfirmed = new EventEmitter();

  customCode;

  apply(coupon) {
    this.actionConfirmed.emit({type:'apply', value: coupon});
  }

  close() {
    this.actionConfirmed.emit({type:'close'});
  }
}
