<div class="row flex-wrap bg-white custom-pagination px-2" style="font-size: 14px;">
    <div class="col-6  col-sm-4 col-md-4 col-lg-4 d-flex align-items-center text-truncate">
        <div class="profile-pic-small"><i (click)="changePage('dec')" class="fas fa-step-backward icon-small"></i></div>
        <div class="profile-pic-small"><i (click)="changePageNo('dec')" class="fas fa-caret-left"></i></div>
        <div><small>Page</small></div>
        <div>
            <input class="form-control" placeholder="" type="text" [(ngModel)]="pageNumber"
                (keyup)="onPageChange()">
        </div>
        <div><small>of {{endPage}}</small></div>
        <div class="profile-pic-small"><i (click)="changePageNo('inc')" class="fas fa-caret-right"></i></div>
        <div class="profile-pic-small"><i (click)="changePage('inc')" class="fas fa-step-forward icon-small"></i></div>
    </div>
    <div class="col-6  col-sm-4 col-md-4 col-lg-4 d-flex align-items-center">
        <div class="d-flex align-items-center">    
                <select class="form-select w-auto" style="padding: 0.375rem 1.25rem 0.375rem 0.55rem;" [(ngModel)]="pageSize"
                (change)="onPageSizeChange()">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
                <option *ngIf="pageName == 'employeeCalibration'" value="1000">1000</option>
            </select>
        </div>
        <div><small>items per page</small></div>
    </div>
    <div class="col-6  col-sm-4 col-md-4 col-lg-4 d-flex align-items-center ml-auto">
        <div><small>{{((startingPageNumber-1)*pageSize)+1}} -
            {{pageSize > 1 ? (pageSize*(startingPageNumber-1)+numberOfElements) > totalNumbers ? totalNumbers :
            pageSize*(startingPageNumber-1)+numberOfElements : pageNumber}}
                of {{totalNumbers}} items</small></div>
        <div class="profile-pic-small">
            <i class="fas fa-redo icon-small" (click)="refresh()"></i>
        </div>
    </div>
</div>