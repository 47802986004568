<ng-container *ngIf="!(inEditor || inCollage || inCrop)">
    <p class="px-3" >Page {{editIndex}}/{{parent.bookData.metadata?.images?.length - 1}}</p>

    <div class="row px-4 edit" style="text-align: center;">
        <div class="d-flex">
            <div class="col">
                <i (click)="openModal()">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" stroke="currentColor" stroke-width="2"><path d="M8 21 L15 11 L19 15"></path><path d="M15 2 v5 h5"></path><path d="M8 2 h8 l4 4 v12 q0 4 -4 4 h-8 q-4 0 -4 -4 v-12 q0 -4 4 -4z"></path></g><circle fill="currentColor" cx="10" cy="8" r="1.5"></circle></g></svg>
                </i>
                <p>Select</p>
            </div>
            <div class="col">
                <i (click)="resetImage()">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="2"><path d="M7.388 18.538a8 8 0 10-2.992-9.03"></path><path fill="currentColor" d="M2.794 11.696L2.37 6.714l5.088 3.18z"></path><path d="M12 8v4M12 12l4 2"></path></g></g></svg>
                </i>
                <p>Reset</p>
            </div>
            <div class="col" *ngIf="!(renderMeta?.metadata.style == 'original' || renderMeta?.metadata.style == 'collage')">
                <i (click)="crop()">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g stroke-width="2" stroke="currentColor" fill="none"><path d="M23 17H9a2 2 0 0 1-2-2v-5m0-3V1 M1 7h14a2 2 0 0 1 2 2v7m0 4v3"></path></g></g></svg>
                </i>
                <p>Crop 
                    <!-- <small *ngIf="renderMeta?.metadata?.crop" class="text-muted active">(cropped)</small> -->
                </p>
            </div>
            <div class="col" *ngIf="renderMeta?.metadata.style != 'collage'">
                <i (click)="rotateRight()">
                    <svg class="" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round" style="transform: matrix(-1,0,0,1,0,0)"><g><g stroke="none" fill="currentColor"><path fill="none" d="M-1-1h582v402H-1z"></path><rect x="3" rx="1" height="12" width="12" y="9"></rect><path d="M15 5h-1a5 5 0 015 5 1 1 0 002 0 7 7 0 00-7-7h-1.374l.747-.747A1 1 0 0011.958.84L9.603 3.194a1 1 0 000 1.415l2.355 2.355a1 1 0 001.415-1.414l-.55-.55H15z"></path></g></g></svg>
                </i>
                <p>Rotate 
                    <!-- <small *ngIf="renderMeta?.metadata?.rotate" class="text-muted active">({{renderMeta?.metadata?.rotate}}&deg;)</small> -->
                </p>
            </div>
            <div class="col">
                <i (click)="deletePage()">
                    <svg width="24" height="24" viewBox="2 2 18 18" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" fill-rule="evenodd"><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M7.5 7h9z"></path><path d="M7.916 9h8.168a1 1 0 01.99 1.14l-.972 6.862a2 2 0 01-1.473 1.653c-.877.23-1.753.345-2.629.345-.876 0-1.752-.115-2.628-.345a2 2 0 01-1.473-1.653l-.973-6.862A1 1 0 017.916 9z" fill="currentColor"></path><rect fill="currentColor" x="10" y="5" width="4" height="3" rx="1"></rect></g></g></svg>
                </i>
                <p>Delete</p>
            </div>

        </div>
    
        <div class="imageBox">
            <i (click)="prevImg()" class="fa fa-solid fa-circle-chevron-left arrow prev"></i>
            <div *ngIf="!inCrop && renderMeta?.metadata" class="col-10 imgCont">
                <a *ngIf="actualImage||renderImage; else noImg" (click)="styleImage(null)">
                    <img  class="imgPrev" [src]="renderImage||actualImage | safeUrl" alt="image">
                    <div *ngIf="loading" class="overlay">loading...</div>
                </a>
            </div>
            <i (click)="nextImg()" class="fa fa-solid fa-circle-chevron-right arrow next"></i>
        </div>
    
    </div>
    
    <ng-content *ngTemplateOutlet="fitPages"></ng-content>
    
    <div class="row caption m-3">
        <div class="col-2 p-0">
            <small>Caption &nbsp;</small>
        </div>
        <div class="col-2 p-0">
            <div class="form-check form-switch">
                <input class="form-check-input" [ngModel]="renderMeta?.metadata.caption" (ngModelChange)="captionToggle()"
                 type="checkbox" role="switch" id="titleSwitch" [checked]="renderMeta?.metadata.caption">
            </div>
        </div>
        <div class="col-8 p-0">
            <small>add extra love with your captions</small>
        </div>
        <!-- <div class="col">
            <button class="btn btn-sm" (click)="crop()" >Crop</button>
        </div> -->
    </div>
    <div class="px-2" *ngIf="renderMeta?.metadata.caption">
        <small class="text-muted" style="font-size: 10px;">
            ({{renderMeta?.metadata?.text.length||0}}/{{maxCaption}})
        </small>
    </div>
    <div class="row mx-2 caption-select" *ngIf="renderMeta?.metadata.caption">
        <div class="col position-relative p-0">
            <textarea [ngModel]="renderMeta.metadata.text" (ngModelChange)="updateText($event)"
                [typeahead]="suggestedCaptions" container=".caption-select .col.position-relative"
                class="form-control" [typeaheadMinLength]="0" [maxlength]="maxCaption"></textarea>
               
        </div>
    </div>
    <br><br><br>
    <div class="footer-action">
        <div class="">
            <button class="btn btn-sm btn-secondary" (click)="backToAlbum()">Back</button>
        </div>
        <div class="">
            <button class="btn btn-sm btn-primary" (click)="saveAndNext(editIndex != parent.bookData.metadata?.images?.length - 1)">{{ editIndex == parent.bookData.metadata?.images?.length - 1 ? 'Save' : 'Save & Next'}}</button>
        </div>
    </div>
</ng-container>

<ng-template #noImg>
    <img class="imgPrev" style="object-fit: none;opacity: 0.4" src="assets/images/favicon.png" alt="">
</ng-template>

<!-- 
<app-editor *ngIf="inEditor" [modalVisible]="inEditor" (modalClosed)="inEditor = $event" 
[imgMeta]="bookData.metadata.images[editIndex]"
[imgSrc]="parent.parent.originalUrls.get(bookData.metadata.images[editIndex].id)"></app-editor> -->

<app-collage *ngIf="inCollage" [parent]="this" (getCollage)="setCollage($event)" [imgDataCp]="imgDataCp"></app-collage>

<ng-template #fitPages>
    <div class="row fitBtns" *ngIf="renderMeta?.metadata">
        <div class="col crop" [ngClass]="{'active' : renderMeta.metadata.style == 'full'}" (click)="styleImage('full')">
            <svg stroke="#cfcccc" fill="#cfcccc" height="40" viewBox="0 0 30 30" width="40"><rect width="30" height="30" rx="4" fill="inherit"></rect></svg>
            <p >Full</p>
        </div>
        <div class="col crop" [ngClass]="{'active' : renderMeta.metadata.style == 'smart'}" (click)="styleImage('smart')">
            <svg stroke="#cfcccc" height="40" viewBox="0 0 30 30" width="40"><rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="inherit" fill="none"></rect><rect x="7" y="7" width="16" height="16" rx="2" fill="inherit"></rect></svg>
            <p [ngClass]="renderMeta.metadata.style == 'smart' ? 'active' : ''">Smart</p>
        </div>
        <div class="col crop" [ngClass]="{'active' : renderMeta.metadata.style == 'fit'}" (click)="styleImage('fit')">
            <svg [ngStyle]="{'transform': renderMeta.layout == 'landscape' ? 'rotate(90deg)' :''}" stroke="#cfcccc" fill="#cfcccc" height="40" viewBox="0 0 30 30" width="40">
                <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="inherit" fill="none"></rect>
                <rect x="7" y="2" width="16" height="26" rx="2" fill="inherit"></rect>
            </svg>
            <p [ngClass]="renderMeta.metadata.style == 'fit' ? 'active' : ''">Fit</p>
        </div>
        <div class="col crop" [ngClass]="{'active' : renderMeta.metadata.style == 'collage'}" (click)="styleImage('collage')">
            <svg stroke="#cfcccc" fill="#fff" width="40" height="40" viewBox="0 0 128 128">
                <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="inherit" fill="none"></rect>
                <g transform="translate(0 128) scale(.1 -.1)"><path d="m0 640v-640h640 640v640 640h-640-640v-640zm600-1v-560l-209 3c-230 3-235 5-285 72-20 27-21 41-24 459-2 238 0 446 3 464 7 40 49 91 90 109 21 9 86 13 228 14h197v-561zm526 535c67-50 69-55 72-285l3-209h-260-261v261 260l209-3c193-3 212-5 237-24zm72-783c-3-230-5-235-72-285-25-19-44-21-237-24l-209-3v260 261h261 260l-3-209z"/></g></svg>
            <p [ngClass]="renderMeta.metadata.style == 'collage' ? 'active' : ''">Collage</p>
        </div>
        <div class="col crop" [ngClass]="{'active' : renderMeta.metadata.style == 'original'}" (click)="styleImage('original')">
            <svg fill="#fff" width="40" height="40" viewBox="0 0 30 30" enable-background="new 0 0 40 40" xml:space="preserve">
                <g><path d="M30,3v3L6,30H3L30,3zM10,30h3l17,-17v-3L10,30zM24,30h3l3,-3v-3L24,30zM17,30h3l10,-10v-3L17,30zM2,3v3l4,-4H3L2,3zM27,2h-3L2,24v3L27,2zM20,2h-3L2,17v3L20,2zM13,2h-3l-8,8v3L13,2z" fill="inherit"/></g>
            </svg>
            <p [ngClass]="renderMeta.metadata.style == 'original' ? 'active' : ''">Original</p>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="inCrop">
    <div  class="imageBox">
        <div class="imgCont">
            <app-croppie  #cropperView (cropper)="getCropData($event)" [imgData]="renderMeta"
                [imgOrgUrl]="parent.originalUrls.get(renderMeta.id)"></app-croppie>
        </div>
    </div>

    <div class="pt-4">
        <ng-content *ngTemplateOutlet="fitPages"></ng-content>
    </div>

    <div class="row text-center px-3" style="
    display: flex;
    margin: auto;
    width: 100%;
    padding-top: 30px;">
        <div class="col-6">
            <button class="btn btn-sm btn-secondary" (click)="crop()">Cancel</button>
        </div>
        <div class="col-6">
            <button class="btn btn-sm btn-primary" (click)="cropImage()">Crop</button>
        </div>
    </div>
</ng-container>
