<!-- <div class="m-1">
    <div class="row w-100 m-0" style="justify-content: space-evenly;">
        <ng-container *ngFor="let file of filesRead; index as i">
            <div class="col-4 p-1 gridPics">
                <ng-container *ngIf="file;else alt_image" >
                    <a (click)="selected(i, true)">
                        <img class="pics" [src]="file | safeUrl" alt="">
                    </a>
                    <svg (click)="selected(i, null)" [ngStyle]="{'display': imageMeta[i]?.isTicked ? 'block' : 'none'}" class="tick txt-blue" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7a.9959.9959 0 010-1.41c.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"></path></svg>
                </ng-container>
                <ng-template #alt_image>
                    <img class="pics" style="opacity: .3;" src="assets/images/favicon.png" alt="">
                </ng-template>
            </div>
        </ng-container>
    </div>
</div>

<div class="row px-3" style="position: sticky;bottom: 10px;">
    <div class="col-4 p-0">
        <input type="file" #uploadeInput id="file-input" [accept]="accepts" multiple (change)="uploadFiles($event)" style="display: none;">
        <button class="w-100 btn btn-sm btn-secondary" style="font-size: 16px;" (click)="uploadeInput.click()">Add
            <i class="fa-solid fa-image fa-bounce"></i>
        </button>
    </div>
    <div class="col-8 p-0 px-2">
        <button class="w-100 btn btn-sm btn-primary" [disabled]="!isNext || utils.minImages > filesRead.length" style="font-size: 17px;" (click)="upload()">
            Photos
            {{selectedImg}}/{{utils.minImages > filesRead.length ? utils.minImages : filesRead.length }}
            &nbsp;&nbsp;<i class="fa fa-solid fa-arrow-right fa-beat-fade" style="color: #2f4892;"></i>
        </button>
    </div>
</div> -->
<div id="wrapper">
    <div id="mouse"></div>
        <div class="loader">
    </div>
    <div class="loading-bar">
      <div class="progress-bar"></div>
    </div>
    <div class="status">
      <div class="state"></div>
      <div class="percentage"></div>
    </div>
  </div>