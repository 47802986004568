import { Injectable } from '@angular/core';
import smartcrop from 'src/assets/js/smartcrop';
import { DataService } from './data-service.service';
import { cloneDeep } from 'lodash';
declare const cv: any; 

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  private isWebp = false;

  private layoutMap = {
    'two-portrait': [
      {dx: 6.38021, dy : 21.5495, dw : 42.7083, dh : 56.901 },
      {dx: 50.9115, dy : 21.5495, dw : 42.7083, dh : 56.901 },
    ],
    'two-landscape' : [
      {dx: 21.5495, dy : 6.38021, dw : 56.901, dh : 42.7083 },
      {dx: 21.5495, dy : 50.9115, dw : 56.901, dh : 42.7083 },
    ],
    'three-landscape': [
      {dx: 15.4948, dy : 6.44531, dw : 69.0104, dh : 51.6927 },
      {dx: 15.4948, dy : 59.9609, dw : 33.5938, dh : 33.5938 },
      {dx: 50.9115, dy : 59.9609, dw : 33.5938, dh : 33.5938 },
    ],
    'three-portrait': [
      {dx: 6.44531, dy : 15.4948, dw : 51.6927, dh : 69.0104 },
      {dx: 59.9609, dy : 15.4948, dw : 33.5938, dh : 33.5938 },
      {dx: 59.9609, dy : 50.9115, dw : 33.5938, dh : 33.5938 },
    ], 
    'four-photo' : [
      {dx: 6.51042, dy : 6.51042, dw : 42.7083, dh : 42.7083 },
      {dx: 50.9115, dy : 6.51042, dw : 42.7083, dh : 42.7083 },
      {dx: 6.51042, dy : 50.9115, dw : 42.7083, dh : 42.7083 },
      {dx: 50.9115, dy : 50.9115, dw : 42.7083, dh : 42.7083 },
    ]
  }

  private maxDimension = 1024;
  minDpi = 200;


  constructor(private data: DataService) {
    this.isWebp = this.supportsWebP();
  }

  private supportsWebP() {
    var canvas = document.createElement('canvas');
    if (!!(canvas.getContext && canvas.getContext('2d'))) {
      return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
  }

  private loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  private setupCanvas(dimension: Array<number>, compressionRatio: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = dimension[0] * compressionRatio;
    canvas.height = dimension[1] * compressionRatio;
    return canvas;
  }

  private calculateCompression(imgMeta: any): number {
    const maxFileSize = this.minDpi * 1024;
    const currentFileSize = imgMeta.size;
    return imgMeta.dpi < this.minDpi || 
           currentFileSize <= maxFileSize || 
           Math.min(imgMeta.width, imgMeta.height) < 1024 ? 1 : Math.sqrt((100 * 1024) / currentFileSize);
  }

  private async smartcropImg(image, options) {
    return await smartcrop.crop(image, options)
  }

  async genImagePreview(orgUrls: Map<number, any>, imgMeta: any, quality:number = 0.9, 
    output: string = "base64", format:string = "jpeg", isSample:boolean = false, preview = true ) : Promise<string> {
      
    return new Promise(async (resolve) => {
      if(imgMeta && imgMeta.metadata?.style && imgMeta.metadata.style != "collage") {
        preview = preview ? imgMeta.metadata['dpi'] > this.minDpi : preview;
        const url = orgUrls.get(imgMeta.id)[preview ? 'previewUrl' : 'rawUrl'];
        const previewScale = preview ? orgUrls.get(imgMeta.id)['previewScale'] : 1;
        if(!preview)
          quality = 0.8;
        await this.smartApplyUrl(url, imgMeta, output, quality, format, isSample, previewScale).then((url)=> {
          resolve(url);
        })
      } else if(imgMeta) {
        await this.generateCollage(imgMeta, orgUrls, output, quality, format, preview).then((url:any)=> {
          resolve(url);
        })
      } else {
        resolve("");
      }
    });
  }

  public calculateDpi(w:number, h:number):number {
    let dpi = 0;
    dpi = ((w/8)+(h/8))/2
    return Math.round(dpi);
  }
  
  async newImagePreview(orgUrl:string|HTMLImageElement, imgMeta: any, isCover = false, coverData = {}, isNew = true, quality:number = 0.8, output: string = "base64", format:string = "webp"): Promise<Object> {
    return new Promise(async (resolve) => {
      try {
        const image = await (orgUrl instanceof HTMLImageElement ? orgUrl : this.loadImage(orgUrl.toString()));
        const w = Math.min(imgMeta.width, imgMeta.height)
        const [width, height, dx, dy] = this.cropDimension(isCover ? Object.assign({},imgMeta, coverData) : imgMeta, w);
        const canvas = document.createElement('canvas');
        const compress = this.calculateCompress(imgMeta) //: 1/(Math.max(imgMeta.width,imgMeta.height)/(this.maxDimension*2))
        if(imgMeta.metadata.style == "fit34" || imgMeta.metadata.style == "fit43" || imgMeta.metadata.style == "magnet") {
          canvas.width = width*compress;
          canvas.height = height*compress;
        }else {
          canvas.width = w*compress;
          canvas.height = w*compress;
        }
        const context = canvas.getContext('2d');
        if(context && (format == 'jpeg' || !this.isWebp)) {
          context.fillStyle = 'white';
          context.fillRect(0, 0, canvas.width, canvas.height);
        }
        const crop:any = {}
        if(imgMeta.metadata.style == 'original') {
          Object.assign(crop,this.getOriginalCrop(imgMeta, compress, canvas.width, canvas.height));
        } else if(imgMeta.metadata.style != 'original') {
          const option = { width, height };
          if(imgMeta.metadata['boost'] && imgMeta.metadata['boost'].length) {
            option['boost'] = imgMeta.metadata['boost'];
          }
          const result:any = await this.smartcropImg(image, option);
          Object.assign(crop,{sx:result.topCrop.x, sy:result.topCrop.y, sw:result.topCrop.width, sh:result.topCrop.height});
        }
        if(JSON.stringify(crop) != '{}') 
          imgMeta.metadata['dpi'] = this.calculateDpi(crop.sw, crop.sh)
        imgMeta.metadata['crop'] = crop;
        this.drawImage(canvas, context, image, isCover ? Object.assign({}, coverData, imgMeta) : imgMeta, true, compress, (dx*compress), (dy*compress), canvas.width - (dx*compress)*2, canvas.height - (dy*compress)*2);
        if(output == 'canvas') {
          // pending
          resolve(imgMeta);
        } else if(output == 'base64') {
          if(this.isWebp)
            imgMeta.src = canvas.toDataURL("image/"+format, quality);
          else
            imgMeta.src = canvas.toDataURL("image/jpeg", quality);
          resolve(imgMeta)
        } else {
          canvas.toBlob(
            (blob:any) => {
              imgMeta.src = URL.createObjectURL(blob)
              resolve(imgMeta);
            }, "image/"+format, quality
          );
        }
      } catch(e) {
        console.error(e);
        resolve(false);
      }
    });
  }

  private async smartApplyUrl(imageUrl: any, imgMeta: any, output: string, q:number, format:string, isSample:boolean, scale:number): Promise<string> {
    return new Promise(async (resolve) => {
      const img = await this.loadImage(imageUrl);
      const comp = scale / Math.max(imgMeta.width, imgMeta.height);
      const compress = scale > 1 ?  comp > 1 ? 1 : comp : 1 ;

      // const compress = 1/(Math.max(imgMeta.width,imgMeta.height)/comp)
      // const compress = this.calculateCompression(imgMeta);
      const dimension = this.fitCropDimension(imgMeta);
      const canvas = this.setupCanvas(dimension, compress);


      const context = canvas.getContext('2d');
      const dx = dimension[2]*compress, dy = dimension[3]*compress;
      const w = (canvas.width - (dx*2)), h = (canvas.height - (dy*2))

      if(context && format == 'jpeg') {
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
      }
      if(context && isSample) {
        context.filter = 'blur(30px)';
      }

      this.drawImage(canvas, context, img, imgMeta, false, compress, dx, dy, w, h);
      if(output == 'canvas') {
        resolve(canvas+"");
      } else if(output == 'base64') {
        resolve(canvas.toDataURL("image/"+format, q));
      } else {
        canvas.toBlob(
          (blob) => {
            resolve(URL.createObjectURL(blob||new Blob));
          }, "image/"+format, q
        );
      }
      
    });
  }

  private drawImage(canvas, context, img, imgMeta, isNew, compress, dx, dy, w, h) {
    const crop = cloneDeep(imgMeta.metadata.crop);
    if(!isNew) {
      crop.sx *= compress;
      crop.sy *= compress;
      crop.sw *= compress;
      crop.sh *= compress;
    }
    let ct = (canvas.width/80);
    let cap:any = { x:ct*3, y:ct, w: ct*6, h:ct*10 };
    if(imgMeta.metadata.style == 'fit') {
      ct = (canvas.width/8);
      if(imgMeta.width > imgMeta.height) {
        cap = { x:dx, y:Math.max(dy, ct*.5), w: w, h:h }
      } else 
      cap = { x:ct*1.375, y:ct*.5, w: ct*5.25, h:ct*7 }
    } else if(imgMeta.metadata.style == 'full' || imgMeta.metadata.style == 'smart') {
      ct = (canvas.width/8);
      cap = { x:ct*0.5, y:ct*.5, w: ct*7, h:ct*7 }
    }
    if(imgMeta.metadata.style == 'original'){
      if(isNew) {
        crop.sx *= compress;
        crop.sy *= compress;
        crop.sw *= compress;
        crop.sh *= compress;
      }
      if(imgMeta.metadata.caption) {
        if(imgMeta.width > imgMeta.height) {
          // cap = { x:dx, y:Math.max(dy, ct*.5), w: w, h:h }
          context?.drawImage(img, crop.sx, crop.sy, crop.sw, crop.sh);
        } else context?.drawImage(img, crop.sx+cap.x, crop.sy+cap.y, crop.sw-cap.w, crop.sh-cap.h);
      } 
      else context?.drawImage(img, crop.sx, crop.sy, crop.sw, crop.sh);
    }
    else if(imgMeta.metadata.style == 'cover') {
      const r = imgMeta.metadata["rotate"]||0;
      this.drawCover(context, canvas, w, h, r, dx, dy, imgMeta, img, crop)
      this.drawSpine(canvas, context, imgMeta.color);
    }else if(imgMeta.metadata.style == 'magnet') {
      const r = imgMeta.metadata["rotate"]||0;
      this.drawMagnet(context, canvas, w, h, r, dx, dy, imgMeta, img, crop)
    }
    else if(imgMeta.metadata.caption ) {
      context?.drawImage(img, crop.sx, crop.sy, crop.sw, crop.sh, cap.x, cap.y, cap.w, cap.h);
    }
    else context?.drawImage(img, crop.sx, crop.sy, crop.sw, crop.sh, dx, dy, w, h);

    // other metadata on top of square image
    const r = imgMeta.metadata["rotate"]||0;
    if(r && imgMeta.metadata.style != 'cover') {
      this.rotateCanvas(context, canvas, w, h, r, dx, dy);
    }
    if(imgMeta.metadata.caption) {
      this.writeCaption(canvas, context, imgMeta, w, h)
    }
  }

  private drawMagnet(context, canvas, w, h, r, dx, dy, imgMeta, img, crop) {
    if(imgMeta.metadata.frame?.name == "mg2") {
      let sw = canvas.width;
      let sh = canvas.height;
      const sx = 0;
      const sy = 0;
      const aspectRatio = 2.75 / 3.5;
      if (sw / sh > aspectRatio) {
        sw = sh * aspectRatio;
      } else {
        sh = sw / aspectRatio;
      }
      const radius = 30;
      const margin = 30;
      this.drawRoundedImageWithPadding(context, img, crop.sx, crop.sy, crop.sw, crop.sh, sx,sy,sw,sh, radius, margin);
    } else {
      let sw = canvas.width;
      let sh = canvas.width;
      const sx = 0;
      const sy = 0;
      const aspectRatio = 3.5 / 2.75;
      let mh = sw * aspectRatio;
      canvas.height = mh;
      const radius = canvas.width * 0.024;
      const margin = canvas.width * 0.024;

      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);

      this.drawRoundedImageWithPadding(context, img, crop.sx, crop.sy, crop.sw, crop.sh, sx, sy, sw, sh, radius, margin);
      const whitePartHeight = canvas.height - mh;
      const t = sw * 0.06;
      context.fillStyle = "#000";
      const fontSize = imgMeta.titleStyle == 'Ronda' ? (t / 3) * 5 : t;
      context.font = `${fontSize}px ${imgMeta.titleStyle}, sans-serif`;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      const textY = mh + (whitePartHeight / 2);
      const textX = sw / 2;
      const text = `  ${imgMeta.title}  `;
      context.fillText(text, textX, textY - (sw*.16));
      if(imgMeta.isDob) {
        const date = `  ${imgMeta.date}  `;
        context.textBaseline = 'top';
        context.font = (" "+(t*(0.4))+"px '%font%', sans-serif").replace("%font%",'Nunito');
        context.fillText(date, textX, textY - (sw*.07));
      }
    }
  }

  private drawRoundedImageWithPadding(ctx, img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight, radius, padding) {
    ctx.save();
    const paddedDx = dx + padding;
    const paddedDy = dy + padding;
    const paddedDWidth = dWidth - 2 * padding;
    const paddedDHeight = dHeight - 2 * padding;
    ctx.beginPath();
    ctx.moveTo(paddedDx + radius, paddedDy);
    ctx.lineTo(paddedDx + paddedDWidth - radius, paddedDy);
    ctx.quadraticCurveTo(paddedDx + paddedDWidth, paddedDy, paddedDx + paddedDWidth, paddedDy + radius);
    ctx.lineTo(paddedDx + paddedDWidth, paddedDy + paddedDHeight - radius);
    ctx.quadraticCurveTo(paddedDx + paddedDWidth, paddedDy + paddedDHeight, paddedDx + paddedDWidth - radius, paddedDy + paddedDHeight);
    ctx.lineTo(paddedDx + radius, paddedDy + paddedDHeight);
    ctx.quadraticCurveTo(paddedDx, paddedDy + paddedDHeight, paddedDx, paddedDy + paddedDHeight - radius);
    ctx.lineTo(paddedDx, paddedDy + radius);
    ctx.quadraticCurveTo(paddedDx, paddedDy, paddedDx + radius, paddedDy);
    ctx.closePath();
    ctx.clip();
    ctx.drawImage(img, sx, sy, sWidth, sHeight, paddedDx, paddedDy, paddedDWidth, paddedDHeight);
    ctx.restore();
  }

  private drawCover(context, canvas, w, h, r, dx, dy, imgMeta, img, crop) {
    context.fillStyle = imgMeta.color||'#FFF';
    context.fillRect(0, 0, w, w);
    if(imgMeta.frame?.name == "wb2") {
      const isFit = imgMeta.frame?.style == 'fit';
      const padding = 0.5;
      const m = w/8*padding;
      const x = w/8*1.25;
      const y = w/8*2;
      const iw = (w - x*2);

      const sx = isFit ? (w - (iw*1.2727))/2 : x;
      const sy = y;
      const sw = isFit ? (iw*1.2727) : iw;
      const sh = iw;
      
      if(r) {
        // context.clearRect(sx,sy,sw,sh);
        context?.save();
        context?.translate(x + iw / 2, y + iw / 2);
        const angleInDegrees = r;
        const angleInRadians = angleInDegrees * Math.PI / 180;
        context?.rotate(angleInRadians);
        context?.drawImage(img, crop.sx, crop.sy, crop.sw, crop.sh, -iw / 2, -iw / 2, iw, iw);
        context?.restore();
      } else 
      context?.drawImage(img, crop.sx, crop.sy, crop.sw, crop.sh, sx,sy,sw,sh);

      this.drawTextWb2(context, imgMeta, x, iw, w)

    } else {
      const padding = 0.5;
      const m = w/8*padding;
      const iw = (w - m*2);
      context?.drawImage(img, crop.sx, crop.sy, crop.sw, crop.sh, m, m, iw, iw);
      if(r) {
        this.rotateCanvas(context, canvas, w, h, r, dx, dy);
      }
      this.drawTextDefault(context, imgMeta, m, w)
    }
  }

  private drawSpine(canvas, ctx, color) {
    let rgb = this.hexToRgb(color);
    if(rgb) {
      const gradient = ctx.createLinearGradient(0, 0,canvas.width * 0.035, 0);
      gradient.addColorStop(0, this.getColorbyIndex(rgb, 5));
      gradient.addColorStop(0.16, this.getColorbyIndex(rgb, 5));
      gradient.addColorStop(0.161, this.getColorbyIndex(rgb, 0));
      gradient.addColorStop(0.41, this.getColorbyIndex(rgb, 0));
      gradient.addColorStop(0.411, this.getColorbyIndex(rgb, 4.5));
      gradient.addColorStop(0.5, this.getColorbyIndex(rgb, 4.5));
      gradient.addColorStop(0.59, this.getColorbyIndex(rgb, 6));
      gradient.addColorStop(0.68, this.getColorbyIndex(rgb, 7));
      gradient.addColorStop(0.84, this.getColorbyIndex(rgb, 8));
      gradient.addColorStop(0.841, this.getColorbyIndex(rgb, 0));
      gradient.addColorStop(1, this.getColorbyIndex(rgb, 0));
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width * 0.035, canvas.height);
    }
  }

  private getColorbyIndex(rgb, n) {
    let {r,g,b} = rgb;
    r -= n*2;
    g -= n*2;
    b -= n*2;
    return "rgb("+[r>0?r:0, g>0?g:0, b>0?b:0].join(", ")+")";
  }

  private hexToRgb(hex) {
    hex = ["#FFF", "#FFFFFF", "#fff", "#ffffff"].includes(hex) ? "#FFFFFF" : hex;
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }  
  
  private drawTextWb2(context, imgMeta, x, iw, w) {
    const xStart = x;
    const yStart = w/8*1.4;
    const xEnd = iw+x;
    const yEnd = w/8*1.4;
    const t = w * 0.036;
    const fontSize = imgMeta.titleStyle == 'Ronda' ? t/3*5 : t;
    context.strokeStyle = imgMeta.titleColor;
    context.lineWidth = 0.7; // Width of the line
    context.beginPath();
    context.moveTo(xStart, w/8 * 1.5);
    context.lineTo(xEnd, w/8 * 1.5);
    context.stroke();
    context.lineWidth = 0.3;
    context.fillStyle = imgMeta.titleColor;
    const text = imgMeta.title;
    imgMeta.titleStyle == 'Ronda' ? context.letterSpacing = "2px" : null;
    context.font = (" "+fontSize+"pt '%font%', sans-serif").replace("%font%",imgMeta.titleStyle);
    context.textAlign = 'center';
    context.textBaseline = imgMeta.titleStyle == 'Ronda' ? 'middle': 'hanging';
    const centerX = w / 2;
    const centerY = (w/8) * 1.01
    context.fillText(text, centerX, centerY);
    if(imgMeta.isDob) {
      context.textBaseline = 'top';
      context.font = (" "+(t*(0.5))+"px '%font%', sans-serif").replace("%font%",'Nunito');
      context.fillText(imgMeta.dob, centerX, w/8 * 1.6);
    }
  }

  private drawTextDefault(context, imgMeta, m, w) {
    const t = w * 0.034;
    const fontSize = imgMeta.titleStyle == 'Ronda' ? t/3*5 : t;
    context.fillStyle = imgMeta.color;
    console.log(fontSize);
    
    context.font = (" "+fontSize+"px '%font%', sans-serif").replace("%font%",imgMeta.titleStyle);
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    const y = w/8*(1.2)
    const text ="  "+imgMeta.title+"  " ;
    const x = Math.max(w/8*2, context.measureText(text).width)
    context.fillRect(m, y, x , m);
    context.fillStyle = imgMeta.titleColor;
    context.fillText(text, m+x/2, y+(m/1.8));
  }

  private rotateCanvas(context:CanvasRenderingContext2D, canvas:HTMLCanvasElement, w:number, h:number, r= 0, dx, dy) {
    const x =(w / 2)+dx;
    const y =(h / 2)+dy;
    const tempCanvas:any = canvas.cloneNode(true);
    const tempContext = tempCanvas.getContext('2d');
    tempContext.translate(x, y);
    tempContext.rotate(r * Math.PI / 180)
    tempContext.drawImage(canvas, -x, -y);
    tempContext.setTransform(1,0,0,1,0,0)
    context.clearRect(0,0, canvas.width, canvas.height);
    context.drawImage(tempCanvas, 0, 0);
  }

  private writeCaption(canvas:HTMLCanvasElement, context:any, imgMeta:any, w, h) {
    const t = canvas.width/50.405040504050405;
    context.fillStyle = '#000'
    context.font = t+"px Nunito,sans-serif";
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    const caption = imgMeta.metadata.text;
    context.fillText(caption, canvas.width/2, (canvas.width/8*7.75));
  }

  private captionMap(width, height, imgMeta:any):number[] {
    let dx = (width/8) * 0.6;
    let dy = (height/8) * 0.6;
    let dw = (width/8) * 7.4;
    let dh = (height/8) * 7.4;
    if(imgMeta.metadata.style == "full") {
      
    }
    return [dx, dy, dw, dh];
  }

  private async generateCollage(imgData: any, originalUrls:Map<any, any>, output: string, q, format:string, preview:boolean): Promise<string> {
    const collageData = imgData.metadata.collage;
    return new Promise(async (resolve) => {
      let self = this;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = this.maxDimension;
      canvas.height = this.maxDimension;
      if(context && format == 'jpeg') {
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
      }
      (async function () {
        for (let [index, seq] of collageData.seq.entries()) {
          const imgMeta = collageData.metadata.images[+seq];
          const url = originalUrls.get(imgMeta.id)[preview ? 'previewUrl' : 'rawUrl'];
          // const url = orgUrls.get(imgMeta.id)[preview ? 'previewUrl' : 'rawUrl'];
          const scale = preview ? originalUrls.get(imgMeta.id)['previewScale'] : 1;
          // const scale = urlData.scale;
          const compress = scale > 1 ?  scale / Math.max(imgMeta.width, imgMeta.height) : 1 ;

          const [dx, dy, dw, dh] = self.calculateCollageDim(collageData.layout, index, canvas.width);
          const crop = cloneDeep(imgMeta.metadata.crop);
          if(preview) {
            crop.sx *= compress;
            crop.sy *= compress;
            crop.sw *= compress;
            crop.sh *= compress;
          }
          const img:HTMLImageElement = await self.loadImage(url);
          context?.drawImage(img, crop.sx, crop.sy, crop.sw, crop.sh, dx, dy, dw, dh);
        }
        const r = collageData.metadata["rotate"]||0;
        if(r && context) 
          self.rotateCanvas(context, canvas, canvas.width, canvas.width, r, 0, 0);
        if(imgData.metadata.caption) {
          // context?.drawImage(canvas, cap.x, cap.y, canvas.width-cap.w, canvas.height-cap.h);
          self.writeCaption(canvas, context, imgData, canvas.width, canvas.height)
        }
        if(output == 'canvas') {
          resolve(canvas+"");
        } else if(output == 'base64') {
          resolve(canvas.toDataURL("image/"+format, q));
        } else {
          canvas.toBlob(
            (blob) => {
              resolve(URL.createObjectURL(blob||new Blob));
            }, "image/"+format, q
          );
        }
      })();
    });
  }

  private calculateCollageDim(layout:any, index:number, width:number) {
    const {dx, dy, dw, dh} = this.layoutMap[layout][index];
    return [ (width/100)*dx, (width/100)*dy, (width/100)*dw, (width/100)*dh ];
  }

  private calculateCompress2(imgMeta, maxFileSizeKB = 300) {
    const maxFileSize = maxFileSizeKB * 1024;
    const currentFileSize = imgMeta.size;
    return (imgMeta.dpi < this.minDpi || currentFileSize <= maxFileSize || Math.min(imgMeta.width, imgMeta.height) < 1024) ? 1 : Math.sqrt((100*1024) / currentFileSize);
  }

  private calculateCompress(imgMeta, maxFileSizeKB = 200) {
    const maxFileSize = maxFileSizeKB * 1024;
    const currentFileSize = imgMeta.size;
    const minQuality = 0.3;
    const maxQuality = 1;  
    let quality;
    if (currentFileSize <= maxFileSize || Math.min(imgMeta.width, imgMeta.height) < 1024 || imgMeta.dpi < this.minDpi) {
      quality = maxQuality; 
    } 
    else if (currentFileSize > 2 * maxFileSize) {
      quality = Math.sqrt((2 * maxFileSize) / currentFileSize);
      quality = Math.max(quality, minQuality); 
    } 
    else {
      quality = Math.sqrt(maxFileSize / currentFileSize);
      quality = Math.max(quality, minQuality); 
    }
    return quality;
  }
  

  private fitCropDimension(data: any) {
    const w = Math.min(data.width, data.height)
    if(data.metadata.style == 'magnet') {
      let mw = 2.75, mh = 3.5;
      if(data.layout == 'portrait' || data.layout == 'square')
        if(data.metadata.frame.name == 'mg1')
          return [data.width, data.width, 0, 0];
        else
        return [data.width, data.width * (mh/mw), w/2.75, 0];
      else {
        if(data.metadata.frame.name == 'mg1')
          return [data.height, data.height, 0, 0];
        else
          return [data.height, data.height * (mh/mw), 0, 0];
      }
    }
    if((data.layout == 'portrait' || data.layout == 'square') && data.metadata.style == 'fit') {
      return new Array(2).fill(w).concat([w/8,0]);
    }
    if(data.layout == 'landscape' && data.metadata.style == 'fit') {
      return new Array(2).fill(w).concat([0,w/8]);
    }
    if(data.metadata.style == 'fit34') {
      return [data.width, data.width * 1.3333, 0, 0];
    }
    if(data.metadata.style == 'fit43') {
      return [data.height * 1.3333, data.height, 0, 0];
    }
    if(data.metadata.style == 'smart') {
      return new Array(2).fill(w).concat([(w/8) * 0.5,(w/8) * 0.5]);
    }
    return new Array(2).fill(w).concat([0,0]);
  }

  private cropDimension(data: any, w: number) {
    // const dim = data.layout == 'portrait' || data.layout == 'square' ? data.width : data.height;
    if(data.metadata.style == 'cover' && data.frame.name == 'wb2' && data.frame.style == 'fit') {
      return [data.height * 1.2727, data.height, 0, w/8];
    }
    if(data.metadata.style == 'magnet') {
      let mw = 2.75, mh = 3.5;
      if(data.layout == 'portrait' || data.layout == 'square')
        if(data.metadata.frame.name == 'mg1')
          return [data.width, data.width, 0, 0];
        else
        return [data.width, data.width * (mh/mw), w/2.75, 0];
      else {
        if(data.metadata.frame.name == 'mg1')
          return [data.height, data.height, 0, 0];
        else
          return [data.height, data.height * (mh/mw), 0, 0];
      }
    }
    if((data.layout == 'portrait' || data.layout == 'square') && data.metadata.style == 'fit') {
      return [data.width, data.width * 1.3333, w/8, 0];
    }
    if(data.layout == 'landscape' && data.metadata.style == 'fit') {
      return [data.height * 1.3333, data.height, 0, w/8];
    }
    if(data.metadata.style == 'fit34') {
      return [data.width, data.width * 1.3333, 0, 0];
    }
    if(data.metadata.style == 'fit43') {
      return [data.height * 1.3333, data.height, 0, 0];
    }
    if(data.metadata.style == 'smart') {
      return new Array(2).fill(w).concat([(w/8) * 0.5,(w/8) * 0.5]);
    }
    return new Array(2).fill(w).concat([0,0]);
  }

  private getOriginalCrop(imgMeta, compress, width, height) {
    const aspectRatio = imgMeta.width / imgMeta.height;
    let sw, sh;
    if (aspectRatio > 1) {
      sw = width;
      sh = width / aspectRatio;
    } else {
      sw = height * aspectRatio;
      sh = height;
    }
    const sx = (width - sw) / 2;
    const sy = (height - sh) / 2;
    return {sx:sx/compress,sy:sy/compress,sw:sw/compress,sh:sh/compress};
  }
}