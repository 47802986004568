import { Component, Input } from '@angular/core';
import { DataService } from '../data-service.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { SelectPhotosComponent } from '../select-photos/select-photos.component';
import { CustomUtils } from '../service/customUtils';
import { PreviewService } from '../preview.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-magnet',
  templateUrl: './magnet.component.html',
  styleUrls: ['./magnet.component.scss']
})
export class MagnetComponent {

  @Input()
  image = "assets/images/favicon.png";

  @Input()
  bookDataCp: any;

  @Input()
  originalUrls: any;

  @Input()
  parent:any;

  private bookData:any;
  magnetData:any = [];
  renderData:any = {};
  magnetDataCp:any;
  inCrop = false;
  loading = false;
  magnetTypes:any = [];

  private subscribe!:Subscription;
  private modalSubscription!: Subscription;
  private modalRef;

  constructor(public activeModal: NgbActiveModal, private utils: CustomUtils,
    private preview: PreviewService, private customModal:NgbModal, private toaster:ToastrService) {
      this.magnetTypes = this.utils.types;
  }

  ngOnInit() {
    this.bookData = cloneDeep(this.bookDataCp);
    if(this.bookData.metadata.magnets && this.bookData.metadata.magnets.length) {
      this.magnetDataCp = cloneDeep(this.bookData.metadata.magnets);
      this.magnetData = cloneDeep(this.magnetDataCp);
      this.renderData = this.magnetData[0];
      this.parseMagnet();
    } else {
      this.magnetData = [];
      const data = this.originalUrls.get(0);
      this.loading = true;
      this.utils.newMagnetData(data, 0).then(res=> {
        this.renderData = res;
        this.magnetData = [res];
        this.magnetDataCp = [res];
        this.loading = false;
      })
    }

  }

  async parseMagnet() {
    return await new Promise(async (resolve) => {
      this.loading = true;
      this.preview.genImagePreview(this.originalUrls, this.renderData, undefined, undefined, undefined, undefined, false).then(src=> {
        this.renderData.src = src;
        this.loading = false;
        resolve(true);
      })
    })
  }

  changeLayout(name) {
    this.renderData.metadata.frame = this.utils.magnetFrames.find(x=> x.name == name);
    this.genMagnet(this.renderData.id)
  }

  changeTitle(val:string) {
    this.renderData["title"] = val;
    this.parseMagnet();
  }

  onBlur() {
    const val = this.renderData["title"];
    this.renderData["title"] = val?.trim()?.slice(0, this.renderData.metadata.frame.titleLen);
    // this.parseMagnet();
  }

  changeDob(date:string) {
    this.renderData.date = date;
    this.parseMagnet();
  }

  changeIsDob() {
    this.renderData.isDob = !this.renderData.isDob;
    this.parseMagnet(); 
  }

  replaceEmogi(str) {
    return str.replace(/🎈$/, '').replace(/📍$/, '').replace(/♥️$/, '').trim();
  }

  changeType(event) {
    this.renderData.type = event.target?.['value'];
    this.renderData.title = this.replaceEmogi(this.renderData.title);
    switch(this.renderData.type) {
      case 'travel' : this.renderData.title += " 📍"; break;
      case 'birthday' : this.renderData.title += " 🎈"; break;
      case 'pregnancy' : this.renderData.title += " ♥️"; break;
    }
    this.parseMagnet();
  }

  crop() {
    if(this.inCrop) {
      this.renderData.metadata["crop"] = cloneDeep(this.magnetDataCp[0]).metadata?.crop;
      this.parseMagnet();
    }
    this.inCrop = !this.inCrop;
  }

  getCropData(crop) {
    this.renderData.metadata["crop"] = crop;
    this.renderData.metadata["dpi"] = this.preview.calculateDpi(crop.sw, crop.sh);
  }

  cropImage() {
    this.inCrop = false;
    this.parseMagnet();
  }

  async genMagnet(id) {
    return await new Promise(async (resolve) => {
      const data = this.originalUrls.get(id);
      this.preview.newImagePreview(data.rawUrl, this.renderData).then(res=> {
        res["id"] = id;
        this.renderData = res;
        this.magnetData = [res];
        resolve(true);
      })
    })
  }

  openModal() {
    this.modalRef = this.customModal.open(SelectPhotosComponent,
      {
        centered: true, backdrop: 'static', keyboard: false
      }
    );
    this.modalRef.componentInstance.images = this.getRawImages();
    this.modalRef.componentInstance.usedIndex = [];
    this.modalRef.componentInstance.imgIndex = this.renderData?.id||-1;
    this.modalSubscription = this.modalRef.componentInstance.actionConfirmed.subscribe((data: any) => {
      if(data.type == 'close')
        this.closeModalFromParent();
      else if(data.type == 'save')
        this.getImageSelection(data.value);
    });
  }

  getRawImages() {
    const images:any = [];
    Array.from(this.originalUrls.keys()).forEach(x=> {
      const d = this.originalUrls.get(x);
      images.push({id:x, src: d.previewUrl});
    })
    return images;
  }

  async getImageSelection(value:any) {
    await this.genMagnet(value.id);
    this.closeModalFromParent();
  }

  closeModalFromParent() {
    this.unsubscribeModal();
    if (this.modalRef) {
      this.modalRef.close('Closing from parent');
    }
  }

  unsubscribeModal() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  validate():boolean {
    return this.renderData.title && this.renderData.type;
  }

  save() {
    if(this.validate()) {
      console.log(this.renderData);
      this.bookDataCp.metadata.magnets = [this.renderData];
      this.parent.bookData = this.bookDataCp;
      this.parent.dataService.setMagnet(this.renderData.src);
      this.parent.magnetImage = this.renderData.src;

      this.parent.updateBooks();
      this.close();
    } else {
      this.toaster.warning("Fill all the inputs!")
    }
  }

  close() {
		this.activeModal.close();
	}
  
  ngOnDestroy() {
    this.subscribe?.unsubscribe();
    this.unsubscribeModal();
  }

}
