
<ng-container *ngIf="!type">
	<ng-container *ngIf="message == 'privacy' || message == 'refund'">
		<div class="modal-header">
			<h4 class="modal-title">{{messages[message]['head']}}</h4>
		</div>
		<div class="modal-body" style="overflow-y: auto;max-height: 70vh;">
			<div *ngFor="let item of messages[message]['content']; let i = index">
				<ng-container *ngFor="let t of item.text; let j = index">
				  <ng-container [ngSwitch]="item.tag">
					<h1 *ngSwitchCase="'h1'" [attr.key]="i + '' + j">{{ t }}</h1>
					<p *ngSwitchCase="'p'" [attr.key]="i + '' + j">{{ t }}</p>
					<li *ngSwitchCase="'li'" [attr.key]="i + '' + j">{{ t }}</li>
				  </ng-container>
				</ng-container>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)"></button>
			<button type="button" class="btn btn-outline-dark" (click)="activeModal.close(true)">Sure</button>
		</div>
	</ng-container>
	<ng-container *ngIf="message != 'privacy' && message != 'refund'">
		<div class="modal-header">
			<h4 class="modal-title">Hi there!</h4>
			<!-- <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close(false)"></button> -->
		</div>
		<div class="modal-body">
			<p [innerHTML]="message"></p>
		</div>
		<div class="modal-footer">
			<ng-container *ngIf="ok">
				<button type="button" style="visibility: hidden;" [disabled]="true" class="btn btn-outline-dark" (click)="activeModal.close(false)"></button>
				<button type="button" class="btn btn-sm btn-secondary btn-outline-dark" (click)="activeModal.close(true)">Ok</button>
			</ng-container>
			<ng-container *ngIf="!ok">
				<button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Cancel</button>
				<button type="button" class="btn btn-outline-dark" (click)="activeModal.close(true)">Sure</button>
			</ng-container>
		</div>
	</ng-container>
</ng-container>
<ng-container *ngIf="type">
	<div class="modal-close">
		<img (click)="close()" src="assets/images/closepop.svg"/>
	</div>
	<div class="modal-body">
		<div class="top-content">
			<div><img src="assets/images/gifts.svg" width="144" height="171"/></div>
			<!-- <ng-container *ngIf="type == 'unlocked'">
			<div>+</div>
			<div>
				<div>Additional</div>
				<div class="ten">10%</div>
				<div>OFF</div>
			</div>
			</ng-container> -->
		</div>
		<div class="info">
			<ng-container *ngIf="type == 'unlock_magnet'">
				<div class="m1" [innerHTML]="data['m1']"></div>
				<div class="head" [innerHTML]="data['m2']"></div>
				<div class="m3" [innerHTML]="data['m3']"></div>
			</ng-container>
			<ng-container *ngIf="type == 'unlock_discount'">
				<div class="head" [innerHTML]="data['m2']"></div>
				<div class="m1" [innerHTML]="data['m1']"></div>
				<div class="m3 t2" [innerHTML]="data['m3']"></div>
			</ng-container>
			<ng-container *ngIf="type == 'unlocked'">
				<div class="head mb-3" [innerHTML]="data['m1']"></div>
				<div class="m1 mb-1" [innerHTML]="data['m2']"></div>
				<div class="m3 t2" [innerHTML]="data['m3']"></div>
			</ng-container>
		</div>
		<div class="pt-3">
			<button class="btn btn-sm btn-primary" (click)="createMagnet()">Grab Now</button>
		</div>
	</div>
</ng-container>
