import { Component, NgZone } from '@angular/core';
import { DataService } from '../data-service.service';
import { APIService } from '../service/ApiService';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Helper } from '../service/helper';
import { GtmService } from '../gtm.service';

declare var Razorpay: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {

  dataService: DataService;
  service: APIService;

  private options:any; 
  private rzp:any;

  constructor(dataService: DataService, service: APIService, private router: Router, 
    private gtm: GtmService, private toaster: ToastrService,
    private zone: NgZone) {
    this.dataService = dataService;
    this.service = service;
    this.options = {
      "image": "https://cdn.whitebook.world/assets/images/wb_logo.png",
      "currency": "INR",
      "name": "whitebook",
      "description": "Whitebook world photobook collections",
      "notes": {
        "address": "Vara Home PVT LTD"
      },
      "theme": {
        "color": "#85A4FF"
      },
      redirect: true,
      callback_url: this.service.getPaymentUrl()
    }
  }

  ngOnInit() {
    if(this.dataService.getAddress()) {
      let orderData = this.dataService.orderData;
      
      setTimeout(()=> this.initiatePayment(orderData));
    } else
    this.router.navigate(["flipbook", this.dataService.bookId]);
  }

  ngAfterViewInit() {
    setTimeout(()=> this.dataService.checkoutStep = 3);
  }

  initiatePayment(orderData) {
    let self = this;
    this.dataService.rzpId = orderData.razorpayId;
    localStorage.setItem("wb-rzpId", orderData.razorpayId);
    let options:any = {}
    options["key"] = orderData.razorpayKey;
    options["amount"] = +(orderData.partialAmount||orderData.total)*100;
    options["order_id"] = orderData.razorpayId;
    options["prefill"]= this.dataService.getUserData();
    // options["handler"] = self.handleRedirect(self);
    options["callback_url"] = self.service.getPaymentUrl();
    options["modal"]= {
      "escape" : false,
      "handleback" : false,
      "confirm_close" : true,
      "ondismiss": self.handleRedirect(self)
    }
    this.options = Object.assign(this.options, options);
    this.rzp = new Razorpay(this.options);
    this.rzp.on('payment.failed', function (response){    
      // Todo - store this information in the server
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
      // this.error = response.error.reason;
      self.toaster.error(response.error.description);
      self.handleRedirect(self);
    });
    this.rzp.on('payment.cancel', function (response){  
      self.toaster.error(response.error.reason);
      self.handleRedirect(self);
    });
    this.rzp.on('error', function (response){  
      self.toaster.error(response.error.reason);
      self.handleRedirect(self);
    });
    setTimeout(()=> {
      this.ordernow();
    }, 1000);
  }

  // completePayment(response:any) {
  //   this.service.completePayment(response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature);
  // }

  handleRedirect(self) {
    self.router.navigate(["checkout", self.dataService.bookId, "billing"], { replaceUrl: true })
  }

  ordernow() {
    try {
      this.rzp.open();
      Helper.isNextStep = true;
    } catch(e) {
      console.log(e);
      
    }
  }

}
