import { Component, EventEmitter, Input, IterableDiffers, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PageFlip } from 'page-flip';
import { CoverComponent } from '../cover/cover.component';


@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent {

  pageFlip:any;
  showFlipBook = false;
  isPortrait = true;

  @Input()
  blur = false;

  autoInterval:any;

  @Input()
  _imgPreviews:any;

  @Input()
  parent!: CoverComponent|null;

  imgPreviews:any;

  // private imgPreviews: any;
  // @Input()
  // set _imgPreviews(_imgPreviews) {
  //   this.imgPreviews = _imgPreviews;
  //   console.log(this.imgPreviews);
  //   this.updateImages(this.pageFlip);
  // };

  @Input()
  bookColor:any;

  @Input()
  bookLogoColor:any;

  @Input()
  autoFlip:any;

  iterableDiffer:any

  imgInterval:any;
  counter = 0;

  isLandscape = true;
  isRotate = true;

  orientType = ""
  @Output()
  exitEvent:EventEmitter<any> = new EventEmitter();

  private orientationChangeHandler!: (e:any) => any;

  constructor(private iterableDiffers: IterableDiffers){
    this.iterableDiffer = iterableDiffers.find([]).create(this._imgPreviews);
  }

  ngOnInit() {
    if(screen && screen.orientation && screen.orientation.type) {
      this.orientType = screen.orientation.type;
      screen.orientation.addEventListener('change', this.handleOrientationChange.bind(this))
    }
    setTimeout(()=> this.isRotate = false, 5000)
  }

  handleOrientationChange(e:any) {
    // if(this.orientType != e.currentTarget.type) {
    //   // this.updateImages();
    //   // this.updateFlipImages(this.pageFlip);
    //   clearInterval(this.autoInterval)
    //   this.loadPreview();
    // }
    if (e.currentTarget.type === 'landscape-primary') {
      this.isPortrait = false;
    } else if (e.currentTarget.type === 'portrait-primary') {
      this.isPortrait = true;
    }
    this.orientType = e.currentTarget.type;
  }

  getIsPortrait() {
    return !!screen?.orientation?.type?.includes('portrait') || (innerWidth > 0 && innerWidth < 600);
  }

  ngOnChanges(){
    this.loadPreview();
  }

  async loadPreview() {
    // this.toaster.warning("Use landscape for better view!")
    if(this.pageFlip && this.pageFlip.pages) {
      clearInterval(this.imgInterval)
      clearInterval(this.autoInterval)
      let self = this;
      this.pageFlip.destroy();
    }
    this.pageFlip = new PageFlip(document.getElementById('flip-book'), {
      width: 170,
      height: 170,
      minWidth: 170,
      minHeight: 170,
      maxWidth: 300,
      maxHeight: 300,
      showCover: true,
      size: 'stretch'
    });
    this.pageFlip.on('flip', (e) => {
      if(e.object.isUserTouch) {
        this.stop();
      }
      if(e.data == (this.imgPreviews.length + (this.imgPreviews.length % 2 == 0 ? 1 : 2))) {
        setTimeout(()=> {
          this.pageFlip.flip(0);
        },2000);
      }
    });
    this.pageFlip.on('changeOrientation', (e) => {
      // this.loadPreview();
    });
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this._imgPreviews);
    if (changes) {
      this.imgPreviews = this._imgPreviews;
      clearInterval(this.imgInterval);
      this.updateFlipImages(this.pageFlip);
    }
  }

  ngAfterViewInit() {
    let topView = document.getElementById('back_wb');
    if (topView!== null) {
      topView.scrollIntoView();
      topView = null;
    }
    this.flipImages()
    if(this.autoFlip) {
      this.autoInterval = setInterval(()=> {
        if(this.pageFlip && this.pageFlip.pages) {
          const ind = this.pageFlip.getCurrentPageIndex();
          if(ind >= this.imgPreviews.length + 2) {
            this.pageFlip.flip(0);
          } else
          this.pageFlip.flipNext()
        }
      }, 3000)
    }
  }

 flipImages() {
    this.pageFlip.loadFromHTML(document.querySelectorAll('.flip-my-page'));
  }

  updateFlipImages(pageFlip:any) {
    if(!pageFlip) return;
    this.imgInterval = setInterval(()=> {
      const ele = document.querySelectorAll('.flip-my-page');
      if(ele.length > this.imgPreviews.length) {
        clearInterval(this.imgInterval);
      }
      this.updateImages();
    })
  }

  updateImages() {
    try {
      this.pageFlip.updateFromHtml(document.querySelectorAll('.flip-my-page'))
    } catch(e) {
      console.error("failed pageflip ", e);
    }
  }

  stop() {
    clearInterval(this.autoInterval);
  }

  exit() {
    this.exitEvent.emit();
  }


  orderNow() {
    this.parent?.orderNow();
  }

  ngOnDestroy() {
    screen?.orientation?.removeEventListener('change', this.orientationChangeHandler);
    if(this.pageFlip && this.pageFlip.pages)
      this.pageFlip.destroy();
  }

}
