<div class="mt-4">
    <div *ngIf="!inEdit" id="collage-overlay" [ngClass]="'collage-overlay '+ imgData?.metadata?.collage?.layout"> 
        <ng-container *ngIf="imgData?.metadata && imgData?.metadata?.collage" >
            <div class="collage-item" *ngFor="let seq of imgData.metadata.collage.seq" (click)="selectCollageImg(seq)">
                <i *ngIf="imgData.metadata.collage.metadata.images[seq]" class="fa-solid fa-pencil"></i>
                <!-- <i (click)="deletePage()">
                    <svg width="30" height="30" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" fill-rule="evenodd"><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M7.5 7h9z"></path><path d="M7.916 9h8.168a1 1 0 01.99 1.14l-.972 6.862a2 2 0 01-1.473 1.653c-.877.23-1.753.345-2.629.345-.876 0-1.752-.115-2.628-.345a2 2 0 01-1.473-1.653l-.973-6.862A1 1 0 017.916 9z" fill="currentColor"></path><rect fill="currentColor" x="10" y="5" width="4" height="3" rx="1"></rect></g></g></svg>
                </i> -->
                <div *ngIf="imgData.metadata.collage.metadata.images[seq]; else addImg"
                    class="collage-image" [ngStyle]="{'background-image': 'url('+(imgData.metadata.collage.metadata.images[seq].src)+')'}"></div>
            </div>
        </ng-container>
        <div *ngIf="loading" class="overlay">loading...</div>
    </div>
    <div *ngIf="inEdit && imgData.metadata.collage.metadata.images[seqIndex];" style="text-align: center;text-align: -webkit-center;">
        <ng-container *ngIf="inCrop">
            <div  class="imageBox">
                <div class="imgCont">
                    <app-croppie  #cropperView (cropper)="getCropData($event)" [imgData]="imgSeqMeta"
                    [cropStyle]="imgSeqMeta.metadata.style"  [imgOrgUrl]="parent.parent.originalUrls.get(imgSeqMeta.id)"></app-croppie>
                </div>
            </div>
        </ng-container>
        <div *ngIf="!inCrop" class="collage-item" style="width: 70%;">
            <img  [src]="imgSeqMeta.src | safeUrl" alt="">
        </div>
    </div>
    
</div>
<div *ngIf="!inEdit">
    <div class="row collageBtns">
        <div class="col" *ngFor="let layout of layouts; index as i">
            <img (click)="setCollageMetadata(i)" [ngClass]="{'active' : imgData?.metadata?.collage?.layout == layout }" [src]="'assets/images/'+layout+'.png'" [alt]="layout">
        </div>
    </div>
    <div class="text-center mt-3">
        <a (click)="shuffle()">
            <svg version="1.1" width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path d="m23.436 14.375h0.49l-1.138 1.142a0.856 0.856 0 0 0 0.021 1.237 0.915 0.915 0 0 0 0.627 0.246c0.236 0 0.472-0.09 0.65-0.267l2.48-2.49a0.867 0.867 0 0 0 0.434-0.743c0-9e-3 -2e-3 -0.017-3e-3 -0.025a0.856 0.856 0 0 0-0.261-0.638l-2.662-2.58a0.922 0.922 0 0 0-1.275 0 0.856 0.856 0 0 0 0 1.237l1.166 1.13h-0.529c-3.135 0-5.336 2.61-7.465 5.134-1.949 2.31-3.79 4.492-6.069 4.492-0.498 0-0.902 0.392-0.902 0.875s0.404 0.875 0.902 0.875c3.135 0 5.336-2.61 7.466-5.133 1.948-2.31 3.79-4.492 6.068-4.492z"></path><path d="m26.57 21.756-2.467-2.49a0.912 0.912 0 0 0-1.268-0.02 0.86 0.86 0 0 0-0.021 1.237l1.132 1.142h-0.487c-1.257 0-2.466-0.65-3.92-2.109a0.912 0.912 0 0 0-1.268-0.017 0.86 0.86 0 0 0-0.017 1.238c1.129 1.132 2.896 2.638 5.205 2.638h0.525l-1.16 1.131a0.86 0.86 0 0 0 0 1.238 0.904 0.904 0 0 0 0.635 0.256c0.23 0 0.458-0.085 0.634-0.256l2.644-2.581a0.86 0.86 0 0 0 0.26-0.639c0-8e-3 3e-3 -0.015 3e-3 -0.024a0.867 0.867 0 0 0-0.43-0.744zm-16.658-7.884c0.23 0 0.462 0.024 0.69 0.07 1.172 0.238 2.347 1.086 3.81 2.75a0.898 0.898 0 0 0 1.287 0.067 0.954 0.954 0 0 0 0.066-1.322c-1.173-1.335-2.78-2.92-4.81-3.331a5.215 5.215 0 0 0-1.042-0.106 0.925 0.925 0 0 0-0.912 0.936c0 0.517 0.408 0.936 0.912 0.936z"></path></g></svg>
            <small>Shuffle</small>
        </a>
    </div>
    <div class="row mt-4 text-center">
        <div class="col">
            <button class="btn btn-sm btn-secondary" (click)="collageBack()">
                Cancel
            </button>
        </div>
        <div class="col">
            <button class="btn btn-sm btn-primary" (click)="saveCollage()">
                Save
            </button>
        </div>
    </div>
</div>
<div *ngIf="inEdit && !inCrop" class="row mt-4 text-center">
    <div class="d-flex mb-4">
        <div class="col">
            <i (click)="choosePhotoModal(seqIndex)">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" stroke="currentColor" stroke-width="2"><path d="M8 21 L15 11 L19 15"></path><path d="M15 2 v5 h5"></path><path d="M8 2 h8 l4 4 v12 q0 4 -4 4 h-8 q-4 0 -4 -4 v-12 q0 -4 4 -4z"></path></g><circle fill="currentColor" cx="10" cy="8" r="1.5"></circle></g></svg>
            </i>
            <p>Upload</p>
        </div>
        <div class="col">
            <i (click)="resetImage()">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="2"><path d="M7.388 18.538a8 8 0 10-2.992-9.03"></path><path fill="currentColor" d="M2.794 11.696L2.37 6.714l5.088 3.18z"></path><path d="M12 8v4M12 12l4 2"></path></g></g></svg>
            </i>
            <p>Reset</p>
        </div>
        <div class="col">
            <i (click)="crop()">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g stroke-width="2" stroke="currentColor" fill="none"><path d="M23 17H9a2 2 0 0 1-2-2v-5m0-3V1 M1 7h14a2 2 0 0 1 2 2v7m0 4v3"></path></g></g></svg>
            </i>
            <p>Crop 
                <!-- <small *ngIf="renderMeta?.metadata?.crop" class="text-muted active">(cropped)</small> -->
            </p>
        </div>
        <!-- <div class="col">
            <i (click)="rotateRight()">
                <svg class="" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round" style="transform: matrix(-1,0,0,1,0,0)"><g><g stroke="none" fill="currentColor"><path fill="none" d="M-1-1h582v402H-1z"></path><rect x="3" rx="1" height="12" width="12" y="9"></rect><path d="M15 5h-1a5 5 0 015 5 1 1 0 002 0 7 7 0 00-7-7h-1.374l.747-.747A1 1 0 0011.958.84L9.603 3.194a1 1 0 000 1.415l2.355 2.355a1 1 0 001.415-1.414l-.55-.55H15z"></path></g></g></svg>
            </i>
            <p>Rotate 
            </p>
        </div> -->
        <div class="col">
            <i (click)="deletePage()">
                <svg width="24" height="24" viewBox="2 2 18 18" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" stroke-linecap="round" stroke-linejoin="round"><g><g fill="none" fill-rule="evenodd"><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M7.5 7h9z"></path><path d="M7.916 9h8.168a1 1 0 01.99 1.14l-.972 6.862a2 2 0 01-1.473 1.653c-.877.23-1.753.345-2.629.345-.876 0-1.752-.115-2.628-.345a2 2 0 01-1.473-1.653l-.973-6.862A1 1 0 017.916 9z" fill="currentColor"></path><rect fill="currentColor" x="10" y="5" width="4" height="3" rx="1"></rect></g></g></svg>
            </i>
            <p>Delete</p>
        </div>

    </div>
    <div class="col">
        <button class="btn btn-sm btn-secondary" (click)="inEdit = !inEdit">
            Back
        </button>
    </div>
    <div class="col">
        <button class="btn btn-sm btn-primary" (click)="saveSeqImg()">
            Done
        </button>
    </div>
</div>
<div *ngIf="inEdit && inCrop" class="row text-center px-3" style="
    display: flex;
    margin: auto;
    width: 100%;
    padding-top: 5rem;">
        <div class="col-6">
            <button class="btn btn-sm btn-secondary" (click)="crop()">Cancel</button>
        </div>
        <div class="col-6">
            <button class="btn btn-sm btn-primary" (click)="cropImage()">Crop</button>
        </div>
    </div>
<ng-template #addImg>
    <svg class="Icon icon-plus" fill="#357FE8" height="24" viewBox="0 0 8 8" width="24">
        <g stroke="none" stroke-width="1" fill-rule="evenodd">
            <g transform="translate(-915.000000, -625.000000)" fill="inherit">
                <path
                    d="M918,628 L918,625 L920,625 L920,628 L923,628 L923,630 L920,630 L920,633 L918,633 L918,630 L915,630 L915,628 L918,628 Z">
                </path>
            </g>
        </g>
    </svg>
</ng-template>
<ng-template #noImg>
    <img class="imgPrev" style="object-fit: none;opacity: 0.4" src="assets/images/favicon.png" alt="">
</ng-template>
