import { Component } from '@angular/core';
import { APIService } from '../service/ApiService';
import { ToastrService } from 'ngx-toastr';
import { CustomUtils } from '../service/customUtils';
import { DataService } from '../data-service.service';
import { Router } from '@angular/router';
import { UploaderService } from '../uploader.service';
import { ModalService } from '../modal.service';
import { GtmService } from '../gtm.service';
import { PreviewService } from '../preview.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent {

  booksList:any = {};
  type:string;

  //page
  pageNo = 0;
  pageSize = 10;
  total = 0;
  endPage = 0;
  ac = new AbortController();

  constructor(private service: APIService, private toaster: ToastrService, private utils: CustomUtils,
    private dataService: DataService, private router : Router, private uploader: UploaderService,
    public modalService: ModalService, private gtm: GtmService, private preview: PreviewService){
      this.type = this.dataService.orderType;
    }

  async ngOnInit() {
    this.dataService.reset()
    if(await this.service.validateUser())
      this.changeBook(this.type);
  }

  changeBook(type:string) {
    this.type = type;
    this.pageNo = 0;
    this.pageSize = 10;
    this.ac.abort();
    this.ac = new AbortController();
    this.getAllBooks({signal : this.ac.signal});
  }

  getAllBooks({signal}:any = {}) {
    this.gtm.event('click_'+this.type, {});
    this.dataService.orderType = this.type+"";
    try {
      this.service.getAllBooks(this.type, this.pageNo, this.pageSize, signal).then((res)=> {
        if(signal.aborted) return;
        if(res && res.success) {
          this.pageNo = res.page + 1;
          this.pageSize = res.pageSize;
          this.total = res.total;
          this.booksList[this.type] = res.data;
          this.parseBookData(res.data, this.type, signal);
        } else if(res.message){
          // this.toaster.error(res.message);
        }else {
          this.toaster.error("Service unavailable");
        }
      })
    } catch (e) {
      if(e) throw e;
    }
    return;
  }

  abort() {
    this.ac.abort(); // cancel the update
  }

  onPageChange(event:any) {
    this.pageNo = event.page - 1;
    this.pageSize = event.pageSize;
    this.ac.abort();
    this.ac = new AbortController();
    this.getAllBooks({signal : this.ac.signal});
  }
 
  parseBookData(bookData:Array<any>, type:string, signal) {
    let self = this;
    (async function () {
      for (let [i, x] of bookData.entries()) {
        if(signal.aborted) return;
        const data = x;
        x["metadata"] = typeof x.metaData == "string" ? JSON.parse(x.metaData) : x.metaData;
        const coverData = Object.assign(x["metadata"].images[0],x.metadata.cover);
        try {
          const imageId = x["metadata"].images[0].id;
          const urlData = data["metaUrlsData"].find(x=> x.isCover)
          if(data["metaUrlsData"][0]) {
            await self.parseImage(new Map().set(imageId, urlData), x["metadata"].images[0], coverData, signal).then((resp:any)=> {
              if(signal.aborted) return;
              if(resp) {
                x["metadata"].images[0] = resp
                self.booksList[type][i] = data;
                self.dataService.setCover(resp.src)
              } else x["metadata"].images[0].src =""
              return true;
            })
          }
        } catch(e) {
          console.error("Failed Orders bookId parse:",x.id, e);
        }
      }
    })();
  }

  parseImage(urlData:any, imgMeta:any, coverMeta:any, signal, counter = 0) {
    return new Promise(async (resolve)=> {
      if(signal.aborted) {
        resolve(null);
        return;
      } 
      const blobUrl = await this.preview.genImagePreview(urlData, coverMeta)
      if(signal.aborted) resolve(null);
      imgMeta["src"] = blobUrl;
      resolve(imgMeta);
    })
  }

  selectFlipBook(id:number) {
    this.gtm.event('click_book', {});
    this.dataService.setBookData({id, metatdata:{}});
    this.router.navigate(["flipbook", this.dataService.bookId]);
  }

  deleteFlipBook(id:number) {
    this.modalService.open("Are you sure you want to delete ?").then(res=> {
      this.gtm.event('click_delBook', {});
      if(res){
        this.service.deleteBookData(id).then(res=> {
          if(res && res.success) {
            this.booksList[this.type] = this.booksList[this.type].filter(x=> x.id != id);
            this.toaster.success("Successfully deleted your book");
          } else {
            this.toaster.error("Failed to delete your book");
          }
        })
      } else console.log("cancelled");
      
    });
  }

  retryPayment(bookId:number) {
    this.gtm.event('click_retry', {});
    this.service.retryPayment(bookId).then((res)=> {
      if(res && res.success) {
        this.service.createPayment(bookId).then((res:any)=> {
          if(res.data && res.success && res.data.paymentId) {
            this.dataService.orderData = res.data;
            this.service.getDefaultAdd(res.data.deliveryAddressId).then((res)=> {
              if(res && res.success) {
                this.dataService.setAddress(res.data);
                this.router.navigate(["checkout", bookId, "payment"]);
              }
            })
          } else if(!res.success) {
            this.toaster.info(res.message || "Unknown Error");
            if(res.message && (res.message.includes("confirmed") || res.message.includes("completed")))
              this.dataService.orderType = res.message.includes("confirmed") ? "transit" : "completed"
            this.router.navigate(["orders"]);
          }
        })
      }
    })
  }

  editFlipbook(bookId:number) {
    this.gtm.event('click_editF', {});
    this.service.editPayment(bookId).then((res)=> {
      if(res && res.success) {
        this.dataService.setBookData(res.data);
        this.router.navigate(["flipbook",bookId]);
      }
    })
  }

  createNow() {
    this.router.navigate(["home"]);
  }

  ngOnDestroy() {
    this.ac.abort();
  }
}
