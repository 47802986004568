import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PageGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isDirectReload = !this.router.navigated;
    if (isDirectReload) {
    //   const id = route.paramMap.get('id');
      const id = state?.url?.match(/(\d)+/)?.[0];
      this.router.navigateByUrl(id ? `/flipbook/${id}` : `/orders`)
      return false;
    }
    return true;
  }
}