import { Injectable } from '@angular/core';
import Compressor from 'compressorjs';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataService } from './data-service.service';
import { APIService } from './service/ApiService';
import { CustomUtils } from './service/customUtils';
import { cloneDeep } from 'lodash';
import heic2any from 'heic2any';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImageProcessingService {
  private allowedExtension = ['jpeg', 'jpg', 'png', 'webp', 'heic', 'heif'];

  private maxFileCon = 15;
  private maxQueCon = 4;
  private maxWidth = 1024;
  private maxSize = 5;//5 MB for chunk

  private fileQueue: Array<{ file: File, pageId:number, imageId: number }> = [];
  private processQueue: Array<{ file: File, pageId:number, imageId: number, previewData: any }> = [];
  private activeFileProcesses = 0;
  private activeQueueProcesses = 0;
  private fileConcurrencyLimit = 0;
  private queueConcurrencyLimit = 0;
  private processedFiles = 0;
  private totalFiles = 0;
  
  private isSave = false;
  private isNew = false;
  private isRetry = false;
  private stepProgress = 0;

  private uploadProgress:any = [];
  private uploadPageIdStartsWith = 0;

  private responseMap = new Map();
  private bookData!: {id: number,metadata :{cover:{}, images : []}};

  private progressSubject = new Subject<{ pageId:number, imageId: number, processed: number, total: number, result: any }>();
  private prreviewSubject = new Subject<{ pageId:number, result: any }>();
  private completionSubject = new Subject<void>();
  private errorSubject = new Subject<{ pageId:number, imageId: number, error: any }>();

  progress$ = this.progressSubject.asObservable();
  preview$ = this.prreviewSubject.asObservable();
  completion$ = this.completionSubject.asObservable();
  error$ = this.errorSubject.asObservable();
  

  constructor(private toaster: ToastrService, private dataService: DataService, private service: APIService,
    private utils : CustomUtils
  ) {}

  isAlreadyQueued() : boolean {
    return this.fileQueue.length > 0;
  }

  private calculateDpi(w:number, h:number):number {
    let dpi = 0;
    dpi = ((w/8)+(h/8))/2
    return Math.round(dpi);
  }

  async createThumbnail(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const maxWidth = this.maxWidth; // Maximum width of thumbnail
          const scaleSize = maxWidth / Math.max(img.width, img.height);
          canvas.width = img.width * scaleSize;
          canvas.height = img.height * scaleSize;
          if(ctx) {
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve({data: canvas.toDataURL('image/jpeg', 0.8), w:img.width, h:img.height});
          }
          resolve(null);
        };
        img.onerror = reject;
      };

      reader.readAsDataURL(file);
    });
  }

  async processFiles(files: File[], bookData: any, pageId: number, imageId: number, isNew: boolean, isSave: boolean, isRetry: boolean): Promise<void> {
    if (this.isAlreadyQueued()) {
        this.toaster.warning("Please wait upload is in process!");
        return;
    }
    this.bookData = bookData;
    this.isSave = isSave;
    if (!this.bookData.id) {
      this.toaster.warning("Invalid Upload!");
      return;
    }
    this.totalFiles = files.length;

    this.uploadPageIdStartsWith = +pageId;
    this.activeFileProcesses = 0;
    this.activeQueueProcesses = 0;
    this.fileConcurrencyLimit = isNew ? 1 :this.maxFileCon;
    this.processedFiles = 0;
    this.isNew = isNew;
    this.isRetry = isRetry;
    this.queueConcurrencyLimit = isNew ? 1 : this.maxQueCon;
    this.responseMap.clear();
    this.clearImageStatus();

    this.stepProgress = (100 / (this.totalFiles * 4));
    this.isSave = isSave;

    for (const file of files) {
      this.fileQueue.push({ file, pageId: pageId++, imageId: imageId++ });
      this.processNextFile();
    }
  }

  processNextFile() {
    while (this.activeFileProcesses < this.fileConcurrencyLimit && this.fileQueue.length > 0) {
      const item = this.fileQueue.shift();
      if (item) {
        this.processFile(item);
      }
    }
  }

  async processFile(item: { file: File, pageId: number, imageId: number }): Promise<void> {
    const { file, pageId, imageId } = item;
    this.activeFileProcesses++;
    try {
      const finalFile = await this.convertIfHeic(file, pageId);
      const previewData = await this.createThumbnail(finalFile);
      if (!previewData)
          throw new Error("Failed to read Image");
      // this.dataService.setPreview(previewData.data, pageId);
      this.prreviewSubject.next({
        pageId : pageId,
        result: previewData.data,
      })
      this.updateImageStatus(pageId, imageId, 'waiting');
      this.processQueue.push({ file: finalFile, pageId, imageId, previewData });
      this.processNextQueue();
    } catch (error) {
      this.processedFiles++;
      this.updateImageStatus(pageId, imageId, 'failed');
      this.notifyError(file.name, pageId, imageId, error);
    } finally {
      this.activeFileProcesses--;
    }
  }

  processNextQueue() {
    while (this.activeQueueProcesses < this.queueConcurrencyLimit && this.processQueue.length > 0) {
      const item = this.processQueue.shift();
      if (item) {
        this.processFileQueue(item);
      }
    }
  }

  async processFileQueue(item: { file: File, pageId: number, imageId: number, previewData: any }): Promise<void> {
    const { file, pageId, imageId, previewData } = item;
    this.activeQueueProcesses++;
    const responsePages:Array<number> = [pageId];
    try {
      const finalFile = await this.compressFile(await this.convertIfHeic(file, pageId), pageId);

      const data = {
        width: previewData.w,
        height: previewData.h,
        dpi: this.calculateDpi(previewData.w, previewData.h),
        size: finalFile.size,
        format: file.name?.split(".").pop(),
        name: file.name,
        date: file.lastModified,
        layout: previewData.w > previewData.h ? "landscape" : previewData.w == previewData.h ? "square" : "portrait",
      };

      this.updateImageStatus(pageId, imageId, 'uploading');
      const success = await this.uploadFile(this.bookData.id, finalFile, imageId, previewData.data);
      if (success) {
        const isCover = this.isNew && this.queueConcurrencyLimit == 1;

        const uploadedFile: any = await this.saveMetaUrl(this.bookData.id, imageId, data, isCover);
        if (uploadedFile) {
          this.updateImageStatus(pageId, imageId, 'optimising');
          const imgMetaReady: any = [];

          if (isCover) {
            const croppedFile:any = await this.smartCrop(finalFile, data, 0, imageId, uploadedFile, true);
            imgMetaReady.push(croppedFile);
            this.responseMap.set(0, { resp: uploadedFile, meta: croppedFile });
            const previewId = await this.uploadCover(croppedFile.src);
            this.bookData.metadata.cover['previewId'] = previewId;
            responsePages.push(0);
          }

          const croppedFile = await this.smartCrop(finalFile, data, pageId, imageId, uploadedFile, false);
          this.responseMap.set(pageId, { resp: uploadedFile, meta: croppedFile });
          
          imgMetaReady.push(this.responseMap.get(pageId).meta);

          if (this.isSave) {
            await this.updateBooks(this.bookData, imgMetaReady, pageId);
          }

          this.updateImageStatus(pageId, imageId, 'success');
        } else {
          throw new Error("Failed to save photo: " + imageId);
        }
      } else {
        throw new Error("Failed to upload photo: " + imageId);
      }
      this.queueConcurrencyLimit = this.maxQueCon;
      this.fileConcurrencyLimit = this.maxFileCon;
    } catch (error) {
        this.notifyError(file.name, pageId, imageId, error);
    } finally {
      this.activeQueueProcesses--;
      // this.activeFileProcesses--;
      this.processedFiles++;

      responsePages.forEach(pageId=>
        this.progressSubject.next({
          pageId : pageId,
          imageId,
          processed: this.processedFiles,
          total: this.totalFiles,
          result: this.responseMap.get(pageId),
        })
      )
      
      this.processNextFile();
      this.processNextQueue();

      if (this.processedFiles === this.totalFiles) {
        this.completionSubject.next();
        this.dataService.setProgress(100);
      }

      URL.revokeObjectURL(previewData.data);
    }
}

  private updateImageStatus(id:number, imgId:number, status:string) {
    this.dataService.incProgress(this.stepProgress);
    this.dataService.setImageStatus((this.isSave ? id : imgId), status);
  }

  private clearImageStatus() {
    this.dataService.setProgress(0);
    this.dataService.clearImageStatus();
  }

  private async uploadCover(bookImage) {
    let self = this;
    return await new Promise(async (resolve)=> {
      fetch(bookImage)
      .then(function(response) {
        return response.blob()
      })
      .then(async function(blob) {
        const previewId = await self.service.uploadCover(blob);
        resolve(previewId)
      })
    });
  }

  private updateBooks(bookData:any, imgMeta:Array<any>, pageId:number) {
    let self = this;
    bookData["metadata"] = typeof bookData["metadata"] == "string" ? JSON.parse(bookData["metadata"]) : bookData["metadata"];
   if(this.isRetry) {
      imgMeta.forEach(im=> {
        bookData.metadata.images.splice(pageId, 0, im);
      })
    } else {
      bookData.metadata.images.push(...imgMeta);
      const x = bookData.metadata.images;
      bookData.metadata.images = x.slice(0,this.uploadPageIdStartsWith).concat(x.slice(this.uploadPageIdStartsWith).sort((a,b)=> a.id - b.id))
    }
    return new Promise((resolve)=> {
      self.service.updateBooks(cloneDeep(bookData)).then(x=> {
        if(x && x.data && x.success){
          self.dataService.setBookData(x.data);
        }
        resolve(x);
      });
    })
  }

  private compressFile(file: File, pageId): Promise<File> {
    return new Promise((resolve, reject) => {
      const it = ['image/png', 'image/webp'];
      new Compressor(file, {
        quality: file.size && file.size < 1048576 ? 1 : 0.8, 
        retainExif: true,
        convertTypes: it,
        convertSize:5000000,
        mimeType: 'image/jpeg',
        success(result) {
          resolve(file.size > result.size ? new File([result], file.name, {lastModified: file.lastModified, type: file.type}) : file);
        },
        error(err) {
          const img = new Image();
          img.onload = ()=> {
            URL.revokeObjectURL(img.src);
            resolve(file)
          }
          img.onerror = ()=> {
            URL.revokeObjectURL(img.src);
            reject(err)
          }
          img.src = URL.createObjectURL(file);
        }
      })
    });
  }

  private uploadFile(booKId:number, file: File, imageId:number, preview:string): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      const data = {};
      data["bookId"] = booKId;
      data["fileId"] = imageId;
      if (file.size > (this.maxSize * 1024 * 1024)) {
        resolve(await this.uploadFileInChunks(file, imageId, data, preview));
      } else {
        const response = await this.service.getPresignedUrls(data);
        if(response.success && response.data && response.data.rawUrl) {
          await this.uploadFileDirectly(file, response.data.rawUrl, imageId)
          await this.uploadFilePreview(preview, response.data.previewUrl, imageId)
          resolve(true);
        } else resolve(false);
      }
    });
  }

  private async uploadFileInChunks(file: File, imageId: number, data:any, preview:string):Promise<Boolean> {
    return new Promise(async (resolve)=> {
      const chunkSize = this.maxSize * 1024 * 1024; // 5 MB chunk size
      const chunks = Math.ceil(file.size / chunkSize);
      const partETags:any = [];
      const resInit = await this.service.initiateMultipartUpload(data);
      if (resInit.success && resInit.data) {
        const uploadId = resInit.data;
        data['uploadId'] = uploadId;
        data['partCount'] = chunks;
        const presignedData = await this.service.getPresignedUrlsMultiPart(data);
        if(presignedData.success && presignedData.data?.rawUrls?.length) {
          const presignedUrls = presignedData.data.rawUrls
          for (let i = 0; i < chunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const blob = file.slice(start, end);
            const presignedUrl = presignedUrls[i];
            const response = await this.service.uploadFileToS3(`${presignedUrl}&partNumber=${i + 1}&uploadId=${uploadId}`, blob).toPromise();
            const eTag = response.headers.get('ETag');
            partETags.push({ PartNumber: (i + 1), ETag: eTag });
            this.uploadProgress[i] = Math.round(((i + 1) / chunks) * 100);
          }
          data['partETags']= partETags;
          const completeResponse = await this.service.completeMultipartUpload(data);
          if(completeResponse.success) {
            const prev = await this.uploadFilePreview(preview, presignedData.data.previewUrl, imageId)
            resolve(prev && completeResponse.data)
          }
        }
      }else resolve(false);
    })
  }

  private uploadFileDirectly(file: File, uploadUrl: string, imageId: number):Promise<Boolean> {
    return new Promise(async (resolve)=> {
      this.service.uploadFileToS3(uploadUrl, file).subscribe(
        (event) => {
          resolve(true);
        },
        (error) => {
          console.error('Error uploading file:', error);
          resolve(false);
        }
      );
    });
  }

  private uploadFilePreview(base64Image: string, uploadUrl: string, imageId: number):Promise<Boolean> {
    return new Promise(async (resolve)=> {
      try {
        let self = this;
        const contentType = 'image/jpeg'; // Adjust according to your image type
        const base64Data = base64Image.split(',')[1];
        const blob = this.base64ToBlob(base64Data, contentType);
        resolve(await self.uploadFileDirectly((<File> blob), uploadUrl, imageId));
      } catch(e) {
        resolve(false)
      }
    })
  }

  private base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteArrays:Array<Uint8Array> = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, { type: contentType });
  }

  private saveMetaUrl(bookId:number, imageId:number, imgData:any, isCover) {
    const data = {};
    data["bookId"] = bookId;
    data["fileId"] = imageId;
    data["isCover"] = isCover;
    data["scale"] = this.maxWidth;
    data["imageMetaData"] = imgData;
    return new Promise(async (resolve)=> {
      this.service.saveMetaUrl(data).then((res) => {
          if(res.success)
            resolve(res.data);
          else resolve(false);
        },
        (error) => {
          console.error('Error uploading file:', error);
          resolve(false);
        }
      );
    });
  }

  private smartCrop(file: File, imgData:any, pageId, imageId, uploadedFileData, isCover): Promise<Object> {
    // Mock smart crop function using SmartCrop.js
    const {rawUrl, previewUrl, boost } = uploadedFileData;
    return new Promise(async (resolve, reject) => {
        const data = await this.utils.getUploadedImageMetadata(file, '', pageId, imageId, null, isCover, this.bookData?.metadata.cover, false, 
          boost ? JSON.parse(boost) : null);
        data ? resolve(data) : reject(data);
    });
  }

  private notifySuccess(file: File) {
    console.log('Image processed successfully:', file.name);
    // Implement further notifications or updates to UI as needed
  }

  private notifyError(name: string, pageId, imageId, error: any) {
    this.updateImageStatus(pageId, imageId, 'failed');
    console.error('Error processing image:', name, error);
    this.errorSubject.next({ pageId, imageId, error });
    // Implement further notifications or updates to UI as needed
  }
  
  private async convertIfHeic(file:File, pageId): Promise<File> {
    let type = file.type ? file.type : this.getFileType(file.name);
    if(/image\/hei(c|f)/i.test(type)){
      const arrayBuffer = await file.arrayBuffer();
      const blob = new Blob([arrayBuffer], { type: file.type });
      try {
        this.dataService.setImageStatus(pageId, 'converting');
        file = <File> await this.blobToFile((<Blob> await heic2any({blob,toType:"image/jpeg",quality:0.8})), file.name.split(".")[0]+".jpeg");
      } catch(e:any) {
        console.error(`Error converting HEIC to JPEG:`, e);
        throw new Error('HEIC to JPEG conversion failed.');
      }
    }
    return file;
  }

  private getFileType(fileName) {
    let name = ''
    if(fileName.lastIndexOf(".")) {
      name = fileName.slice(fileName.lastIndexOf(".")+1);
    }
    if(name && this.allowedExtension.includes(name.toLowerCase())) {
      name = "image/"+name.toLowerCase();
    }
    return name;
  }
  
  private blobToFile = (theBlob: Blob, fileName:string): File => {
    let b: any = theBlob;
    b.name = fileName;
    return <File>theBlob;
  }
//   ngOnInit() {
//     this.progressSubscription = this.imageProcessingService.progress$.subscribe(progress => {
//       this.progress = progress;
//       console.log(`Processed ${progress.processed} of ${progress.total} images`);
//     });

//     this.completionSubscription = this.imageProcessingService.completion$.subscribe(() => {
//       console.log('All images processed');
//     });
//   }

//   ngOnDestroy() {
//     this.progressSubscription.unsubscribe();
//     this.completionSubscription.unsubscribe();
//   }

}
