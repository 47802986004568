import { Component } from '@angular/core';
import { DataService } from '../data-service.service';
import { APIService } from '../service/ApiService';
import { UploaderService } from '../uploader.service';
import { Router } from '@angular/router';
import { CustomUtils } from '../service/customUtils';
import { GtmService } from '../gtm.service';
import { HandlerService } from '../handler.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {

  chunkSize = 1048576 * 0.3;//(MB)

  progress = 0;

  files: Array<any> = [];

  imageMeta: Array<any> = [];
  imageFiles: Array<any> = [];

  redirect:boolean = true;
  progressSubscription!: Subscription;

  step = 0;

  constructor(public dataService: DataService, private service: APIService, private uploader: UploaderService, 
    private router: Router, private utils: CustomUtils,
    private gtm: GtmService, private handler : HandlerService) {
  }

  ngOnInit() {
    const files = this.dataService.getFiles();
    this.imageMeta = new Array(files.length);
    if(this.uploader.uploadPending && files && files.length) {
      this.dataService.resetFiles();
      this.initUpload(files);
    } else {
      this.router.navigate(["/home"]);
    }
  }

  async initUpload(files) {
    const bookData:any = this.utils.defaultBookData();
    this.createBook(bookData).then(res=> {
      if(res) {
        this.gtm.event('click_drafts', {})
        this.dataService.setBookData(res);
        let count = 0;
        const skip = 1||Math.ceil(files.length/5);
        this.progressSubscription = this.handler.getSubscribe().subscribe(progress => {
          if(progress.result && skip <= count++)
            setTimeout(()=> this.router.navigate(["flipbook", this.dataService.bookId]));
        });
        this.handler.queueImages(files, res);
      }
    });
  }

  createBook(bookData:any, eId = null) {
    return new Promise((resolve)=> {
      bookData["metadata"] = JSON.stringify(bookData["metadata"]);
      this.service.createBooks(bookData, eId).then((res)=> {
        if(res?.data && res.data.id){
          resolve(res.data);
        }
        else {
          console.error("failed");
          resolve(false);
        }
      })
    })
  }


  ngOnDestroy() {
    this.progressSubscription?.unsubscribe();
  }

}
