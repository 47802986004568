import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {

  private routeSub: Subscription = new Subscription;

  constructor(private route: ActivatedRoute, private router: Router, public dataService : DataService){}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.dataService.bookId = +params['id'];
      // this.router.navigate([this.router.url]);
    });
  }

  setRouter(path:string) {
    if(this.router.url == '/checkout/billing')
      this.router.navigate([path], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
